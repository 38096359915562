import { client } from '@whizz/api-client';
import cookies from 'browser-cookies';

export const TOKEN_COOKIE_NAME = 'token';
export const EXP_COOKIE_NAME = 'tx';

export const COOKIE_OPTIONS = {
  secure: true,
};

export const LEGACY_COOKIE_NAME = 'whizz_session';

export const hostnameToDomain = (hostname) => hostname.split('.').splice(-2).join('.');

export const getTokenCookie = () => cookies.get(TOKEN_COOKIE_NAME);
export const setTokenCookie = (token, authtoken) => {
  const domain = hostnameToDomain(window.location.hostname)
  cookies.set(TOKEN_COOKIE_NAME, token, COOKIE_OPTIONS);
  if (!cookies.get(LEGACY_COOKIE_NAME)) {
    cookies.set(LEGACY_COOKIE_NAME, authtoken, Object.assign(COOKIE_OPTIONS, {domain}));
  }
};
export const removeTokenCookie = () => cookies.erase(TOKEN_COOKIE_NAME, COOKIE_OPTIONS);

export const getLegacyCookie = () => cookies.get(LEGACY_COOKIE_NAME);

export const getExperienceCookie = () => cookies.get(EXP_COOKIE_NAME);
export const setExperienceCookie = (version) => cookies.set(EXP_COOKIE_NAME, version, COOKIE_OPTIONS);
export const handleLogin = (identity, locale, loginVersion = null, referrer = null) => {
  console.log('handleLogin', identity, locale, loginVersion, referrer);
  setTokenCookie(identity.token, identity.claims.authtoken);

  client.http().defaults.headers.common.Authorization = `Bearer ${identity.token}`;

  identity.dashboard({
    locale: locale.id,
    loginVersion
  })
    .then((dashboardUrl) => {
      if (dashboardUrl.includes("/account/password?forced=1")) {
        // User needs to change password
        return dashboardUrl;
      }
      if (referrer) {
        return identity.goToReferrer(referrer, window.top)
          .catch(() => dashboardUrl);
      }
      return dashboardUrl;
    })
    .then((url) => {
      identity.goTo(url, window.top);
    });

};
