import React, { useCallback, useState } from 'react';
import context from './context';

const Provider = context.Provider;

const FlashProvider = ({ children }) => {

  const [ flash, setFlash ] = useState(null);

  return (
    <Provider value={{
      flash,
      clear: useCallback(() => setFlash(null), []),
      error: useCallback((message, autoHide = true, action = null, onActionClick = () => {}) => setFlash({ action, autoHide, message, onActionClick, level: 'error' }), []),
      info: useCallback((message, autoHide = true, action = null, onActionClick = () => {}) => setFlash({ action, autoHide, message, onActionClick, level: 'info' }), []),
      success: useCallback((message, autoHide = true, action = null, onActionClick = () => {}) => setFlash({ action, autoHide, message, onActionClick, level: 'success' }), []),
      warning: useCallback((message, autoHide = true, action = null, onActionClick = () => {}) => setFlash({ action, autoHide, message, onActionClick, level: 'warning' }), []),
    }}>
      {children}
    </Provider>
  );

};

export default FlashProvider;
