import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import Version from '../../partials/Version';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  main: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: theme.spacing(3),
    flex: 1,
  },
  stylishBackground: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'url(https://whizz.com/wp-content/uploads/y9yl_gdl3_181030-scaled-1.jpeg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  },
}));

const View = ({ children = [] }) => {

  const classes = useStyles();

  return (
    <div className={classes.stylishBackground}>
      <Header />
      <main className={classes.main}>
        {children}
      </main>
      <Version />
    </div>
  );

};

export default memo(View);
