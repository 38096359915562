import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MathsWhizzLogo } from '@whizz/react-branding';
import { useI18n } from '@whizz/react-i18n';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'center',
  },
  mathsWhizzLogo: { // Set height based on theme.mixins.toolbar minHeight - theme.spacing(2)
    height: 40,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 32,
    },
    [theme.breakpoints.up('sm')]: {
      height: 48,
    },
  },
}));

const Header = () => {

  const classes = useStyles();
  const { locale } = useI18n();

  return (
    <>
      <AppBar elevation={0} position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <MathsWhizzLogo locale={locale.id} dark className={classes.mathsWhizzLogo} />
        </Toolbar>
      </AppBar>
    </>
  );

};

export default memo(Header);
