import { Link } from "@material-ui/core";
import { model } from '@whizz/api-client';
import { Translate, useI18n } from '@whizz/react-i18n';
import React, { memo, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useFlash } from '../../contexts/flash';
import useForm from '../../hooks/useForm';
import useTitle from '../../hooks/useTitle';
import View from './View';

const submitFn = (username) => model.User.passwordReset(username);

const submittableFn = (inputs, dirty) => inputs.username;

const Container = ({
  loginVersion = null
}) => {

  const history = useHistory();
  const { error, info } = useFlash();
  const { translate } = useI18n();

  const defaults = useMemo(() => ({
    password: null,
    username: null,
    showPassword: false,
  }), []);

  const {
    fatal,
    inputs,
    rejection,
    submit,
    submitted,
    submittable,
    submitting,
    textFieldProps,
  } = useForm(defaults, submitFn, submittableFn);

  useTitle(translate('PasswordResetPage.TITLE'));

  useEffect(() => {

    if (!submitted) {
      return;
    }

    if (rejection) {
      fatal();
      error(translate('PasswordResetPage.SAVE_ERROR'));
    } else {
      info(<Translate i18nKey="PasswordResetPage.SAVE_SUCCESS">
        <Link href={`mailto:${translate('PasswordResetPage.SUPPORT_EMAIL')}`} />
      </Translate>, false);
      const redirect = loginVersion ? `/${loginVersion}` : '/';
      history.push(redirect);
    }

  }, [ error, fatal, history, info, loginVersion, rejection, submitted, translate ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    submit(inputs.username);
  };

  const handleCancel = (event) => {
    const redirect = loginVersion ? `/${loginVersion}` : '/';
      history.push(redirect);
  };

  return <View
    onCancel={handleCancel}
    onSubmit={handleSubmit}
    submittable={submittable}
    submitting={submitting}
    usernameProps={textFieldProps('username')}
  />;

};

export default memo(Container);
