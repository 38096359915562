import { Button, Card, CardActions, CardContent, LinearProgress, Link, TextField, Typography } from '@material-ui/core';
import { Translate, useI18n } from '@whizz/react-i18n';
import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import Logo from '../../partials/Logo';

const View = ({
  onCancel = (event) => {},
  onSubmit = (event) => {},
  submittable = false,
  submitting = false,
  usernameProps = {},
}) => {

  const { translate, locale } = useI18n();

  return (
    <DefaultLayout>
      <Card component="form" onSubmit={onSubmit}>

        <CardContent>

          <Logo />

          <Typography variant="h5">{translate('PasswordResetPage.TITLE')}</Typography>
          <Typography gutterBottom>{translate('PasswordResetPage.MESSAGE')}</Typography>

          <Typography variant="subtitle1" color="textSecondary">{translate('PasswordResetPage.STUDENTS_TITLE')}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>{translate('PasswordResetPage.STUDENTS_MESSAGE')}</Typography>

          { locale.id === 'en-nz' ?
          <>
            <Typography variant="subtitle1" color="textSecondary">{translate('PasswordResetPage.THIRD_PARTY.TITLE')}</Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Translate i18nKey={'PasswordResetPage.THIRD_PARTY.MESSAGE'}>
                <Link href={translate('PasswordResetPage.THIRD_PARTY.RESET_URL')} target={'_blank'} />
              </Translate>
            </Typography>
          </>
          : null }

          <TextField
            {...usernameProps}
            label={translate('PasswordResetPage.USERNAME')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            autoComplete="email username"
            InputProps={{ inputProps: { autoCapitalize: 'none' } }}
          />

        </CardContent>

        <CardActions>
          <Button type="submit" color="primary" disabled={!submittable}>{translate('PasswordResetPage.SAVE')}</Button>
          <Button onClick={onCancel} color="primary" disabled={submitting}>{translate('PasswordResetPage.CANCEL')}</Button>
        </CardActions>

        {submitting && <LinearProgress />}

      </Card>
    </DefaultLayout>
  );

};

export default View;
