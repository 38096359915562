import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import View from './View';
import { model } from '@whizz/api-client';
import { useMutation, useQuery } from 'react-query';
import { Avatar as AvatarType, Identity } from '@whizz/api-model';
import { handleLogin as handleLoginToDashboard } from '../../utils';
import { useI18n } from '@whizz/react-i18n';
import { useLocalStorage } from 'react-use';
import useNoIndex from '../../hooks/useNoIndex';
import useTitle from '../../hooks/useTitle';

interface Params {
  accessCode?: string;
}

export type Student = {
  firstName: string;
  lastName: string;
  username: string;
  avatar: AvatarType;
};

export type GroupPictureLoginProps = {
  state: 'initial' | 'loading' | 'success' | 'error' | 'search_error' | 'empty';
  password: 'correct' | 'incorrect' | 'loading' | false;
};

export const formatInput = (value: string): string => {
  const rawValue = value.replace(/[-\s]/g, '').substring(0, 9);
  return rawValue.match(/.{1,3}/g)?.join('-').toUpperCase() || '';
};

const cleanAccessCode = (value: string): string => value.replace(/-/g, '');

const Container = () => {

  useNoIndex();
  const { locale, translate } = useI18n();
  const { accessCode } = useParams<Params>();
  const [ accessCodes, setAccessCodes ] = useLocalStorage<{ label: string; code: string }[]>('accessCodes', []) ?? [];

  const history = useHistory();
  const [ password, setPassword ] = useState<string>('');
  const [ incorrectPassword, setIncorrectPassword ] = useState<GroupPictureLoginProps['password']>(false);

  const classStudentsQuery = useQuery(
    [ accessCode ],
    () => model.GroupPictureLogin.get(cleanAccessCode(accessCode ?? '')),
    {
      enabled: null != accessCode,
      onSuccess: (data) => {
        if (data) {
          const updatedAccessCodes = accessCodes ? accessCodes.map((code) => code.code === data.accessCode ? { ...code, label: data.className } : code) : []; // Update the label of the access code
          // Add new access code if it doesn't exist
          if (!updatedAccessCodes.some((code) => code.code === data.accessCode)) {
            updatedAccessCodes.push({ label: data.className, code: data.accessCode });
          }
          setAccessCodes(updatedAccessCodes.sort((a, b) => a.label.localeCompare(b.label)));
        }
      },
      onError: () => {
        // Remove the access code from the list if it is invalid
        const updatedAccessCodes = accessCodes ? accessCodes.filter((code) => code.code !== accessCode) : [];
        setAccessCodes(updatedAccessCodes);
      }
    },
  );

  useTitle(classStudentsQuery.data?.className ? translate('PictureLoginPage.BOOKMARK', { classLabel: classStudentsQuery.data?.className }) : translate('PictureLoginPage.TITLE'));
  const students = classStudentsQuery.data?.students || [];

  const [ searchQuery, setSearchQuery ] = useState('');
  const [ selectedStudent, setSelectedStudent ] = useState<Student | null>(null);
  const [ formattedAccessCode, setFormattedAccessCode ] = useState<string | null>(accessCode ? formatInput(accessCode) : null);

  const login = useMutation({
    mutationFn: () => {
      setIncorrectPassword('loading');
      return model.Identity.login({ username: selectedStudent?.username ?? '', pin: password, accessCode: classStudentsQuery.data?.accessCode ?? '' }); //Todo Fix accessCode being undefined
    },
    onSuccess: (response) => handleCorrectPassword(response),
    onError: () => handleIncorrectPassword(),
  });

  const handleAccessCodeChange = (value: string) => {
    setFormattedAccessCode(formatInput(value));
  };

  const submitAccessCode = () => {
    history.push(`/class-login/${cleanAccessCode(formattedAccessCode ?? '')}?locale=${locale?.id}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleStudentSelect = (student: Student | null) => {
    setSelectedStudent(student);
  };

  const handlePasswordChange = (value: string) => {
    if (password.length < 3) {
      setPassword((previous) => `${previous}${value}`);
    }
  };

  const handleUndo = () => {
    setPassword((previous) => previous.slice(0, -1));
  };

  const handleLogin = () => {
    login.mutate();
  };

  const handleCorrectPassword = (response: Identity) => {
    setPassword('');
    setIncorrectPassword('correct');
    setTimeout(() => {
      handleLoginToDashboard(response, locale);
    }, 1000);
  };

  const handleIncorrectPassword = () => {
    setPassword('');
    setIncorrectPassword('incorrect');
    setTimeout(() => {
      setIncorrectPassword(false);
    }, 1000);
  };

  useEffect(() => {
    if (password.length >= 3) {
      handleLogin();
    }
  }, [ password ]);

  useEffect(() => {
    const accessCode = cleanAccessCode(formattedAccessCode ?? '');
    if (accessCode.length >= 9) {
      submitAccessCode();
    }
  }, [ formattedAccessCode ]);

  useEffect(() => {
    if (!selectedStudent) {
      setPassword('');
      setIncorrectPassword(false);
    }
  }, [ selectedStudent ]);

  const filteredStudents = students.filter((student) => {
    const fullName = `${student.firstName} ${student.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const getState = (): GroupPictureLoginProps['state'] => {
    if (classStudentsQuery.isLoading) {
      return 'loading';
    }
    if (classStudentsQuery.isError) {
      return 'error';
    }
    if (classStudentsQuery.data?.students.length) {
      if (filteredStudents.length === 0) {
        return 'search_error';
      }
      return 'success';
    }
    if (classStudentsQuery.data?.students.length === 0) {
      return 'empty';
    }
    return 'initial';
  };

  return (
    <View
      state={getState()}
      accessCode={formattedAccessCode}
      accessCodes={accessCodes ?? []}
      classLabel={classStudentsQuery.data?.className || null}
      students={filteredStudents as Student[]}
      password={password}
      incorrectPassword={incorrectPassword}
      searchQuery={searchQuery}
      onSubmitAccessCode={submitAccessCode}
      onAccessCodeChange={handleAccessCodeChange}
      onSearchChange={handleSearchChange}
      onStudentSelect={handleStudentSelect}
      onPasswordChange={handlePasswordChange}
      onUndo={handleUndo}
      selectedStudent={selectedStudent}
    />
  );
};

export default Container;
