import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import IdentityPage from '../pages/IdentityPage';
import LoginPage from '../pages/LoginPage';
import PictureLoginPage from '../pages/PictureLoginPage';
import PasswordResetConfirmPage from '../pages/PasswordResetConfirmPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import RegistrationPage from '../pages/RegistrationPage';
import NoAccessPage from '../pages/NoAccessPage';

const Routes = () => (
  <Switch>
    <Route path="/whizz-app" exact component={() => <LoginPage loginVersion={"whizz-app"} />} />
    <Route path="/identity" exact component={IdentityPage} />
    <Route path="/password-reset" exact component={PasswordResetPage} />
    <Route path="/password-reset/confirm" exact component={PasswordResetConfirmPage} />
    <Route path="/whizz-app/password-reset" exact component={() => <PasswordResetPage loginVersion={"whizz-app"} />} />
    <Route path="/whizz-app/password-reset/confirm" exact component={() => <PasswordResetConfirmPage loginVersion={"whizz-app"} />} />
    <Route path="/registration" exact component={RegistrationPage} />
    <Route path="/no-access" exact component={NoAccessPage} />
    <Route path="/class-login/:accessCode?" component={PictureLoginPage} />
    <Route path="/" component={LoginPage} />
    <Route component={() => <Redirect to="/" />} />
  </Switch>
);

export default Routes;
