import { isSupported } from '@whizz/browser-detect';
import React from 'react';
import View from './View';

export const MAINTENANCE = false;
const CRITICAL = false;

const Container = ({ children, maxWidth }) => {

  return <View
    browserUnsupported={!isSupported()}
    children={children}
    maintenance={MAINTENANCE}
    critical={CRITICAL}
    maxWidth={maxWidth}
  />;

};

export default Container;
