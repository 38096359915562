import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useI18n } from '@whizz/react-i18n';
import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { STORAGE_NAME_LOCALE } from '../../partials/App';
import Flag from './Flag';

const useStyles = makeStyles((theme) => ({
  flag: {
    height: 32,
  },
  flagActive: {
    height: 16,
    borderRadius: theme.shape.borderRadius,
  },
  flagWithLabel: {
    marginRight: theme.spacing(1),
  },
}));

const LanguagePicker = (props) => {

  const classes = useStyles();
  const { locale, locales, setLocale } = useI18n();
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleLocaleClick = (event, locale) => {
    handleClose();
    setLocale(locale);
    window.sessionStorage.setItem(STORAGE_NAME_LOCALE, locale.id);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleClick} {...props}>
        <Flag square country={locale.country} className={classNames(classes.flag, classes.flagActive)} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {locales.map((l) => (
          <MenuItem key={l.id} dense selected={locale.id === l.id} onClick={(event) => handleLocaleClick(event, l)}>
            <Flag square country={l.country} className={classNames(classes.flag, classes.flagWithLabel)} />
            <div>
              <Typography variant="inherit" component="div" noWrap>
                {(() => {
                  switch (l.id) {
                    case 'en-ae':
                      return 'English';
                    case 'en-gb':
                      return 'English';
                    case 'en-ke':
                      return 'English';
                    case 'en-nz':
                      return 'English';
                    case 'en-us':
                      return 'English';
                    case 'es-mx':
                      return 'Español';
                    case 'fr-cd':
                      return 'Français';
                    case 'sw-ke':
                      return 'Kiswahili';
                    case 'th-th':
                      return 'ภาษาไทย';
                    default:
                      return l.id;
                  }
                })()}
              </Typography>
              <Typography variant="caption" color="textSecondary" noWrap>
                {(() => {
                  switch (l.id) {
                    case 'en-ae':
                      return 'United Arab Emirates';
                    case 'en-gb':
                      return 'United Kingdom';
                    case 'en-ke':
                      return 'Kenya';
                    case 'en-nz':
                      return 'New Zealand';
                    case 'en-us':
                      return 'United States of America';
                    case 'es-mx':
                      return 'Mexico';
                    case 'fr-cd':
                      return 'République Démocratique du Congo';
                    case 'sw-ke':
                      return 'Kenya';
                    case 'th-th':
                      return 'ประเทศไทย';
                    default:
                      return null;
                  }
                })()}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

};

export default memo(LanguagePicker);
