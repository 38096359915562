import { Box, Button, Card, CardActions, CardContent, Checkbox, Container, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, Link, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useHotJar from 'react-use-hotjar';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { model } from '@whizz/api-client';
import { Translate, useI18n } from '@whizz/react-i18n';
import React, { useEffect, useMemo } from 'react';
import ParentLayout from '../../layouts/ParentLayout';
import PasswordStrengthIndicator from '../../partials/PasswordStrengthIndicator';

const useStyles = makeStyles((theme) => ({
  disableReveal: { // Disable the password-reveal feature in IE >= 10 and Edge <= 44 (EdgeHTML 18)
    '&::-ms-reveal': {
      display: 'none',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  mainTitle: {
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  whiteSpacePre: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  alreadyRegistedContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& hr': {
      marginBottom: '1rem',
    }
  },
  alreadyRegistered: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  flexCenterCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '0.5rem',
    }
  },
}));

const View = ({
  countryProps = {},
  disableMarketingReach = true,
  emailProps = {},
  firstNameProps = {},
  lastNameProps = {},
  marketingReachProps = {},
  onShowPasswordClick = (event) => {},
  onSubmit = (event) => {},
  passwordProps = {},
  passwordValid = false,
  showPassword = false,
  submittable = false,
  submitting = false,
}) => {

  const classes = useStyles();
  const { locale, translate } = useI18n();

  const { initHotjar } = useHotJar();

  const countries = useMemo(() => (
    Object.entries({
      ad: translate('RegistrationPage.COUNTRY_AD'),
      ae: translate('RegistrationPage.COUNTRY_AE'),
      af: translate('RegistrationPage.COUNTRY_AF'),
      ag: translate('RegistrationPage.COUNTRY_AG'),
      ai: translate('RegistrationPage.COUNTRY_AI'),
      al: translate('RegistrationPage.COUNTRY_AL'),
      am: translate('RegistrationPage.COUNTRY_AM'),
      an: translate('RegistrationPage.COUNTRY_AN'),
      ao: translate('RegistrationPage.COUNTRY_AO'),
      aq: translate('RegistrationPage.COUNTRY_AQ'),
      ar: translate('RegistrationPage.COUNTRY_AR'),
      as: translate('RegistrationPage.COUNTRY_AS'),
      at: translate('RegistrationPage.COUNTRY_AT'),
      au: translate('RegistrationPage.COUNTRY_AU'),
      aw: translate('RegistrationPage.COUNTRY_AW'),
      ax: translate('RegistrationPage.COUNTRY_AX'),
      az: translate('RegistrationPage.COUNTRY_AZ'),
      ba: translate('RegistrationPage.COUNTRY_BA'),
      bb: translate('RegistrationPage.COUNTRY_BB'),
      bd: translate('RegistrationPage.COUNTRY_BD'),
      be: translate('RegistrationPage.COUNTRY_BE'),
      bf: translate('RegistrationPage.COUNTRY_BF'),
      bg: translate('RegistrationPage.COUNTRY_BG'),
      bh: translate('RegistrationPage.COUNTRY_BH'),
      bi: translate('RegistrationPage.COUNTRY_BI'),
      bj: translate('RegistrationPage.COUNTRY_BJ'),
      bl: translate('RegistrationPage.COUNTRY_BL'),
      bm: translate('RegistrationPage.COUNTRY_BM'),
      bn: translate('RegistrationPage.COUNTRY_BN'),
      bo: translate('RegistrationPage.COUNTRY_BO'),
      bq: translate('RegistrationPage.COUNTRY_BQ'),
      br: translate('RegistrationPage.COUNTRY_BR'),
      bs: translate('RegistrationPage.COUNTRY_BS'),
      bt: translate('RegistrationPage.COUNTRY_BT'),
      bv: translate('RegistrationPage.COUNTRY_BV'),
      bw: translate('RegistrationPage.COUNTRY_BW'),
      by: translate('RegistrationPage.COUNTRY_BY'),
      bz: translate('RegistrationPage.COUNTRY_BZ'),
      ca: translate('RegistrationPage.COUNTRY_CA'),
      cc: translate('RegistrationPage.COUNTRY_CC'),
      cd: translate('RegistrationPage.COUNTRY_CD'),
      cf: translate('RegistrationPage.COUNTRY_CF'),
      cg: translate('RegistrationPage.COUNTRY_CG'),
      ch: translate('RegistrationPage.COUNTRY_CH'),
      ci: translate('RegistrationPage.COUNTRY_CI'),
      ck: translate('RegistrationPage.COUNTRY_CK'),
      cl: translate('RegistrationPage.COUNTRY_CL'),
      cm: translate('RegistrationPage.COUNTRY_CM'),
      cn: translate('RegistrationPage.COUNTRY_CN'),
      co: translate('RegistrationPage.COUNTRY_CO'),
      cr: translate('RegistrationPage.COUNTRY_CR'),
      cu: translate('RegistrationPage.COUNTRY_CU'),
      cv: translate('RegistrationPage.COUNTRY_CV'),
      cw: translate('RegistrationPage.COUNTRY_CW'),
      cx: translate('RegistrationPage.COUNTRY_CX'),
      cy: translate('RegistrationPage.COUNTRY_CY'),
      cz: translate('RegistrationPage.COUNTRY_CZ'),
      de: translate('RegistrationPage.COUNTRY_DE'),
      dj: translate('RegistrationPage.COUNTRY_DJ'),
      dk: translate('RegistrationPage.COUNTRY_DK'),
      dm: translate('RegistrationPage.COUNTRY_DM'),
      do: translate('RegistrationPage.COUNTRY_DO'),
      dz: translate('RegistrationPage.COUNTRY_DZ'),
      ec: translate('RegistrationPage.COUNTRY_EC'),
      ee: translate('RegistrationPage.COUNTRY_EE'),
      eg: translate('RegistrationPage.COUNTRY_EG'),
      eh: translate('RegistrationPage.COUNTRY_EH'),
      er: translate('RegistrationPage.COUNTRY_ER'),
      es: translate('RegistrationPage.COUNTRY_ES'),
      et: translate('RegistrationPage.COUNTRY_ET'),
      fi: translate('RegistrationPage.COUNTRY_FI'),
      fj: translate('RegistrationPage.COUNTRY_FJ'),
      fk: translate('RegistrationPage.COUNTRY_FK'),
      fm: translate('RegistrationPage.COUNTRY_FM'),
      fo: translate('RegistrationPage.COUNTRY_FO'),
      fr: translate('RegistrationPage.COUNTRY_FR'),
      ga: translate('RegistrationPage.COUNTRY_GA'),
      gb: translate('RegistrationPage.COUNTRY_GB'),
      gd: translate('RegistrationPage.COUNTRY_GD'),
      ge: translate('RegistrationPage.COUNTRY_GE'),
      gf: translate('RegistrationPage.COUNTRY_GF'),
      gg: translate('RegistrationPage.COUNTRY_GG'),
      gh: translate('RegistrationPage.COUNTRY_GH'),
      gi: translate('RegistrationPage.COUNTRY_GI'),
      gl: translate('RegistrationPage.COUNTRY_GL'),
      gm: translate('RegistrationPage.COUNTRY_GM'),
      gn: translate('RegistrationPage.COUNTRY_GN'),
      gp: translate('RegistrationPage.COUNTRY_GP'),
      gq: translate('RegistrationPage.COUNTRY_GQ'),
      gr: translate('RegistrationPage.COUNTRY_GR'),
      gs: translate('RegistrationPage.COUNTRY_GS'),
      gt: translate('RegistrationPage.COUNTRY_GT'),
      gu: translate('RegistrationPage.COUNTRY_GU'),
      gw: translate('RegistrationPage.COUNTRY_GW'),
      gy: translate('RegistrationPage.COUNTRY_GY'),
      hk: translate('RegistrationPage.COUNTRY_HK'),
      hm: translate('RegistrationPage.COUNTRY_HM'),
      hn: translate('RegistrationPage.COUNTRY_HN'),
      hr: translate('RegistrationPage.COUNTRY_HR'),
      ht: translate('RegistrationPage.COUNTRY_HT'),
      hu: translate('RegistrationPage.COUNTRY_HU'),
      id: translate('RegistrationPage.COUNTRY_ID'),
      ie: translate('RegistrationPage.COUNTRY_IE'),
      il: translate('RegistrationPage.COUNTRY_IL'),
      im: translate('RegistrationPage.COUNTRY_IM'),
      in: translate('RegistrationPage.COUNTRY_IN'),
      io: translate('RegistrationPage.COUNTRY_IO'),
      iq: translate('RegistrationPage.COUNTRY_IQ'),
      ir: translate('RegistrationPage.COUNTRY_IR'),
      is: translate('RegistrationPage.COUNTRY_IS'),
      it: translate('RegistrationPage.COUNTRY_IT'),
      je: translate('RegistrationPage.COUNTRY_JE'),
      jm: translate('RegistrationPage.COUNTRY_JM'),
      jo: translate('RegistrationPage.COUNTRY_JO'),
      jp: translate('RegistrationPage.COUNTRY_JP'),
      ke: translate('RegistrationPage.COUNTRY_KE'),
      kg: translate('RegistrationPage.COUNTRY_KG'),
      kh: translate('RegistrationPage.COUNTRY_KH'),
      ki: translate('RegistrationPage.COUNTRY_KI'),
      km: translate('RegistrationPage.COUNTRY_KM'),
      kn: translate('RegistrationPage.COUNTRY_KN'),
      kp: translate('RegistrationPage.COUNTRY_KP'),
      kr: translate('RegistrationPage.COUNTRY_KR'),
      kw: translate('RegistrationPage.COUNTRY_KW'),
      ky: translate('RegistrationPage.COUNTRY_KY'),
      kz: translate('RegistrationPage.COUNTRY_KZ'),
      la: translate('RegistrationPage.COUNTRY_LA'),
      lb: translate('RegistrationPage.COUNTRY_LB'),
      lc: translate('RegistrationPage.COUNTRY_LC'),
      li: translate('RegistrationPage.COUNTRY_LI'),
      lk: translate('RegistrationPage.COUNTRY_LK'),
      lr: translate('RegistrationPage.COUNTRY_LR'),
      ls: translate('RegistrationPage.COUNTRY_LS'),
      lt: translate('RegistrationPage.COUNTRY_LT'),
      lu: translate('RegistrationPage.COUNTRY_LU'),
      lv: translate('RegistrationPage.COUNTRY_LV'),
      ly: translate('RegistrationPage.COUNTRY_LY'),
      ma: translate('RegistrationPage.COUNTRY_MA'),
      mc: translate('RegistrationPage.COUNTRY_MC'),
      md: translate('RegistrationPage.COUNTRY_MD'),
      me: translate('RegistrationPage.COUNTRY_ME'),
      mf: translate('RegistrationPage.COUNTRY_MF'),
      mg: translate('RegistrationPage.COUNTRY_MG'),
      mh: translate('RegistrationPage.COUNTRY_MH'),
      mk: translate('RegistrationPage.COUNTRY_MK'),
      ml: translate('RegistrationPage.COUNTRY_ML'),
      mm: translate('RegistrationPage.COUNTRY_MM'),
      mn: translate('RegistrationPage.COUNTRY_MN'),
      mo: translate('RegistrationPage.COUNTRY_MO'),
      mp: translate('RegistrationPage.COUNTRY_MP'),
      mq: translate('RegistrationPage.COUNTRY_MQ'),
      mr: translate('RegistrationPage.COUNTRY_MR'),
      ms: translate('RegistrationPage.COUNTRY_MS'),
      mt: translate('RegistrationPage.COUNTRY_MT'),
      mu: translate('RegistrationPage.COUNTRY_MU'),
      mv: translate('RegistrationPage.COUNTRY_MV'),
      mw: translate('RegistrationPage.COUNTRY_MW'),
      mx: translate('RegistrationPage.COUNTRY_MX'),
      my: translate('RegistrationPage.COUNTRY_MY'),
      mz: translate('RegistrationPage.COUNTRY_MZ'),
      na: translate('RegistrationPage.COUNTRY_NA'),
      nc: translate('RegistrationPage.COUNTRY_NC'),
      ne: translate('RegistrationPage.COUNTRY_NE'),
      nf: translate('RegistrationPage.COUNTRY_NF'),
      ng: translate('RegistrationPage.COUNTRY_NG'),
      ni: translate('RegistrationPage.COUNTRY_NI'),
      nl: translate('RegistrationPage.COUNTRY_NL'),
      no: translate('RegistrationPage.COUNTRY_NO'),
      np: translate('RegistrationPage.COUNTRY_NP'),
      nr: translate('RegistrationPage.COUNTRY_NR'),
      nu: translate('RegistrationPage.COUNTRY_NU'),
      nz: translate('RegistrationPage.COUNTRY_NZ'),
      om: translate('RegistrationPage.COUNTRY_OM'),
      pa: translate('RegistrationPage.COUNTRY_PA'),
      pe: translate('RegistrationPage.COUNTRY_PE'),
      pf: translate('RegistrationPage.COUNTRY_PF'),
      pg: translate('RegistrationPage.COUNTRY_PG'),
      ph: translate('RegistrationPage.COUNTRY_PH'),
      pk: translate('RegistrationPage.COUNTRY_PK'),
      pl: translate('RegistrationPage.COUNTRY_PL'),
      pm: translate('RegistrationPage.COUNTRY_PM'),
      pn: translate('RegistrationPage.COUNTRY_PN'),
      pr: translate('RegistrationPage.COUNTRY_PR'),
      ps: translate('RegistrationPage.COUNTRY_PS'),
      pt: translate('RegistrationPage.COUNTRY_PT'),
      pw: translate('RegistrationPage.COUNTRY_PW'),
      py: translate('RegistrationPage.COUNTRY_PY'),
      qa: translate('RegistrationPage.COUNTRY_QA'),
      re: translate('RegistrationPage.COUNTRY_RE'),
      ro: translate('RegistrationPage.COUNTRY_RO'),
      rs: translate('RegistrationPage.COUNTRY_RS'),
      rw: translate('RegistrationPage.COUNTRY_RW'),
      sa: translate('RegistrationPage.COUNTRY_SA'),
      sb: translate('RegistrationPage.COUNTRY_SB'),
      sc: translate('RegistrationPage.COUNTRY_SC'),
      sd: translate('RegistrationPage.COUNTRY_SD'),
      se: translate('RegistrationPage.COUNTRY_SE'),
      sg: translate('RegistrationPage.COUNTRY_SG'),
      sh: translate('RegistrationPage.COUNTRY_SH'),
      si: translate('RegistrationPage.COUNTRY_SI'),
      sj: translate('RegistrationPage.COUNTRY_SJ'),
      sk: translate('RegistrationPage.COUNTRY_SK'),
      sl: translate('RegistrationPage.COUNTRY_SL'),
      sm: translate('RegistrationPage.COUNTRY_SM'),
      sn: translate('RegistrationPage.COUNTRY_SN'),
      so: translate('RegistrationPage.COUNTRY_SO'),
      sr: translate('RegistrationPage.COUNTRY_SR'),
      ss: translate('RegistrationPage.COUNTRY_SS'),
      st: translate('RegistrationPage.COUNTRY_ST'),
      sv: translate('RegistrationPage.COUNTRY_SV'),
      sx: translate('RegistrationPage.COUNTRY_SX'),
      sy: translate('RegistrationPage.COUNTRY_SY'),
      sz: translate('RegistrationPage.COUNTRY_SZ'),
      tc: translate('RegistrationPage.COUNTRY_TC'),
      td: translate('RegistrationPage.COUNTRY_TD'),
      tf: translate('RegistrationPage.COUNTRY_TF'),
      tg: translate('RegistrationPage.COUNTRY_TG'),
      th: translate('RegistrationPage.COUNTRY_TH'),
      tj: translate('RegistrationPage.COUNTRY_TJ'),
      tk: translate('RegistrationPage.COUNTRY_TK'),
      tl: translate('RegistrationPage.COUNTRY_TL'),
      tm: translate('RegistrationPage.COUNTRY_TM'),
      tn: translate('RegistrationPage.COUNTRY_TN'),
      to: translate('RegistrationPage.COUNTRY_TO'),
      tr: translate('RegistrationPage.COUNTRY_TR'),
      tt: translate('RegistrationPage.COUNTRY_TT'),
      tv: translate('RegistrationPage.COUNTRY_TV'),
      tw: translate('RegistrationPage.COUNTRY_TW'),
      tz: translate('RegistrationPage.COUNTRY_TZ'),
      ua: translate('RegistrationPage.COUNTRY_UA'),
      ug: translate('RegistrationPage.COUNTRY_UG'),
      um: translate('RegistrationPage.COUNTRY_UM'),
      us: translate('RegistrationPage.COUNTRY_US'),
      uy: translate('RegistrationPage.COUNTRY_UY'),
      uz: translate('RegistrationPage.COUNTRY_UZ'),
      va: translate('RegistrationPage.COUNTRY_VA'),
      vc: translate('RegistrationPage.COUNTRY_VC'),
      ve: translate('RegistrationPage.COUNTRY_VE'),
      vg: translate('RegistrationPage.COUNTRY_VG'),
      vi: translate('RegistrationPage.COUNTRY_VI'),
      vn: translate('RegistrationPage.COUNTRY_VN'),
      vu: translate('RegistrationPage.COUNTRY_VU'),
      wf: translate('RegistrationPage.COUNTRY_WF'),
      ws: translate('RegistrationPage.COUNTRY_WS'),
      ye: translate('RegistrationPage.COUNTRY_YE'),
      yt: translate('RegistrationPage.COUNTRY_YT'),
      za: translate('RegistrationPage.COUNTRY_ZA'),
      zm: translate('RegistrationPage.COUNTRY_ZM'),
      zw: translate('RegistrationPage.COUNTRY_ZW'),
    })
      .sort(([ , a ], [ , b ]) => a === b ? 0 : a > b ? 1 : -1)
      .reduce((r, [ k, v ]) => ({ ...r, [k]: v }), {})
  ), [ translate ]);

  useEffect(() => {
    initHotjar(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION, true);
  }, [ initHotjar ]);

  return (
    <ParentLayout>
      <Typography align={'center'} variant={'h2'} className={classes.mainTitle}>{translate('RegistrationPage.MAIN_TITLE')}</Typography>
      <Container maxWidth="sm">
        <Card component="form" onSubmit={onSubmit}>

          <CardContent>

            <Typography gutterBottom variant="h5" align={'center'} className={classes.title}>{translate('RegistrationPage.TITLE')}</Typography>
            <Typography paragraph className={classes.whiteSpacePre}>
              <Translate i18nKey={'RegistrationPage.MESSAGE_1'}>
                <strong/>
              </Translate>
            </Typography>

            <Grid container spacing={1} data-cy={'form-fields'}>
              <Grid item xs={6}>

                <TextField
                  {...firstNameProps}
                  label={translate('RegistrationPage.FIRST_NAME')}
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                />

              </Grid>
              <Grid item xs={6}>

                <TextField
                  {...lastNameProps}
                  label={translate('RegistrationPage.LAST_NAME')}
                  margin="normal"
                  required
                  fullWidth
                />

              </Grid>
              <Grid item xs={12} sm={6}>

                <TextField
                  {...emailProps}
                  type="email"
                  label={translate('RegistrationPage.EMAIL')}
                  margin="normal"
                  required
                  fullWidth
                />

              </Grid>
              <Grid item xs={12} sm={6}>

                <TextField
                  {...passwordProps}
                  type={showPassword ? 'text' : 'password'}
                  label={translate('RegistrationPage.PASSWORD')}
                  helperText={!passwordValid && translate('RegistrationPage.PASSWORD_HINT', { count: model.Parent.PASSWORD_MIN_LENGTH })}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="new-password"
                  InputProps={{
                    inputProps: {
                      className: classes.disableReveal,
                      pattern: model.Parent.PASSWORD_REGEXP.source,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={passwordProps.disabled} onClick={onShowPasswordClick}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {passwordValid && <PasswordStrengthIndicator password={passwordProps.value} />}

              </Grid>

              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth disabled={countryProps.disabled} error={countryProps.error}>
                  <InputLabel htmlFor="country">{translate('RegistrationPage.COUNTRY')}</InputLabel>
                  <Select
                    inputProps={{
                      name: countryProps.name,
                      id: 'country',
                    }}
                    onChange={countryProps.onChange}
                    value={countryProps.value || ''}
                  >
                    {Object.entries(countries).map(([ value, label ]) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Box mt={3}>

                {!disableMarketingReach && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox {...marketingReachProps} />}
                      label={
                        <Typography variant="caption">
                          <Translate i18nKey="RegistrationPage.MARKETING_REACH">
                            <Link href="https://reachplc.gcs-web.com/static-files/4d7b8fad-b416-4cc1-9c7d-2bcefe1785dd" target="_blank" />
                          </Translate>
                        </Typography>
                      }
                    />
                  </Grid>
                )}

              </Box>

            </Grid>

          </CardContent>

          <CardActions className={classes.flexCenterCenter}>
            <Button type="submit" variant={'contained'} color={'secondary'} disabled={!submittable}>{translate('RegistrationPage.SAVE')}</Button>
          </CardActions>

          {submitting && <LinearProgress />}

          <CardContent className={classes.alreadyRegistedContainer}>
            <Divider />
            <div className={classes.flexCenterCenter}>
              <Typography className={classes.alreadyRegistered} align={'center'} gutterBottom>{translate('RegistrationPage.ALREADY_REGISTERED')}</Typography>
              <Button to={'/'} component={RouterLink} size={'small'} variant={'outlined'} color={'secondary'}>{translate('RegistrationPage.LOG_IN')}</Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    </ParentLayout>
  );

};

export default View;
