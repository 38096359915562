/* eslint-disable sort-imports */
/* eslint-disable @typescript-eslint/no-use-before-define */
import type { Code } from './StudentPin';
import React from 'react';
import { Dialog, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search, Backspace, Close, ArrowBack } from '@material-ui/icons';
import { GroupPictureLoginProps, Student, formatInput } from './Container';
import classNames from 'classnames';
import ParentLayout from '../../layouts/ParentLayout';
import heart from '../../resources/animations/heart.json';
import burst from '../../resources/animations/burst.json';
import arrowUp from '../../resources/animations/arrow_up.json';
import Lottie from 'react-lottie-player';
import { Link } from 'react-router-dom';
import { Avatar } from '@whizz/react-avatar';
import { Autocomplete } from '@material-ui/lab';
import { useI18n } from '@whizz/react-i18n';
import { PinIcon, pinIcons } from './StudentPin';
import { palette } from '@whizz/react-branding';

type GroupPictureLoginType = {
  className: string;
  students: Student[];
};
interface ViewProps {
  accessCode: string | null;
  accessCodes: { label: string; code: string}[];
  classLabel: string | null;
  students: GroupPictureLoginType['students'] | undefined;
  searchQuery: string;
  selectedStudent: Student | null;
  password: string;
  state: GroupPictureLoginProps['state'];
  incorrectPassword: GroupPictureLoginProps['password'];
  onSubmitAccessCode: () => void;
  onAccessCodeChange: (value: string) => void;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStudentSelect: (student: Student | null) => void;
  onPasswordChange: (value: string) => void;
  onUndo: () => void;
}

const useStyles = makeStyles((theme) => ({
  codeInput: {
    fontFamily: 'monospace, Courier',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    textAlign: 'center',
    fontSize: '1.5rem',
    '@media (min-width: 1280px)': {
      fontSize: '2rem',
    },
  },
  codeAutoComplete: {
    fontSize: '1rem',
    fontWeight: 'bold',
    '& span': {
      fontFamily: 'monospace, Courier',
      fontWeight: 'normal',
      letterSpacing: '0.1em',
      fontSize: '0.8em',
      whiteSpace: 'nowrap'
    },
  },
  disableReveal: { // Disable the password-reveal feature in IE >= 10 and Edge <= 44 (EdgeHTML 18)
    '&::-ms-reveal': {
      display: 'none',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
  },
  pinGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
  },
}));

const stringToTileColor = (str: string): string => {
  const tileColors = [ palette.blue.main, palette.green.main, palette.orange.main, palette.purple.main, palette.red.main, palette.yellow.main ];

  const num = str
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const index = num % tileColors.length;
  return tileColors[index];
};

const View = ({ state, accessCode, accessCodes, classLabel, students, searchQuery, selectedStudent, password, incorrectPassword, onSubmitAccessCode, onSearchChange, onStudentSelect, onAccessCodeChange, onPasswordChange, onUndo }: ViewProps): JSX.Element => {

  const classes = useStyles();
  const { translate } = useI18n();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmitAccessCode();
  };

  const hasClass = [ 'success', 'search_error' ].includes(state);

  let content;

  switch (state) {
    case 'initial':
      content = (
        <div className={'flex justify-center items-end gap-4 mr-10'}>
          <h2 className={'text-2xl font-bold'}>{translate('PictureLoginPage.INITIAL')}</h2>
          <Lottie
            animationData={arrowUp}
            play
            speed={0.7}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            style={{
              transform: 'scaleX(-1)',
            }}
          />
        </div>
      );
      break;
    case 'success':
      content = <StudentGrid students={students} onSelect={onStudentSelect} />;
      break;
    case 'loading':
      content = (
        <div className={'flex justify-center items-center'}>
          <h2 className={'text-2xl font-bold'}>{translate('PictureLoginPage.LOADING')}</h2>
        </div>
      );
      break;
    case 'search_error':
      content = (
        <div className={'flex justify-center items-center'}>
          <h2 className={'text-2xl font-bold'}>{translate('PictureLoginPage.SEARCH_ERROR')}</h2>
        </div>
      );
      break;
    case 'empty':
      content = (
        <div className={'flex justify-center items-center'}>
          <h2 className={'text-2xl font-bold'}>{translate('PictureLoginPage.NO_STUDENTS')}</h2>
        </div>
      );
      break;
    case 'error':
      content = (
        <div className={'flex justify-center items-center'}>
          <h2 className={'text-2xl font-bold'}>{translate('PictureLoginPage.CLASS_CODE_NOT_FOUND')}</h2>
        </div>
      );
      break;
    default:
      content = null;
      break;
  }

  return (
    <div className={classNames({ 'filter blur-sm': !!selectedStudent})}>
      <ParentLayout maxWidth={'xl'}>
        <div className={'bg-white bg-opacity-90 rounded-lg border-2 shadow-md p-4'}>
          <div className={`flex flex-col xl:flex-row justify-between items-center gap-4 ${classNames({ 'my-4': !hasClass })}`}>
            <div className={'relative flex items-center gap-4 w-full xl:w-1/3'}>
              <Link to={'/'} className={'rounded-md bg-blue-400 hover:bg-blue-900 shadow-md py-2 px-4 flex justify-center items-center gap-2 uppercase font-bold text-white'}>
                <ArrowBack fontSize={'inherit'} />
                <span className={'hidden sm:block'}>{translate('PictureLoginPage.NORMAL_LOGIN')}</span>
              </Link>
              <h1 className={'text-lg sm:text-xl font-bold absolute left-1/2 transform -translate-x-1/2 xl:static xl:transform-none whitespace-nowrap'}>{translate('PictureLoginPage.TITLE')}</h1>
            </div>
            <div className={'flex flex-col lg:flex-row items-center justify-end gap-4 w-full xl:w-2/3'}>
              { hasClass ? (
                <div className={'flex order-last lg:order-none justify-center items-center p-4 rounded-t-md bg-gray-200 w-full'}>
                  <TextField
                    className={'bg-white text-center w-full'}
                    hiddenLabel
                    aria-label={translate('PictureLoginPage.SEARCH_NAME')}
                    type={'search'}
                    variant={'outlined'}
                    placeholder={translate('PictureLoginPage.NAME')}
                    margin={'none'}
                    value={searchQuery}
                    onChange={onSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>) : null }
              <div className={'flex items-center justify-end space-x-2 w-full'}>
                <h1 className={'text-xl font-bold md:whitespace-nowrap'}>{hasClass ? classLabel : translate('PictureLoginPage.ENTER_CLASS_CODE')}</h1>
                <form onSubmit={handleSubmit} className={'flex justify-end items-center space-x-2 w-full xl:w-96'}>
                  <Autocomplete
                    className={'w-full'}
                    classes={{
                      option: classes.codeAutoComplete,
                    }}
                    freeSolo
                    blurOnSelect
                    value={accessCode ?? undefined}
                    inputValue={accessCode ?? ''}
                    options={accessCodes}
                    disableClearable
                    forcePopupIcon={false}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return formatInput(option.code);
                    }}
                    onInputChange={(_, value) => onAccessCodeChange(value)}
                    onChange={(_, value) => {
                      if (typeof value === 'object' && value !== null && 'code' in value) {
                        onAccessCodeChange(value.code);
                      }
                    }}
                    renderOption={(option) => <p>{option.label}: <span>{formatInput(option.code)}</span></p>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={'bg-white w-full'}
                        type={'search'}
                        label={translate('PictureLoginPage.CLASS_CODE')}
                        placeholder={'ABC-DEF-GHJ'}
                        variant={'outlined'}
                        margin={'none'}
                        fullWidth
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          shrink: true,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            input: classes.codeInput,
                          },
                        }}
                      />
                    )}
                  />
                  <button type={'submit'} className={'rounded-md bg-blue-400 hover:bg-blue-900 shadow-md py-2 px-4 flex justify-center items-center gap-2 uppercase font-bold text-white'}>{translate('PictureLoginPage.SUBMIT')}</button>
                </form>
              </div>
            </div>
          </div>
          <div className='w-full border-t-2 border-gray-200 pt-4'>
            {content}
          </div>
          <Password
            password={password}
            incorrectPassword={incorrectPassword}
            student={selectedStudent}
            onClose={() => onStudentSelect(null)}
            onPasswordChange={onPasswordChange}
            onUndo={onUndo}
          />
        </div>
      </ParentLayout>
    </div>
  );
};

const StudentGrid = ( { students, onSelect }: { students: ViewProps['students'], onSelect: (student: Student ) => void }) => {

  const getTextSize = (name: string) => {
    if (name.length > 20) {
      return 'text-sm';
    }
    if (name.length > 12) {
      return 'text-lg';
    }
    if (name.length > 9) {
      return 'text-xl';
    }
    return 'text-2xl';
  };
  return (
    <div className={'flex flex-wrap justify-center items-stretch'}>
      {students?.map((student: Student) => {
        const { firstName, lastName, username, avatar } = student;
        const color = stringToTileColor(username);
        return (
          <button key={student.username} onClick={() => onSelect(student)} className={'w-full sm:w-1/2 md:w-1/3 lg:w-1/6 2xl:w-1/9 p-2'}>
            <div className={'flex flex-col items-center rounded-md shadow-md h-full p-3 space-y-1 bg-white border-8 filter transform transition-transform hover:scale-105'} style={{ borderColor: color }}>
              <div className={'-space-y-1'}>
                <h4 className={`font-bold leading-tight ${getTextSize(firstName)}`}>{firstName}</h4>
                <h6 className={'text-sm leading-tight font-bold'}>{lastName}</h6>
              </div>
              <div className={'w-20 h-20'}>
                <Avatar
                  avatar={avatar}
                  size={'scale'}
                />
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

const Password = ( { student, password, incorrectPassword, onClose, onPasswordChange, onUndo }: Pick<ViewProps, 'password' | 'incorrectPassword' | 'onPasswordChange' | 'onUndo'> & { student: Student | null, onClose: () => void } ) => {

  const classes = useStyles();

  const handleOnClick = (value: Code['value']) => () => {
    onPasswordChange(value);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleUndo = () => {
    onUndo();
  };

  const waiting = incorrectPassword && [ 'loading', 'correct' ].includes(incorrectPassword);

  return (
    <Dialog
      open={!!student}
      fullWidth
      maxWidth={'sm'}
      onClose={handleOnClose}
    >
      <div className={`bg-white rounded-lg border-2 shadow-md p-2 xl:p-4 space-y-4 ${classNames({ 'cursor-wait': waiting})}`}>
        <button className={'absolute right-3 top-3'} onClick={handleOnClose}>
          <Close fontSize={'large'} />
        </button>
        <div className={'flex justify-center items-center gap-2'}>
          { student ?
            (
              <>
                <div className={'w-20 h-20'}>
                  <Avatar
                    userLevel={'LEVEL_ROOKIE'}
                    avatar={student.avatar}
                    size={'scale'}
                  />
                </div>
                <h4 className={'text-2xl font-bold'}>{`${student.firstName} ${student.lastName}`}</h4>
              </>
            ) : null }
        </div>
        <div className={`space-y-4 ${classNames({ 'pointer-events-none': waiting })}`}>
          <div className={'flex justify-center items-center space-y-2 rounded-lg bg-gray-100 p-4 bg-opacity-30'}>
            <div className={'grid auto-cols-min gap-2'}>
              {pinIcons.map((icon) => {
                return (
                  <button
                    key={icon.value}
                    onClick={handleOnClick(icon.value)}
                    className={'justify-self-center self-center w-14 h-14 xl:w-16 xl:h-16 flex grow-0 justify-center items-center rounded-md border-2 border-gray-300 shadow-sm bg-white hover:bg-green-200 disabled:bg-white disabled:cursor-default'}
                    disabled={!!incorrectPassword}
                  >
                    <PinIcon letter={icon.value} />
                  </button>
                );
              })}
              <div className={'col-span-5 space-y-2'}>
                <hr />
                <div className={`${classes.pinGrid} gap-4`}>
                  <div className={`flex justify-center items-center space-x-2 col-start-2 ${classNames({ 'animate-shake': incorrectPassword === 'incorrect' })}`}>
                    <PinItem password={password} index={0} incorrect={incorrectPassword} />
                    <PinItem password={password} index={1} incorrect={incorrectPassword} />
                    <PinItem password={password} index={2} incorrect={incorrectPassword} />
                  </div>
                  <button onClick={handleUndo} disabled={!!incorrectPassword} className={'flex self-center text-7xl text-gray-900 hover:text-red-500 disabled:opacity-50 disabled:text-gray-900 disabled:cursor-default'}>
                    <Backspace fontSize={'inherit'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const PinItem = ({ password, index, incorrect, className = ''}: { password: string, index: number, incorrect: ViewProps['incorrectPassword'], className?: string }) => {
  const splitPassword = password ? password.split('') : null;
  const icon = pinIcons.find((icon) => icon.value === splitPassword?.[index]);
  const showIcon = incorrect && [ 'correct', 'incorrect' ].includes(incorrect);
  return (
    <div className={`justify-self-center self-center overflow-hidden w-14 h-14 xl:w-16 xl:h-16 flex grow-0 justify-center items-center rounded-md border-2 border-gray-300 shadow-sm bg-white ${className} ${classNames({ 'border-success border-4': incorrect === 'correct', 'border-error border-4': incorrect === 'incorrect' })}`}>
      { showIcon ? <Burst type={incorrect}/> : icon ? <PinIcon letter={icon.value} /> : '' }
    </div>
  );
};

const Burst = ({ type }: { type: Exclude<ViewProps['incorrectPassword'], false>}) => {
  let anim;
  switch (type) {
    case 'correct':
      anim = heart;
      break;
    case 'incorrect':
      anim = burst;
      break;
  }
  return (
    <Lottie
      animationData={anim}
      play
      loop
      speed={0.7}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      style={{
        width: 80,
        height: 80,
      }}
    />
  );
};

export default View;
