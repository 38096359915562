import { model } from '@whizz/api-client';
import { useI18n } from '@whizz/react-i18n';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFlash } from '../../contexts/flash';
import useForm from '../../hooks/useForm';
import useTitle from '../../hooks/useTitle';
import { handleLogin } from '../../utils';
import View from './View';

const submitFn = (parent, password, referralTrackingId = null, marketingReach = false) => (
  model.Identity.register(parent, password, referralTrackingId, marketingReach)
    .then((parent) => model.Identity.login({ password, username: parent.email }))
    .then((response) => new Promise((resolve) => {
      const done = () => resolve(response);
      if ('dataLayer' in window) {
        window.dataLayer.push({
          event: 'parentsCreateAccount',
          eventCallback: done,
          eventTimeout: 2000,
        });
      } else {
        done();
      }
    }))
);

const submittableFn = (inputs, dirty) => inputs.country && inputs.email && inputs.first_name && inputs.last_name && inputs.password;

const Container = () => {

  const history = useHistory();
  const { error } = useFlash();
  const { locale, translate } = useI18n();
  const [ disableMarketingReach, setDisableMarketingReach ] = useState(true);

  const defaults = useMemo(() => ({
    country: locale.country,
    email: null,
    first_name: null,
    last_name: null,
    marketing_reach: false,
    password: null,
    referral_tracking_id: null,
    show_password: false,
  }), [ locale ]);

  const {
    checkboxProps,
    fatal,
    input,
    inputs,
    rejection,
    resolution,
    submit,
    submitted,
    submittable,
    submitting,
    textFieldProps,
    error: formError,
  } = useForm(defaults, submitFn, submittableFn);

  useTitle(translate('RegistrationPage.TITLE'));

  useEffect(() => {
    if ('ttq' in window) {
      window.ttq.page();
    }
  }, []);

  useEffect(() => {

    // eslint-disable-next-line
    (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://r.wdfl.co/rw.js';
    script.async = true;
    script.setAttribute('data-rewardful', '7320d6');
    head.appendChild(script);

    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        input('referral_tracking_id', window.Rewardful.referral);
      }
      if (window.Rewardful.campaign && '452a13c4-8ff0-49b0-b17a-202544d70903' === window.Rewardful.campaign.id) {
        setDisableMarketingReach(false);
      }
    });

    return () => {
      head.removeChild(script);
    };

  }, [ input ]);

  useEffect(() => {

    // When embedded in an <iframe> (such as on https://www.whizz.com/parent/create-account/) we'll
    // make some page information available to the parent window
    if (window !== window.top) {
      window.top.postMessage({ locale: locale.id, title: window.document.title }, '*');
    }

  }, [ locale ]);

  useEffect(() => {

    if (!submitted) {
      return;
    }

    if (rejection) {
      if (rejection.response && 409 === rejection.response.status && 'Email Already Registered' === rejection.response.data.error) {
        error(translate('RegistrationPage.SAVE_ERROR_EMAIL_ALREADY_REGISTERED'));
        formError('email');
      } else {
        error(translate('RegistrationPage.SAVE_ERROR'));
        fatal();
      }
    } else {
      handleLogin(resolution, locale);
    }

  }, [ error, fatal, formError, history, locale, rejection, resolution, submitted, translate ]);

  const passwordValid = useMemo(() => model.User.validatePassword(inputs.password), [ inputs.password ]);

  const handleShowPasswordClick = (event) => {
    input('show_password', !inputs.show_password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submit(new model.Parent({
      locale,
      country: inputs.country,
      email: inputs.email,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
    }), inputs.password, inputs.referral_tracking_id, inputs.marketing_reach);
  };

  if (resolution) {
    return null;
  }

  return <View
    countryProps={textFieldProps('country')}
    disableMarketingReach={disableMarketingReach}
    emailProps={textFieldProps('email')}
    firstNameProps={textFieldProps('first_name')}
    lastNameProps={textFieldProps('last_name')}
    marketingReachProps={checkboxProps('marketing_reach')}
    onShowPasswordClick={handleShowPasswordClick}
    onSubmit={handleSubmit}
    passwordProps={textFieldProps('password')}
    passwordValid={passwordValid}
    showPassword={inputs.show_password}
    submittable={submittable}
    submitting={submitting}
  />;

};

export default memo(Container);
