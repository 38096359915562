import React from 'react';
import { Button, Card, CardActions, CardContent, IconButton, InputAdornment, LinearProgress, TextField, Typography, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { Block, Info, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import { useI18n, Translate } from '@whizz/react-i18n';
import DefaultLayout from '../../layouts/DefaultLayout';
import Logo from '../../partials/Logo';
import LanguagePicker from './LanguagePicker';
import { Link } from 'react-router-dom';
import { StudentPin } from '../PictureLoginPage/StudentPin';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  disableReveal: { // Disable the password-reveal feature in IE >= 10 and Edge <= 44 (EdgeHTML 18)
    '&::-ms-reveal': {
      display: 'none',
    },
  },
  maintenance: {
    display: 'block',
    margin: `${theme.spacing(6)}px auto`,
    fontSize: '10rem',
    color: theme.palette.grey[300],
  },
  experienceRoot: {
    marginTop: `${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'start',
  },
  experienceCheckbox: {
    paddingTop: 0,
  },
  experienceRadio: {
    marginTop: `${theme.spacing(3)}px`,
  },
  experienceLabel: {
    fontSize: '0.8rem',
  }
}));

const View = ({
  loginVersion = {},
  maintenance = false,
  onPasswordResetClick = (event) => { },
  onShowPasswordClick = (event) => { },
  onSubmit = (event) => { },
  onExperienceChange = null,
  passwordProps = {},
  showPassword = false,
  submittable = false,
  submitting = false,
  usernameProps = {},
  defaultExperience = null,
  experiencePickerVersion = null,
  showPictureLogin = false,
}) => {

  const classes = useStyles();
  const { translate } = useI18n();

  return (
    <DefaultLayout>
      <div className={'space-y-4'}>
        <Card component="form" onSubmit={onSubmit}>

          <CardContent>

            <Logo />

            <>
              {loginVersion ? (
                <Alert icon={<Info fontSize="inherit" />} severity="warning">
                  <Typography variant="caption" component="div">{translate('LoginPage.WHIZZ_APP_LOGIN')}</Typography>
                </Alert>
              ) : (
                <></>
              )}
            </>

            {maintenance ? (
              <Block className={classes.maintenance} />
            ) : (
              <>
                <TextField
                  {...usernameProps}
                  disabled={usernameProps.disabled || maintenance}
                  label={translate('LoginPage.USERNAME')}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  autoComplete="email username"
                  InputProps={{ inputProps: { autoCapitalize: 'none' } }}
                />
                <TextField
                  {...passwordProps}
                  disabled={passwordProps.disabled || maintenance}
                  type={showPassword ? 'text' : 'password'}
                  label={translate('LoginPage.PASSWORD')}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="current-password"
                  InputProps={{
                    inputProps: { className: classes.disableReveal },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={passwordProps.disabled} onClick={onShowPasswordClick}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" color="secondary" disabled={!submittable || maintenance} className={classes.submit}>{translate('LoginPage.LOGIN')}</Button>
              </>
            )}
            {(!maintenance && !loginVersion && experiencePickerVersion) && (
              experiencePickerVersion === 'checkbox'
                ? (
                  <FormControlLabel classes={{ root: classes.experienceRoot, label: classes.experienceLabel }} control={<Checkbox checked={defaultExperience === 'tx2'} className={classes.experienceCheckbox} />} label={translate('LoginPage.EXPERIENCE_SELECTION_CHECKBOX')} onChange={onExperienceChange}/>
                )
                : (
                  <>
                    <Typography variant="body1" component="div"  className={classes.experienceRadio}>{translate('LoginPage.EXPERIENCE_SELECTION_RADIO')}</Typography>
                    <RadioGroup value={defaultExperience} name="loginVersionSelect" onChange={onExperienceChange} >
                      <FormControlLabel value="tx2" control={<Radio />} label={translate('LoginPage.TX2_LABEL')} />
                      <FormControlLabel value="tx3" control={<Radio />} label={
                        <Translate i18nKey="LoginPage.TX3_LABEL">
                          <strong />
                        </Translate>
                      } />
                    </RadioGroup>
                  </>
                ))}
          </CardContent>

          <CardActions disableSpacing className={classes.actions}>
            {maintenance ? (
              <div />
            ) : (
              <Button onClick={onPasswordResetClick} variant="text" size="small">{translate('LoginPage.PASSWORD_RESET')}</Button>
            )}
            <LanguagePicker />
          </CardActions>

          {submitting && <LinearProgress />}

        </Card>
        {(!maintenance && showPictureLogin) ? (
          <Card>
            <Link to={'/class-login'}>
              <div className={'flex flex-col items-center p-4 space-y-2 hover:bg-gray-100'}>
                <h2 className={'text-lg font-bold'}>{translate('PictureLoginPage.TITLE')}</h2>
                <StudentPin pin={'ADI'} />
              </div>
            </Link>
          </Card>)
        : null}
      </div>
    </DefaultLayout>
  );

};

export default View;
