import { createContext } from 'react';

const context = createContext({
  clear: () => {},
  error: (message, autoHide = true, action = null, onActionClick = () => {}) => {},
  flash: null,
  info: (message, autoHide = true, action = null, onActionClick = () => {}) => {},
  success: (message, autoHide = true, action = null, onActionClick = () => {}) => {},
  warning: (message, autoHide = true, action = null, onActionClick = () => {}) => {},
});

export default context;
