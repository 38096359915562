import React from 'react';
import context from './context';

const Consumer = context.Consumer;

const withFlash = (Component) => (props) => (
  <Consumer>
    {(value) => <Component {...props} {...value} />}
  </Consumer>
);

export default withFlash;
