import React from 'react';
import View from './View';

const Container = () => {
  return (
    <View />
  )
}

export default Container;
