import { Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Translate, useI18n } from '@whizz/react-i18n';
import classNames from 'classnames';
import React from 'react';
import Version from '../../partials/Version';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  rootWithBrowserUnsupported: {
    marginTop: theme.spacing(12),
  },
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    '&>div': {
      padding: theme.spacing(1),
    },
  },
  unsupportedBrowser: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  maintenance: {
    backgroundColor: theme.custom.palette.orange.main,
    color: theme.custom.palette.orange.contrastText,
  },
  critical: {
    backgroundColor: theme.custom.palette.orange.main,
    color: theme.custom.palette.orange.contrastText,
  },
}));

const View = ({
  browserUnsupported = false,
  children = [],
  maintenance = false,
  critical = false,
  maxWidth = 'xs',
}) => {

  const classes = useStyles();
  const { translate } = useI18n();
  const showBanner = browserUnsupported || maintenance || critical;
  return (
    <Container maxWidth={maxWidth} className={classNames(classes.root, { [classes.rootWithBrowserUnsupported]: browserUnsupported })}>

      {(showBanner) && (
        <Paper square elevation={4} className={classes.banner}>
          {critical
            ? (
              <div className={classes.critical}>
                <Typography variant="body1" color="inherit" align="center">{translate('DefaultLayout.CRITICAL_TITLE')}</Typography>
                <Typography variant="body2" color="inherit" align="center">{translate('DefaultLayout.CRITICAL_MESSAGE')}</Typography>
              </div>
            )
          : maintenance ? (
            <div className={classes.maintenance}>
              <Typography variant="body1" color="inherit" align="center">{translate('DefaultLayout.MAINTENANCE_TITLE')}</Typography>
              <Typography variant="body2" color="inherit" align="center">
                <Translate i18nKey='DefaultLayout.MAINTENANCE_MESSAGE'>
                  <a href={`mailto:${translate('PasswordResetPage.SUPPORT_EMAIL')}`} target='_blank' />
                </Translate>
              </Typography>
            </div>
            )
          : (
            <div className={classes.unsupportedBrowser}>
              <Typography variant="body1" color="inherit" align="center">{translate('DefaultLayout.UNSUPPORTED_BROWSER_TITLE')}</Typography>
              <Typography variant="body2" color="inherit" align="center">{translate('DefaultLayout.UNSUPPORTED_BROWSER_MESSAGE')}</Typography>
            </div>
          )}
        </Paper>
      )}

      {children}

      <Version />

    </Container>
  );

};

export default View;
