import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { memo } from 'react';
import { useFlash } from '../contexts/flash';

const DefaultAction = (props) => (
  <IconButton color="inherit" size="small" {...props}>
    <Close />
  </IconButton>
);

const Flash = () => {

  const { clear, flash } = useFlash();

  const handleActionClick = () => {
    flash.onActionClick();
    clear();
  };

  const handleClose = () => {
    clear();
  };

  const Action = (flash && flash.action) ? flash.action : DefaultAction;

  return flash && (
    <Snackbar
      autoHideDuration={flash.autoHide ? 3000 : null}
      onClose={handleClose}
      open={true}
    >
      <Alert
        action={<Action onClick={handleActionClick} />}
        onClose={handleClose}
        severity={flash.level}
      >
        {flash.message}
      </Alert>
    </Snackbar>
  );

};

export default memo(Flash);
