import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Container from './Container';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const PictureLoginPage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container />
    </QueryClientProvider>
  );
};

export default PictureLoginPage;

