import { Button } from '@material-ui/core';
import { model } from '@whizz/api-client';
import { useI18n } from '@whizz/react-i18n';
import React, { memo, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlash } from '../../contexts/flash';
import useForm from '../../hooks/useForm';
import useTitle from '../../hooks/useTitle';
import queryString from 'query-string';
import View from './View';

const submitFn = (username, token, password) => model.User.passwordResetConfirm(username, token, password);

const submittableFn = (inputs, dirty) => inputs.password && model.User.validatePassword(inputs.password);

const Container = ({
  loginVersion = null
}) => {

  const history = useHistory();
  const location = useLocation();
  const { username, token } = queryString.parse(location.search);

  const { error, success } = useFlash();
  const { translate } = useI18n();

  const defaults = useMemo(() => ({
    password: null,
    show_password: false,
    username: null,
  }), []);

  const {
    fatal,
    input,
    inputs,
    rejection,
    submit,
    submittable,
    submitted,
    submitting,
    textFieldProps,
  } = useForm(defaults, submitFn, submittableFn);

  useTitle(translate('PasswordResetConfirmPage.TITLE'));

  useEffect(() => {

    if (!submitted) {
      return;
    }

    if (rejection) {
      fatal();
      if (rejection.response && 400 === rejection.response.status) {
        error(translate('PasswordResetConfirmPage.SAVE_ERROR_INVALID'), false, (props) => (
          <Button color="inherit" size="small" {...props}>
            {translate('PasswordResetConfirmPage.SAVE_ERROR_INVALID_ACTION')}
          </Button>
        ), () => { 
          const root = loginVersion ? `/${loginVersion}/` : '/';
          history.push(`${root}password-reset`); 
        });
      } else {
        error(translate('PasswordResetConfirmPage.SAVE_ERROR'));
      }
    } else {
      success(translate('PasswordResetConfirmPage.SAVE_SUCCESS'), false);
      const root = loginVersion ? `/${loginVersion}/` : '/';
      history.push(`${root}?username=${encodeURIComponent(username)}`);
    }

  }, [ error, fatal, history, loginVersion, rejection, submitted, success, translate, username ]);

  const passwordValid = useMemo(() => model.User.validatePassword(inputs.password), [ inputs.password ]);

  const handleShowPasswordClick = (event) => {
    input('show_password', !inputs.show_password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submit(username, token, inputs.password);
  };

  const handleCancel = (event) => {
    const root = loginVersion ? `/${loginVersion}/` : '/';
    history.push(`${root}?username=${encodeURIComponent(username)}`);
  };

  if (!username || !token) {
    const root = loginVersion ? `/${loginVersion}/` : '/';
    history.push(`${root}password-reset`);
  }

  return <View
    onCancel={handleCancel}
    onShowPasswordClick={handleShowPasswordClick}
    onSubmit={handleSubmit}
    passwordProps={textFieldProps('password')}
    passwordValid={passwordValid}
    showPassword={inputs.show_password}
    submittable={submittable}
    submitting={submitting}
    username={username}
  />;

};

export default memo(Container);
