import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useI18n } from '@whizz/react-i18n';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: theme.spacing(1),
  },
}));

const Version = () => {
  const classes = useStyles();
  const { translate } = useI18n();
  return <Typography variant="caption" color="textSecondary" className={classes.root}>{translate('Version.VERSION', { version: process.env.REACT_APP_VERSION })}</Typography>;
};

export default memo(Version);
