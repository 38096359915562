import { Button, Card, CardActions, CardContent, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { model } from '@whizz/api-client';
import { useI18n } from '@whizz/react-i18n';
import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import Logo from '../../partials/Logo';
import PasswordStrengthIndicator from '../../partials/PasswordStrengthIndicator';

const useStyles = makeStyles((theme) => ({
  disableReveal: { // Disable the password-reveal feature in IE >= 10 and Edge <= 44 (EdgeHTML 18)
    '&::-ms-reveal': {
      display: 'none',
    },
  },
}));

const View = ({
  onCancel = (event) => {},
  onShowPasswordClick = (event) => {},
  onSubmit = (event) => {},
  passwordProps = {},
  passwordValid = false,
  showPassword = false,
  submittable = false,
  submitting = false,
  username = null,
}) => {

  const classes = useStyles();
  const { translate } = useI18n();

  return (
    <DefaultLayout>
      <Card component="form" onSubmit={onSubmit}>

        <CardContent>

          <Logo />

          <Typography gutterBottom>{translate('PasswordResetConfirmPage.MESSAGE')}</Typography>

          {/* See https://goo.gl/9p2vKq and https://stackoverflow.com/questions/48525114/chrome-warning-dom-password-forms-should-have-optionally-hidden-username-fi#48736294 */}
          <input type="text" value={username} readOnly autoComplete="email username" style={{ display: 'none' }}/>

          <TextField
            {...passwordProps}
            type={showPassword ? 'text' : 'password'}
            label={translate('PasswordResetConfirmPage.PASSWORD')}
            helperText={!passwordValid && translate('PasswordResetConfirmPage.PASSWORD_HINT', { count: model.User.PASSWORD_MIN_LENGTH })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            autoComplete="new-password"
            InputProps={{
              inputProps: {
                className: classes.disableReveal,
                pattern: model.User.PASSWORD_REGEXP.source,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={passwordProps.disabled} onClick={onShowPasswordClick}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {passwordValid && <PasswordStrengthIndicator password={passwordProps.value} />}

        </CardContent>

        <CardActions>
          <Button type="submit" color="primary" disabled={!submittable}>{translate('PasswordResetConfirmPage.SAVE')}</Button>
          <Button onClick={onCancel} color="primary" disabled={submitting}>{translate('PasswordResetConfirmPage.CANCEL')}</Button>
        </CardActions>

        {submitting && <LinearProgress />}

      </Card>
    </DefaultLayout>
  );

};

export default View;
