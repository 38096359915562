import { model } from '@whizz/api-client';
import { useEffect } from 'react';
import { getLegacyCookie, getTokenCookie, hostnameToDomain, setTokenCookie } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const isEmbedded = window !== window.parent;
const ssoFlag = queryString.parse(window.location.search).ssoLogin;

let referrer;
try {
  referrer = new URL(window.document.referrer);
} catch (err) { }

const isSameDomain = referrer && hostnameToDomain(window.location.hostname) === hostnameToDomain(referrer.hostname);

const identified = (identity) => {

  if (isSameDomain && identity) {
    // Flatten the instance to JSON before sending; prevents nested object cloning issues
    identity = JSON.stringify(identity);
  }

  if (isEmbedded) {
    window.parent.postMessage({ identity }, `${referrer.protocol}//${referrer.host}`);
  }

};

const Container = () => {

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {

    const token = getTokenCookie();
    const authtoken = getLegacyCookie();
    
    if (!isEmbedded && !ssoFlag) {
      console.debug('IDENT: Not embedded');
      identified(null);
      return;
    }
    
    if (!isSameDomain && !ssoFlag) {
      console.debug('IDENT: Invalid domain');
      identified(null);
      return;
    }
    
    if (!token && !authtoken) {
      console.debug('IDENT: No token');
      identified(null);
      return;
    }
    
    let identity;

    if (token) {
      identity = new model.Identity({ token });
    
      if (!identity.user) {
        identity.destroy()
          .catch((err) => {})
          .finally(() => {
            identified(null)
          });

      } else {
        identified(identity);
      }

    } else {
      // User authenticated via a legacy auth system; convert the session cookie to a token cookie

      identity = model.Identity.login({ authtoken })
        .then((identity) => {
          setTokenCookie(identity.token);
          identified(identity);
        })
        .catch((err) => {
          console.debug('IDENT: Token upgrade failure');
          identified(null);
        });
    }

    if (identity && ssoFlag) {
      const currentQuery = queryString.parse(window.location.search);
      const redirectUrl = currentQuery.redirect;
      const redirectQuery = queryString.parseUrl(redirectUrl);
      const mergedQuery = { ...redirectQuery.query, ...(currentQuery.locale && { locale: currentQuery.locale }) };
      const updatedRedirectUrl = `${redirectQuery.url}?${queryString.stringify(mergedQuery)}`;
      window.location.replace(updatedRedirectUrl);
    }

  }, [history, location]);

  return null;

};

export default Container;
