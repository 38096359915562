import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import usePromise from './usePromise';

const toString = (value) => {
  switch (typeof value) {
    case 'boolean':
      return value ? '1' : '0';
    case 'number':
      return value + '';
    case 'string':
      return value;
    default:
      if (null != value && 'production' !== process.env.NODE_ENV) {
        console.error('Input value should be null-like or a boolean, number or string', value);
      }
      return '';
  }
};

const init = (defaults) => ({
  dirty: false,
  errors: {},
  inputs: defaults,
});

const reducer = (state, action) => {
  switch (action.type) {

    case 'clean':
      return {
        ...state,
        dirty: false,
      };

    case 'error':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };

    case 'fatal':
      const errors = {};
      Object.keys(state.inputs).forEach((name) => errors[name] = true);
      return {
        ...state,
        errors,
      };

    case 'input':
      return {
        ...state,
        dirty: true,
        inputs: {
          ...state.inputs,
          ...action.payload,
        },
      };

    case 'reset':
      return init(action.payload);

    case 'resetErrors':
      return {
        ...state,
        errors: {},
      };

    default:
      if ('production' !== process.env.NODE_ENV) {
        console.error('Invalid action provided to useForm()', action);
      }
      return state;

  }
};

const useForm = (defaults, submitFn, submittableFn = (inputs, dirty) => true, submittableWhenClean = false) => {

  const [ state, dispatch ] = useReducer(reducer, defaults, init);
  const unmounted = useRef(false);

  const {
    rejection,
    reset,
    resolution,
    load: submit,
    loaded: submitted,
    loading: submitting,
    reload: resubmit,
  } = usePromise(submitFn);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (submitted && !unmounted.current) {
      dispatch({ type: 'clean' });
    }
  }, [ submitted ]);

  const submittable = useMemo(() => !submitting && !!submittableFn(state.inputs, state.dirty), [ state.dirty, state.inputs, submittableFn, submitting ]);

  return {
    submittable,
    submitted,
    submitting,
    rejection,
    resolution,
    checkboxProps: useCallback((name) => ({
      name,
      checked: !!state.inputs[name],
      disabled: submitting,
      onChange: (event) => {
        dispatch({ type: 'input', payload: { [event.target.name]: event.target.checked } });
      },
    }), [ state.inputs, submitting ]),
    error: useCallback((nameOrPairs, value = true) => {
      dispatch({ type: 'error', payload: 'object' === typeof nameOrPairs ? nameOrPairs : { [nameOrPairs]: value } });
    }, []),
    errors: state.errors,
    fatal: useCallback(() => {
      dispatch({ type: 'fatal' });
    }, []),
    input: useCallback((nameOrPairs, value) => {
      dispatch({ type: 'input', payload: 'object' === typeof nameOrPairs ? nameOrPairs : { [nameOrPairs]: value } });
    }, []),
    inputs: state.inputs,
    reset: useCallback(() => {
      reset();
      dispatch({ type: 'reset', payload: defaults });
    }, [ defaults, reset ]),
    radioGroupProps: useCallback((name) => ({
      name,
      onChange: (event) => {
        dispatch({ type: 'input', payload: { [event.target.name]: event.target.value } });
      },
      value: toString(state.inputs[name]),
    }), [ state.inputs ]),
    retry: useCallback(() => {
      dispatch({ type: 'resetErrors' });
      return resubmit();
    }, [ resubmit ]),
    submit: useCallback((...args) => {
      dispatch({ type: 'resetErrors' });
      return submit(...args);
    }, [ submit ]),
    textFieldProps: useCallback((name) => ({
      name,
      disabled: submitting,
      error: !!state.errors[name],
      helperText: 'string' === typeof state.errors[name] ? state.errors[name] : undefined,
      onChange: (event) => {
        dispatch({ type: 'input', payload: { [event.target.name]: event.target.value } });
      },
      value: toString(state.inputs[name]),
    }), [ state.errors, state.inputs, submitting ]),
  };

};

export default useForm;
