import { client, model } from '@whizz/api-client';
import { useI18n } from '@whizz/react-i18n';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFlash } from '../../contexts/flash';
import useForm from '../../hooks/useForm';
import usePromise from '../../hooks/usePromise';
import useTitle from '../../hooks/useTitle';
import { MAINTENANCE } from '../../layouts/DefaultLayout/Container';
import { getTokenCookie, handleLogin, removeTokenCookie } from '../../utils';
import View from './View';

const maintenanceFn = () => MAINTENANCE ? model.Identity.login({}).catch((err) => (err && err.response && 503 === err.response.status)) : Promise.resolve(false);

const submitFn = (credentials) => {
  delete client.http().defaults.headers.common.Authorization;
  return model.Identity.login(credentials);
};

const errorMessage = (code, translate) => {
  switch (code) {
    case 'expired':
      return translate('LoginPage.ERROR_EXPIRED');
    case 'invalid':
      return translate('LoginPage.ERROR_INVALID');
    case 'noaccess':
      return translate('LoginPage.ERROR_NO_ACCESS');
    case 'pwdexpired':
      return translate('LoginPage.ERROR_EXPIRED_PASSWORD');
    case 'unknown':
    default:
      return translate('LoginPage.ERROR_UNKNOWN');
  }
};

const Container = ({
  loginVersion = null
}) => {

  const history = useHistory();
  const location = useLocation();
  const { locale, translate } = useI18n();
  const { error } = useFlash();
  const { referrer, error: errorCode, username: initialUsername } = useMemo(() => queryString.parse(location.search), [ location.search ]);
  const experiencePickerVersion = [ 'en-gb', 'en-ae', 'en-nz', 'en-us', 'th-th' ].includes(locale.id) ? 'checkbox' : null;
  const [ defaultExperience, setDefaultExperience ] = useState('tx2');
  const referrerIsTx2 = referrer?.includes('my.whizz');

  const {
    load: loadMaintenance,
    resolution: maintenance,
  } = usePromise(maintenanceFn, false);

  const defaults = useMemo(() => ({
    password: null,
    username: initialUsername || null,
    showPassword: false,
  }), [ initialUsername ]);

  const {
    fatal,
    input,
    inputs,
    rejection,
    resolution,
    submit,
    submittable,
    submitting,
    textFieldProps,
  } = useForm(defaults, submitFn);

  useTitle(translate('LoginPage.TITLE'));

  useEffect(() => {

    // As the apps themselves cannot delete the token cookie as they are not same-domain, we need to
    // ignore any previously-set cookie and force re-authentication
    // TODO: Check if the token is still valid at the API and redirect back to dashboard if it is
    const token = getTokenCookie();
    if (token) {
      client.http().defaults.headers.common.Authorization = `Bearer ${token}`;
      new model.Identity({ token }).destroy()
        .catch((err) => {})
        .finally(() => {
          delete client.http().defaults.headers.common.Authorization;
          removeTokenCookie();
        });
    }

    // When embedded in an <iframe> (such as on https://www.whizz.com/login/) we'll make some page
    // information available to the parent window
    if (window !== window.top) {
      window.top.postMessage({ locale: locale.id, title: window.document.title }, '*');
    }

  }, [ locale ]);

  useEffect(() => {
    loadMaintenance();
  }, [ loadMaintenance ]);

  useEffect(() => {
    if (errorCode) {
      error(errorMessage(errorCode, translate));
      fatal();
    }
  }, [ error, fatal, errorCode, locale, translate ]);

  useEffect(() => {

    if (rejection) {

      if (rejection.response && 400 === rejection.response.status) {
        error(errorMessage('invalid', translate));
      } else {
        error(errorMessage('unknown', translate));
      }

      fatal();

    }

  }, [ error, fatal, rejection, translate ]);

  useEffect(() => {
    if (resolution) {
      const appVersion = {
        'tx3': 'whizzApp',
      };
      handleLogin(resolution, locale, appVersion[defaultExperience], referrerIsTx2 ? null : referrer);
    }
  }, [ locale, defaultExperience, referrer, resolution ]);

  useEffect(() => {
    setDefaultExperience(referrerIsTx2 ? 'tx2' : experiencePickerVersion === 'checkbox' ? 'tx3' : 'tx2');
  }, [ locale, experiencePickerVersion ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    input('show', false);
    submit({ username: inputs.username, password: inputs.password });
  };

  const handleShowPasswordClick = (event) => {
    input('show', !inputs.show);
  };

  const handlePasswordResetClick = (event) => {
    const passwordResetPath = 'password-reset';
    history.push(`/${passwordResetPath}`);
  };

  const handleExperienceChange = (event, value) => {
    setDefaultExperience(typeof value === 'boolean' ? value ? 'tx2' : 'tx3' : value);
  };

  if (resolution) {
    return null;
  }

  const showPictureLogin = !!experiencePickerVersion || [ 'en-ke', 'sw-ke' ].includes(locale.id);

  return <View
    loginVersion={loginVersion}
    maintenance={maintenance}
    onPasswordResetClick={handlePasswordResetClick}
    onShowPasswordClick={handleShowPasswordClick}
    onSubmit={handleSubmit}
    onExperienceChange={handleExperienceChange}
    passwordProps={textFieldProps('password')}
    showPassword={inputs.show}
    submittable={submittable}
    submitting={submitting}
    usernameProps={textFieldProps('username')}
    defaultExperience={defaultExperience}
    experiencePickerVersion={experiencePickerVersion}
    showPictureLogin={showPictureLogin}
  />;

};

export default memo(Container);
