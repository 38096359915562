import { makeStyles } from '@material-ui/styles';
import { MathsWhizzLogo } from '@whizz/react-branding';
import { useI18n } from '@whizz/react-i18n';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    height: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
}));

const Logo = () => {

  const classes = useStyles();
  const { locale } = useI18n();

  return <MathsWhizzLogo locale={locale.id} className={classes.root} />;

};

export default memo(Logo);
