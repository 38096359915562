import { useI18n } from '@whizz/react-i18n';
import { useEffect, useMemo } from 'react';

const useTitle = (...args) => {

  const { translate } = useI18n();
  const appName = useMemo(() => translate('useTitle.APP_NAME'), [ translate ]);

  useEffect(() => {

    const metaAppleMobileWebAppTitle = document.querySelector('meta[name=apple-mobile-web-app-title]');
    const metaApplicationName = document.querySelector('meta[name=application-name]');

    let initialMetaAppleMobileWebAppTitle;
    let initialMetaApplicationName;

    if (metaAppleMobileWebAppTitle) {
      initialMetaAppleMobileWebAppTitle = metaAppleMobileWebAppTitle.content;
      metaAppleMobileWebAppTitle.content = appName;
    }

    if (metaApplicationName) {
      initialMetaApplicationName = metaApplicationName.content;
      metaApplicationName.content = appName;
    }

    return () => {

      if (metaAppleMobileWebAppTitle) {
        metaAppleMobileWebAppTitle.content = initialMetaAppleMobileWebAppTitle;
      }

      if (metaApplicationName) {
        metaApplicationName.content = initialMetaApplicationName;
      }

    };

  }, [ appName ]);

  useEffect(() => {

    const initialTitle = window.document.title;

    window.document.title = `${args.join(' ⧸ ')} | ${appName}`;

    return () => {
      window.document.title = initialTitle;
    };

  }, [ appName, args ]);

};

export default useTitle;
