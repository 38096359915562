import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { muiTheme } from '@whizz/react-branding';
import { I18nProvider } from '@whizz/react-i18n';
import * as luxon from 'luxon';
import queryString from 'query-string';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { FlashProvider } from '../contexts/flash';
import translations from '../translations';
import ErrorBoundaryWrapper from './ErrorBoundaryWrapper';
import Flash from './Flash';
import Loading from './Loading';
import LogApiErrors from './LogApiErrors';
import Routes from './Routes';

export const STORAGE_NAME_LOCALE = 'locale';

const locale = queryString.parse(window.location.search).locale || window.sessionStorage.getItem(STORAGE_NAME_LOCALE) || undefined;
if (locale) {
  window.sessionStorage.setItem(STORAGE_NAME_LOCALE, locale);
}

const App = () => (
  <MuiThemeProvider theme={createTheme(muiTheme)}>
    <CssBaseline />
    <I18nProvider locale={locale} loadingComponent={<Loading />} luxon={luxon} translations={translations}>
      <ErrorBoundaryWrapper>
        <FlashProvider>
          <LogApiErrors>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </LogApiErrors>
          <Flash />
        </FlashProvider>
      </ErrorBoundaryWrapper>
    </I18nProvider>
  </MuiThemeProvider>
);

export default App;
