import { useCallback, useReducer, useEffect } from 'react';

const reseed = Math.random;

const init = (initialResolution) => ({
  loaded: undefined !== initialResolution,
  loading: false,
  promiseFnArgs: [],
  rejection: undefined,
  resolution: initialResolution,
  seed: undefined,
});

const reducer = (state, action) => {
  switch (action.type) {

    case 'load':
      return {
        ...state,
        promiseFnArgs: action.payload,
        seed: reseed(),
      };

    case 'loading':
      return {
        ...state,
        loaded: false,
        loading: true,
        rejection: undefined,
        resolution: undefined,
      };

    case 'reload':
      return {
        ...state,
        seed: reseed(),
      };

    case 'reject':
      return {
        ...state,
        loaded: true,
        loading: false,
        rejection: action.payload,
      };

    case 'resolve':
      return {
        ...state,
        loaded: true,
        loading: false,
        resolution: action.payload,
      };

    case 'reset':
      return init(action.payload);

    default:
      if ('production' !== process.env.NODE_ENV) {
        console.error('Invalid action provided to usePromiseLater()', action);
      }
      return state;

  }
};

const usePromise = (promiseFn, initialResolution) => {

  const [ state, dispatch ] = useReducer(reducer, initialResolution, init);

  useEffect(() => {

    if (undefined === state.seed) {
      return;
    }

    dispatch({ type: 'loading' });

    let unmounted = false;

    promiseFn(...state.promiseFnArgs)
      .then((resolution) => {
        if (unmounted) return;
        dispatch({ type: 'resolve', payload: resolution });
      })
      .catch((rejection) => {
        if (unmounted) return;
        dispatch({ type: 'reject', payload: rejection });
      });

    return () => {
      unmounted = true;
    };

  }, [ promiseFn, state.promiseFnArgs, state.seed ]);

  return {
    load: useCallback((...promiseFnArgs) => {
      dispatch({ type: 'load', payload: promiseFnArgs });
    }, []),
    reload: useCallback(() => {
      dispatch({ type: 'reload' });
    }, []),
    reset: useCallback(() => {
      dispatch({ type: 'reset', payload: initialResolution });
    }, [ initialResolution ]),
    loaded: state.loaded,
    loading: state.loading,
    rejection: state.rejection,
    resolution: state.resolution,
  };

};

export default usePromise;
