import { LinearProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useI18n } from '@whizz/react-i18n';
import React, { memo, useEffect, useState } from 'react';

const PasswordStrengthIndicatorWeak = withStyles((theme) => ({
  colorPrimary: {
    background: theme.custom.palette.red.light,
  },
  barColorPrimary: {
    background: theme.custom.palette.red.main,
  },
})) (LinearProgress);

const PasswordStrengthIndicatorAverage = withStyles((theme) => ({
  colorPrimary: {
    background: theme.custom.palette.orange.light,
  },
  barColorPrimary: {
    background: theme.custom.palette.orange.main,
  },
})) (LinearProgress);

const PasswordStrengthIndicatorStrong = withStyles((theme) => ({
  colorPrimary: {
    background: theme.custom.palette.green.light,
  },
  barColorPrimary: {
    background: theme.custom.palette.green.main,
  },
})) (LinearProgress);

const PasswordStrengthIndicator = ({ password }) => {

  const { translate } = useI18n();
  const [ strength, setStrength ] = useState(0);

  useEffect(() => {
    import('zxcvbn').then((zxcvbn) => {
      setStrength((zxcvbn.default(password).score + 1) / 5 * 100);
    });
  }, [ password ]);

  let Component;
  let label;

  if (strength <= 40) {
    Component = PasswordStrengthIndicatorWeak;
    label = translate('PasswordStrengthIndicator.WEAK');
  } else if (strength <= 80) {
    Component = PasswordStrengthIndicatorAverage;
    label = translate('PasswordStrengthIndicator.AVERAGE');
  } else {
    Component = PasswordStrengthIndicatorStrong;
    label = translate('PasswordStrengthIndicator.STRONG');
  }

  return (
    <>
      <Component variant="determinate" value={strength} />
      <Typography variant="caption" color="textSecondary">{label}</Typography>
    </>
  );

};

export default memo(PasswordStrengthIndicator);
