import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useI18n } from '@whizz/react-i18n';

const View = () => {
  const { translate } = useI18n();
  return (
    <DefaultLayout maxWidth={'md'}>
      <Card>
        <CardContent>
          <Typography align={'center'} variant={'h5'}>🤔 {translate('NoAccessPage.MESSAGE')}</Typography>
        </CardContent>
      </Card>
    </DefaultLayout>
  )
}

export default View;
