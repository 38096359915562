import * as React from 'react';
//'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'Y' | 'X' | 'Z'
export type Code = {
  value: string;
  alt: string;
};

const pinIcons = [
  {
    value: 'A',
    alt: 'apple',
  },
  {
    value: 'B',
    alt: 'banana',
  }, {
    value: 'C',
    alt: 'car',
  },
  {
    value: 'D',
    alt: 'duck',
  },
  {
    value: 'E',
    alt: 'egg',
  },
  {
    value: 'F',
    alt: 'flower',
  },
  {
    value: 'G',
    alt: 'guitar',
  },
  {
    value: 'H',
    alt: 'hat',
  },
  {
    value: 'I',
    alt: 'igloo',
  },
  {
    value: 'J',
    alt: 'jug',
  },
  {
    value: 'K',
    alt: 'kite',
  },
  {
    value: 'L',
    alt: 'lion',
  },
  {
    value: 'M',
    alt: 'moon',
  },
  {
    value: 'N',
    alt: 'nail',
  },
  {
    value: 'O',
    alt: 'orange',
  }, {
    value: 'P',
    alt: 'panda',
  },
  {
    value: 'R',
    alt: 'rainbow',
  },
  {
    value: 'S',
    alt: 'star',
  }, {
    value: 'T',
    alt: 'tree',
  },
  {
    value: 'U',
    alt: 'umbrella',
  },
  {
    value: 'V',
    alt: 'volcano',
  },
  {
    value: 'W',
    alt: 'whale',
  },
  {
    value: 'X',
    alt: 'xylophone',
  },
  {
    value: 'Y',
    alt: 'yacht',
  },
  {
    value: 'Z',
    alt: 'zebra',
  },
];

const PinIcon = ( { letter }: { letter: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 98 98"
      enableBackground="new 0 0 98 98"
      xmlSpace="preserve"
    >
      <symbol id="Guitar_String_Adjuster" viewBox="-4.5 -2.7 11.1 5.3">
        <polygon
          display="none"
          stroke="#000000"
          strokeWidth={2}
          strokeMiterlimit={10}
          points="5.7,-0.6 -1.7,-0.6 -1.7,0.5 5.7,0.5  "
        />
        <polygon
          display="none"
          stroke="#000000"
          strokeWidth={2}
          strokeMiterlimit={10}
          points="-3.5,-1.7 -3.5,1.7 -1.5,1.7 -1.5,-1.7  "
        />
        <polygon points="-2.7,-1.6 -2.7,1.5 6.7,1.5 6.7,-1.6  " />
        <polygon points="-4.5,-2.7 -4.5,2.7 -0.5,2.7 -0.5,-2.7  " />
      </symbol>
      <symbol id="Orange_Slice" viewBox="-14.9 -7.1 24.7 16.9">
        <path
          fill="#FF9900"
          stroke="#FA6100"
          strokeMiterlimit={10}
          d="M8.6,9.3c0,0-22,0-22.4,0s-0.6-0.4-0.6-0.6 c0.1-6.3,2.2-10.5,5.7-15.1c0.1-0.2,0.7-0.3,1.1,0L8.8,7.8C9.6,8.4,9.3,9.3,8.6,9.3z"
        />
      </symbol>
      <symbol id="Panda_Eye" viewBox="-11.3 -10.6 21.7 21.2">
        <path
          display="none"
          d="M10.3-1.5c0,5-4.1,9.1-9.1,9.1c-5,0-9.1-4.1-9.1-9.1c0-5,4.1-9.1,9.1-9.1C6.3-10.6,10.3-6.5,10.3-1.5z"
        />
        <path
          display="none"
          d="M6.5,2.2c0,4.7-3.8,8.4-8.4,8.4c-4.7,0-8.4-3.8-8.4-8.4c0-4.7,3.8-8.4,8.4-8.4C2.7-6.3,6.5-2.5,6.5,2.2z"
        />
        <path
          display="none"
          fill="#FFFFFF"
          d="M0.9,3c0-2.1-1.7-3.7-3.7-3.7S-6.6,0.9-6.6,3c0,2.1,1.7,3.7,3.7,3.7S0.9,5.1,0.9,3z"
        />
        <path d="M5.2,6.7C3.7,9,1.1,10.6-1.9,10.6c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-5.7,0-9.3-4.1-8.8-9.8c0.6-6.5,7.5-11.4,11.4-11.4 c0,0,0.5,0,0.7,0c5,0,9.1,4.1,9.1,9.1C10.3,2.1,8.2,5.2,5.2,6.7z" />
        <path
          display="none"
          d="M9.3,0.3c0,3.1-4,3.1-6.2,4.9C1.3,6.8,0.6,10.6-2,10.6c-5.7,0-9.3-4.6-9.3-10.3c0-5.7,6.2-10.9,11.9-10.9 C6.3-10.6,9.3-5.4,9.3,0.3z"
        />
        <path
          fill="#FAF4E6"
          d="M0.1,2c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,1.7,1.3,3,3,3S0.1,3.6,0.1,2z"
        />
      </symbol>
      { letter === 'A' ? (
        <g id="Icon-A">
          <path
            display="inline"
            fill="#EA4260"
            d="M60.6,26.7c5,0,20,3.9,21.5,20c1.8,19.9-8.6,30.1-8.6,30.1s-10.5,14-23.8,8.8 c-0.7-0.3-1.3-0.5-2-0.9c-0.9,0.4-1.8,0.6-2.7,0.9c-14.5,3.8-23.3-9.3-23.3-9.3s-9.3-9.3-7.9-29.1c1.1-16.1,15.6-20.5,21-20.5 c0.4,0,0.7,0,1,0c3.2,0.2,5.7,1.2,8.9,2.2l6.4-0.5c3.2-0.7,5.1-1.4,8.6-1.6C60,26.7,60.3,26.7,60.6,26.7z"
          />
          <path
            display="inline"
            fill="#BD1622"
            d="M82.1,46.7c-0.6-6.7-5.1-12.8-8.3-15.5c0,0,1.9,25.6-12.2,39.1 c-14.2,13.5-38.1,8.3-38.1,8.3c2.8,3.3,11.2,9.6,21.6,7c0.9-0.2,1.8-0.5,2.7-0.9c0.7,0.4,1.3,0.6,2,0.9c13.2,5.2,23.8-8.8,23.8-8.8 S83.9,66.6,82.1,46.7z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M60.6,26.7c5,0,20,3.9,21.5,20c1.8,19.9-8.6,30.1-8.6,30.1s-10.5,14-23.8,8.8c-0.7-0.3-1.2-0.4-2-0.4c-0.7,0-1.8,0.2-2.7,0.4 c-14.5,3.8-23.3-9.3-23.3-9.3s-9.3-9.3-7.9-29.1c1.1-16.1,15.6-20.5,21-20.5c0.4,0,0.7,0,1,0c3.2,0.2,5.7,1.2,8.9,2.2l6.4-0.5 c3.2-0.7,5.1-1.4,8.6-1.6C60,26.7,60.3,26.7,60.6,26.7z"
          />
          <path
            display="inline"
            fill="#36C236"
            d="M62,13.2c9.4-4.3,17.2-3.2,19.4-2c0,0-2.6,7.2-10,14.1c-7.4,7-24,12.2-24,12.2 C48.2,32,52.6,17.5,62,13.2z"
          />
          <path
            display="inline"
            fill="#22941F"
            d="M62,13.2c9.4-4.3,17.2-3.2,19.4-2c0,0-10.4,4.3-17.9,11.3c-7.4,7-16.1,15.1-16.1,15.1 C48.2,32,52.6,17.5,62,13.2z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M62,13.2c9.4-4.3,17.2-3.2,19.4-2 c0,0-2.6,7.2-10,14.1c-7.4,7-24,12.2-24,12.2C48.2,32,52.6,17.5,62,13.2z"
          />
          <path
            display="inline"
            fill="#B17F49"
            d="M45,38c0-0.1,0.2-5.3-3.3-10.3c-1.5-2.2-5.4-6-8.2-7.7c-1.7-1-2.2-3.1-1.3-4.8 c1-1.7,3.1-2.2,4.8-1.3c0.9,0.5,8.1,6,11.3,11.4c3.3,5.5,3.5,12.1,3.5,12.4"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M45,38 c0-0.1,0.2-5.3-3.3-10.3c-1.5-2.2-5.4-6-8.2-7.7c-1.7-1-2.2-3.1-1.3-4.8c1-1.7,3.1-2.2,4.8-1.3c0.9,0.5,8.1,6,11.3,11.4 c3.3,5.5,3.5,12.1,3.5,12.4"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d=" M39.4,34.2c0,0,1.6,4.8,8.3,4.8c3.4,0,5.3-1.2,6.3-2.4"
          />
        </g> ) : null }
      { letter === 'B' ? (
        <g id="Icon-B">
          <g display="inline">
            <g>
              <path
                fill="#DDAE0C"
                d="M72.7,17.2c-3.2-3.1-4.5-8.6-4.5-8.6l-3.2,1l0.4,0.4c0,0,7.8,11.3,5.7,17.4c-1.4,3.8-0.3,11.6-1,16.5 c-2.6,18.3-21.6,26.6-33.7,28.3c-11.5,1.7-19.7,4.3-21.9,5.3c0.2,0.6,0.6,1.3,1.2,2c3.1,4.4,13.6,7.1,18.8,6.5 c7.6-0.9,22.8,0,34.3-11C80,64.4,84.1,50.6,83.9,38.4C83.7,26.2,75.9,20.3,72.7,17.2z"
              />
              <path
                fill="#FDDB60"
                d="M70.1,43.9c0.7-4.8-0.3-12.7,1-16.5c2.2-6-5.7-17.4-5.7-17.4L65,9.7l-4.6,1.5c0,0,7.3,5.3,2.6,12 c-2.3,3.3-3.6,11.4-4.3,16.3c-2.6,18.1-16.4,19.6-26.3,22.3C18.3,65.7,15.3,74,15.3,74s-1.8,0.8-0.8,3.5 c2.2-0.9,9.3-3.6,21.7-5.3C49,70.5,67.5,62.2,70.1,43.9z"
              />
            </g>
            <path
              fill="#84B532"
              d="M70.4,19.9l4.4-0.6c-0.8-0.8-1.5-1.5-2.1-2c-3.2-3.1-4.5-8.6-4.5-8.6l-3.2,1l0.4,0.4 C65.4,10.1,68.7,14.9,70.4,19.9z"
            />
            <path
              fill="#CFE63A"
              d="M65.1,20.6l5.3-0.8c-1.7-5-5-9.8-5-9.8L65,9.7l-4.6,1.2C60.4,10.9,67.3,15,65.1,20.6z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M32.4,61.7c9.9-2.8,24.9-5.3,26.4-23.8 c0.5-6.1,1.4-10.8,4.2-15.1C67.5,16,59.5,11,59.5,11l9.6-3.5c0,0,1.4,6.5,4.6,9.6c3.2,3.1,10.5,9.6,10.7,21.8 C84.6,51.1,80,64.4,68.8,75c-11.6,11-25,11.1-34.3,11c-5.2,0-15.7-2.1-18.8-6.5c-3.1-4.4-0.5-6-0.5-6S18.3,65.7,32.4,61.7z"
            />
          </g>
        </g> ) : null }
      { letter === 'C' ? (
        <g id="Icon-C">
          <path
            display="inline"
            fill="#96E2F8"
            d="M80,45.8c0,4.4-3.6,8-8,8H36.5c-4.4,0-13-3.6-13-8l9.5-17c1.5-2.8,4.5-7.1,9.5-8.5l29.5-4 c4.4,0,8,3.1,8,7.5V45.8z"
          />
          <path
            display="inline"
            fill="#21B9EC"
            d="M80,45.8v-22c0-3.3-2-5.9-4.9-7l-20.6,3.5c-5,1.4-8,5.8-9.5,8.5l-9.5,17c0,4.4,8.6,8,13,8 H72C76.4,53.8,80,50.2,80,45.8z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M80,45.8 c0,4.4-3.6,8-8,8H36.5c-4.4,0-13-3.6-13-8l9.5-17c1.5-2.8,4.5-7.1,9.5-8.5l29.5-4c4.4,0,8,3.1,8,7.5V45.8z"
          />
          <g display="inline">
            <path
              fill="#956DFB"
              d="M73,16.3l-29.5,4c-5,1.4-8,5.8-9.5,8.5l-1.5,2.7c0.2-0.1,0.3-0.1,0.5-0.2l29.5-4c6.6-0.9,7.6-0.1,7.5,4 l-2,22.5h5c4.4,0,8-3.6,8-8v-22C81,19.3,77.4,16.3,73,16.3z"
            />
            <path
              fill="#723CFA"
              d="M52.6,23.9c-3.5,1-6,3.4-7.7,5.7l17.6-2.3c6.6-0.9,7.6-0.1,7.5,4l-2,22.5h5c4.4,0,8-3.6,8-8v-22 c0-0.5-0.1-1-0.2-1.5c-1.3-0.9-3-1.5-4.8-1.5L52.6,23.9z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M73,16.3l-29.5,4c-5,1.4-8,5.8-9.5,8.5l-1.5,2.7 c0.2-0.1,0.3-0.1,0.5-0.2l29.5-4c6.6-0.9,7.6-0.1,7.5,4l-2,22.5h5c4.4,0,8-3.6,8-8v-22C81,19.3,77.4,16.3,73,16.3z"
            />
          </g>
          <g display="inline">
            <path
              fill="#956DFB"
              d="M30,51.4l47-3c2.7-0.2,5.1,0.2,7,1c-2-4.4-6.2-8-13.5-7.5l-47,3c-6.6,0-12,5.4-12,12v21H18V63.4 C18,56.8,23.4,51.4,30,51.4z"
            />
            <path
              fill="#723CFA"
              d="M73.5,77.9h12V56.4c0-2.3-0.5-4.8-1.5-7c-1.9-0.8-4.2-1.2-7-1l-47,3c-6.6,0-12,5.4-12,12v14.5h5.5H73.5z"
            />
          </g>
          <polygon
            display="inline"
            fill="#96E2F8"
            points="12.3,51.7 34.6,51.7 12.3,59.7  "
          />
          <path
            display="inline"
            fill="#21B9EC"
            d="M34.6,51.7h-7.2c-3.2,0.7-5.9,2.7-7.6,5.3L34.6,51.7z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M85.5,77.9h-12h-50h-12v-21 c0-6.6,5.4-12,12-12l47-3c11-0.9,15,7.9,15,14.5V77.9z"
          />
          <g display="inline">
            <circle
              stroke="#000000"
              strokeWidth={2.7181}
              strokeLinejoin="round"
              strokeMiterlimit={10}
              cx={30.8}
              cy={75.4}
              r={10.7}
            />
            <circle fill="#D1D1D1" cx={30.8} cy={75.4} r={6.2} />
          </g>
          <g display="inline">
            <circle
              stroke="#000000"
              strokeWidth={2.7181}
              strokeLinejoin="round"
              strokeMiterlimit={10}
              cx={67.8}
              cy={75.4}
              r={10.7}
            />
            <circle fill="#D1D1D1" cx={67.8} cy={75.4} r={6.2} />
          </g>
        </g> ) : null }
      { letter === 'D' ? (
        <g id="Icon-D">
          <polygon
            display="inline"
            fill="#F9A900"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            points=" 46,49.3 17.8,53.3 46.1,38.8  "
          />
          <path
            display="inline"
            fill="#54B03C"
            d="M73.4,81.1c-4.6,1.4-10.9,2.7-18.8,2.7c-12.3,0-23.7-1.4-29-3.5 c-0.2-3,8.2-11.1,11.1-13.2c6.7-4.7,16.8-8,16.7-14.2c-0.1-6.2-6.7-3.6-6.7-3.6s-0.4-6.2-0.9-7.8C25.3,52.7,9.8,54.3,9.4,53.4 s-0.5-3.3,3.9-6c4.5-2.8,8.9-1.4,19.2-12.8c10.3-11.3,15.1-20.2,31.2-18.6c9.8,0.9,18.7,8.3,21,20c2.4,12.6-4,24.5-9.8,32.9 C72.9,71.7,72.9,79,73.4,81.1z"
          />
          <path
            display="inline"
            fill="#0B8720"
            d="M47.2,40.2c0,0,6.9,3,12,0s7-4.1,10-1.4s5.4,9.2,2.9,14.6c-2.1,4.5-5.5,9.3-19.4,15.5 c-9.6,4.3-13.8,13.7-14.2,14.9c-5-1-10.1-2.4-12.9-3.5c-0.2-3,8.2-11.1,11.1-13.2c6.7-4.7,16.8-8,16.7-14.2 c-0.1-6.2-6.7-3.6-6.7-3.6s-0.4-6.2-0.9-7.8C25.3,52.7,9.8,54.3,9.4,53.4c-0.1-0.2-0.1-0.3-0.2-0.6L47.2,40.2z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M73.4,81.1c-4.6,1.4-10.9,2.7-18.8,2.7c-12.3,0-23.7-1.4-29-3.5c-0.2-3,6.1-10.2,11.1-13.2c7.1-4.2,16.8-8,16.7-14.2 c-0.1-6.2-6.7-3.6-6.7-3.6s-0.4-6.2-0.9-7.8C25.3,52.7,9.8,54.3,9.4,53.4s-0.5-3.3,3.9-6c4.5-2.8,8.9-1.4,19.2-12.8 c10.3-11.3,15.1-20.2,31.2-18.6c9.8,0.9,18.7,8.3,21,20c2.4,12.6-4.2,24.4-9.8,32.9C71.5,74,72.9,79,73.4,81.1z"
          />
          <path
            display="inline"
            fill="#FACE3C"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M45.8,41.2 c0,0.3,0.1,0.8,0.1,0.8C25.3,53.2,9.8,54.3,9.4,53.4s-0.5-3.3,3.9-6c4.5-2.8,8.9-1.4,19.2-12.8c0.9-0.9,1.7-1.9,2.5-2.8 c0,0.2,0,0.4,0.1,0.5c0.6,0.4,5.8-3.2,6.2-2.5c0.4,0.7-2.2,1.4-1.2,4.9C41.2,38.2,43,40.4,45.8,41.2z"
          />
          <circle display="inline" cx={56} cy={30.2} r={3.7} />
        </g> ) : null }
      { letter === 'E' ? (
        <g id="Icon-E">
          <path
            display="inline"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FAA772"
            d="M48.7,85.1c-12.2,0-28.3-9.5-27.5-31.1 c0.6-14.5,12.5-38.1,27.5-38.1S75.5,39.5,76.1,54C77,75.6,60.9,85.1,48.7,85.1z"
          />
          <path
            display="inline"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#D4814E"
            d="M76.1,54c-0.2-4.3-1.4-9.5-3.4-14.6 c0.2,1.3,0.3,2.5,0.4,3.6C74,64.6,57.9,74.1,45.7,74.1c-8.6,0-19.2-4.7-24.3-15.1c1.6,18.1,16.1,26.1,27.3,26.1 C60.9,85.1,77,75.6,76.1,54z"
          />
          <path
            display="inline"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d=" M48.7,85.1c-12.2,0-28.3-9.5-27.5-31.1c0.6-14.5,12.5-38.1,27.5-38.1S75.5,39.5,76.1,54C77,75.6,60.9,85.1,48.7,85.1z"
          />
        </g> ) : null }
      { letter === 'F' ? (
        <g id="Icon-F">
          <path
            display="inline"
            fill="#FACE3C"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M76.9,54.4 c-4.7-2.8-8.5-4.5-12.1-5.1c3.6-0.6,7.5-2.3,12.1-5.1c8.8-5.3,8.9-16.4,8.9-16.4s-9.7-5.4-18.7-0.5c-4.8,2.6-8.1,5.1-10.5,7.9 c1.3-3.5,1.7-7.6,1.6-13.1c-0.2-10.3-9.8-15.9-9.8-15.9s-9.5,5.7-9.8,15.9c-0.1,5.5,0.4,9.6,1.6,13.1c-2.4-2.8-5.7-5.3-10.5-7.9 c-9-4.9-18.7,0.5-18.7,0.5s0.2,11.1,8.9,16.4c4.7,2.8,8.5,4.5,12.1,5.1c-3.6,0.6-7.5,2.3-12.1,5.1c-8.8,5.3-8.9,16.4-8.9,16.4 s9.7,5.4,18.7,0.5c4.8-2.6,8.1-5.1,10.5-7.9c-1.3,3.5-1.7,7.6-1.6,13.1c0.2,10.3,9.8,15.9,9.8,15.9s9.5-5.7,9.8-15.9 c0.1-5.5-0.4-9.6-1.6-13.1c2.4,2.8,5.7,5.3,10.5,7.9c9,4.9,18.7-0.5,18.7-0.5S85.7,59.7,76.9,54.4z M51.4,55.4L48.5,55l-2.9,0.4 c-0.7,0.6-1.3,1.3-1.8,2c0.3-0.8,0.6-1.7,0.8-2.6l-1-2.7l-1.8-2.3c-0.9-0.3-1.8-0.5-2.6-0.6c0.9-0.2,1.7-0.4,2.6-0.6l1.8-2.3l1-2.7 c-0.2-0.9-0.5-1.8-0.8-2.6c0.6,0.7,1.2,1.3,1.8,2l2.9,0.4l2.8-0.4c0.7-0.6,1.3-1.3,1.8-2c-0.3,0.8-0.6,1.7-0.8,2.6l1,2.7l1.8,2.3 c0.9,0.3,1.8,0.5,2.6,0.6c-0.9,0.2-1.7,0.4-2.6,0.6l-1.8,2.2l-1,2.7c0.2,0.9,0.5,1.8,0.8,2.6C52.7,56.7,52.1,56.1,51.4,55.4z"
          />
          <g display="inline">
            <path
              fill="#D4510A"
              d="M40.3,45.8c-9.5-5.5-14.1-9.5-14.1-9.5s5.8,2.1,15.3,7.5C50.9,49.3,49.8,51.3,40.3,45.8z"
            />
            <path
              fill="#D4510A"
              d="M56.8,52.7c9.5,5.5,14.1,9.5,14.1,9.5s-5.8-2.1-15.3-7.5C46.2,49.2,47.3,47.2,56.8,52.7z"
            />
            <path
              fill="#D4510A"
              d="M41.4,54.7c-9.5,5.4-15.3,7.5-15.3,7.5s4.7-4,14.1-9.5S50.9,49.2,41.4,54.7z"
            />
            <path
              fill="#D4510A"
              d="M55.6,43.8c9.5-5.4,15.3-7.5,15.3-7.5s-4.7,4-14.1,9.5C47.3,51.3,46.2,49.3,55.6,43.8z"
            />
            <path
              fill="#D4510A"
              d="M47.4,40.4c0-10.9,1.1-17,1.1-17s1.1,6,1.1,17C49.7,51.3,47.4,51.3,47.4,40.4z"
            />
            <path
              fill="#D4510A"
              d="M49.7,58.1c0,10.9-1.1,17-1.1,17s-1.1-6-1.1-17C47.4,47.2,49.7,47.2,49.7,58.1z"
            />
          </g>
          <g display="inline">
            <path
              fill="#FA793C"
              d="M35.3,41.6c0,0,0.1,3.7,1.8,7.9c1.5-0.1,3-0.4,4.6-0.9l1.8-2.3l1-2.7c-0.4-1.6-0.9-3.1-1.6-4.4 C38.6,39.8,35.3,41.6,35.3,41.6z"
            />
            <path
              fill="#FA793C"
              d="M61.7,56.9c0,0-0.1-3.7-1.8-7.9c-1.5,0.1-3,0.4-4.6,0.9l-1.8,2.2l-1,2.7c0.4,1.6,0.9,3.1,1.6,4.4 C58.5,58.7,61.7,56.9,61.7,56.9z"
            />
            <path
              fill="#FA793C"
              d="M35.3,56.9c0,0,3.2,1.8,7.7,2.4c0.7-1.4,1.2-2.8,1.6-4.4l-1-2.7l-1.8-2.3c-1.6-0.5-3.1-0.8-4.6-0.9 C35.4,53.2,35.3,56.9,35.3,56.9z"
            />
            <path
              fill="#FA793C"
              d="M61.7,41.6c0,0-3.2-1.8-7.7-2.4c-0.7,1.4-1.2,2.8-1.6,4.4l1,2.7l1.8,2.3c1.6,0.5,3.1,0.8,4.6,0.9 C61.7,45.3,61.7,41.6,61.7,41.6z"
            />
            <path
              fill="#FA793C"
              d="M48.5,34c0,0-3.2,1.9-5.9,5.5c0.9,1.3,1.9,2.4,3.1,3.6l2.9,0.4l2.8-0.4c1.2-1.1,2.2-2.3,3.1-3.6 C51.7,35.9,48.5,34,48.5,34z"
            />
            <path
              fill="#FA793C"
              d="M48.5,64.5c0,0,3.2-1.9,5.9-5.5c-0.9-1.3-1.9-2.4-3.1-3.6L48.5,55l-2.9,0.4c-1.2,1.1-2.2,2.3-3.1,3.6 C45.4,62.6,48.5,64.5,48.5,64.5z"
            />
          </g>
          <circle display="inline" fill="#D4510A" cx={48.5} cy={49.1} r={8.9} />
        </g> ) : null }
      { letter === 'G' ? (
        <g id="Icon-G">
          <use
            xlinkHref="#Guitar_String_Adjuster"
            width={11.1}
            height={5.3}
            x={-4.5}
            y={-2.7}
            transform="matrix(0.4483 0.7092 0.7092 -0.4483 83.2402 9.3193)"
            display="inline"
            overflow="visible"
          />
          <use
            xlinkHref="#Guitar_String_Adjuster"
            width={11.1}
            height={5.3}
            x={-4.5}
            y={-2.7}
            transform="matrix(0.4483 0.7092 0.7092 -0.4483 77.7891 12.7114)"
            display="inline"
            overflow="visible"
          />
          <use
            xlinkHref="#Guitar_String_Adjuster"
            width={11.1}
            height={5.3}
            x={-4.5}
            y={-2.7}
            transform="matrix(0.4483 0.7092 0.7092 -0.4483 72.6221 15.9141)"
            display="inline"
            overflow="visible"
          />
          <use
            xlinkHref="#Guitar_String_Adjuster"
            width={11.1}
            height={5.3}
            id="XMLID_7_"
            x={-4.5}
            y={-2.7}
            transform="matrix(0.4483 0.7092 0.7092 -0.4483 67.4551 19.1167)"
            display="inline"
            overflow="visible"
          />
          <path
            display="inline"
            fill="#E6333E"
            d="M52.9,21.4c1.7,2.5,1.2,3.1-1.6,6.4c-1.8,2.1-3.2,6-0.7,8.5c2.5,2.5,7.2,1.3,7.2,1.3 l3.2,3.9l-5.2,6.1c0,0-2.1,2.7,1.8,5.5c2.6,1.9,9-1.3,10.6-2.6s4.8-2.1,2.8,3.9c-1.3,4-8.8,6.9-11.2,8.4c-2.5,1.4-6.4,1.1-9.3,12.6 C47.7,86.9,39.7,91.7,34,91c-5.7-0.7-13-6.8-15.9-9.4c-3.5-3.2-11.1-10.6-9.9-18.4c1.3-8,9.2-13,18.1-15.9s10.2-13.9,12.1-16.2 s6-8.5,9.6-9.6C51.4,20.4,52.9,21.4,52.9,21.4z"
          />
          <path
            display="inline"
            fill="#B5000D"
            d="M50.6,75.4c2.9-11.5,6.8-11.1,9.3-12.6c2.5-1.4,9.9-4.3,11.2-8.4c1.7-5.2-0.4-5.3-2.1-4.4 c-0.1,0.2-0.2,0.5-0.3,0.7c-1.6,4.2-9.3,6.9-11.9,8.3c-2.6,1.4-6.7,0.9-10.2,12.7c-3.5,11.9-12.1,16.6-18,15.6 c-2.7-0.4-5.7-2-8.4-3.9c3.6,2.9,9.1,6.9,13.6,7.4C39.7,91.7,47.7,86.9,50.6,75.4z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M52.9,21.4c2,1.3,1.2,3.1-1.6,6.4c-1.8,2.1-3.2,6-0.7,8.5c2.5,2.5,7.2,1.3,7.2,1.3l3.2,3.9l-5.2,6.1c0,0-2.1,2.7,1.8,5.5 c2.6,1.9,9-1.3,10.6-2.6s4.8-2.1,2.8,3.9c-1.3,4-8.8,6.9-11.2,8.4c-2.5,1.4-6.4,1.1-9.3,12.6C47.7,86.9,39.7,91.7,34,91 c-5.7-0.7-13-6.8-15.9-9.4c-3.5-3.2-11.1-10.6-9.9-18.4c1.3-8,9.2-13,18.1-15.9s10.9-13.9,12.1-16.7c1.5-3.7,6-8,9.6-9.1 C51.4,20.4,52.9,21.4,52.9,21.4z"
          />
          <path
            display="inline"
            fill="#B17649"
            d="M87.9,11.8c0,0,5,4.8,1.2,6.9c-3.8,2-4.6,1.7-6.2,3.8s-3.5,8.4-5.6,9.2s-6.2,0.1-6.9,1.1 c-0.7,1-19.9,22.7-19.9,22.7l-6.9-6.9l20.7-20.7l2.1-3.2l18.9-12.1C85.4,12.5,87.2,11,87.9,11.8z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M87.9,11.8c0,0,5,4.8,1.2,6.9 c-3.8,2-4.6,1.7-6.2,3.8s-3.5,8.4-5.6,9.2s-6.2,0.1-6.9,1.1c-0.7,1-19.9,22.7-19.9,22.7l-6.9-6.9l20.7-20.7l2.1-3.2l18.9-12.1 C85.4,12.5,87.2,11,87.9,11.8z"
          />
          <path
            display="inline"
            fill="#290003"
            d="M33.4,78.6c-0.9,0.9-2.3,0.9-3.2,0L20.6,69c-0.9-0.9-0.9-2.3,0-3.2l3.2-3.2 c0.9-0.9,2.3-0.9,3.2,0l9.6,9.6c0.9,0.9,0.9,2.3,0,3.2L33.4,78.6z"
          />
          <path
            display="inline"
            fill="#290003"
            d="M43.8,63.1c-0.5,0.5-1.4,0.5-1.9,0l-5.8-5.8c-0.5-0.5-0.5-1.4,0-1.9l1.9-1.9 c0.5-0.5,1.4-0.5,1.9,0l5.8,5.8c0.5,0.5,0.5,1.4,0,1.9L43.8,63.1z"
          />
          <circle display="inline" fill="#290003" cx={43} cy={70.5} r={2.1} />
          <circle display="inline" fill="#290003" cx={40.7} cy={80.7} r={2.1} />
        </g> ) : null }
      { letter === 'H' ? (
        <g id="Icon-H">
          <g display="inline">
            <ellipse fill="#A4D534" cx={49} cy={69.3} rx={38.8} ry={9.3} />
            <path
              fill="#A4D534"
              d="M49,32.7c-14,0-25.4-2.5-25.4-5.7v36.7c0,3.1,11.4,5.7,25.4,5.7c14,0,25.4-2.5,25.4-5.7V27 C74.4,30.1,63,32.7,49,32.7z"
            />
            <ellipse fill="#A4D534" cx={49} cy={27} rx={25.4} ry={5.7} />
          </g>
          <path
            display="inline"
            fill="#5FAB19"
            d="M86.3,71.9c1-0.8,1.5-1.7,1.5-2.6c0-5.2-17.4-9.3-38.8-9.3c-9.5,0-18.2,0.8-25,2.2 L86.3,71.9z"
          />
          <path
            display="inline"
            fill="#5FAB19"
            d="M60.5,32v36.7c8.2-0.9,13.9-2.8,13.9-5V27C74.4,29.2,68.7,31.1,60.5,32z"
          />
          <path
            display="inline"
            fill="#FA3C80"
            d="M49,56.8c-14,0-25.4-2.5-25.4-5.7v12.5c0,3.1,11.4,5.7,25.4,5.7c14,0,25.4-2.5,25.4-5.7 V51.2C74.4,54.3,63,56.8,49,56.8z"
          />
          <path
            display="inline"
            fill="#A80F55"
            d="M60.5,56.2v12.5c8.2-0.9,13.9-2.8,13.9-5V51.2C74.4,53.4,68.7,55.3,60.5,56.2z"
          />
          <g display="inline">
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M23.6,27v36.7c0,3.1,11.4,5.7,25.4,5.7 c14,0,25.4-2.5,25.4-5.7V27"
            />
            <path
              display="none"
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M49,32.7c-14,0-25.4-2.5-25.4-5.7 v36.7c0,3.1,11.4,5.7,25.4,5.7c14,0,25.4-2.5,25.4-5.7V27C74.4,30.1,63,32.7,49,32.7z"
            />
            <ellipse
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              cx={49}
              cy={27}
              rx={25.4}
              ry={5.7}
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M23.6,62.2c-8.2,1.7-13.5,4.2-13.5,7.1 c0,5.2,17.4,9.3,38.8,9.3c21.4,0,38.8-4.2,38.8-9.3c0-2.8-5.2-5.3-13.3-7"
            />
          </g>
        </g> ) : null }
      { letter === 'I' ? (
        <g id="Icon-I">
          <path
            display="inline"
            fill="#BDEDFB"
            d="M49,26.3c-20.3,0-36.7,16.5-36.7,36.7c0,3.8,0,6.9,0,10.6c2.1,0.6,6.3,1.6,9,2.2 c0-2.9,0-7.3,0-10.3c0-5,4.3-7.9,4.3-7.9l5.5-2.8c0,0,3.5-1.7,7.3-1.7c7.6,0,9.3,8.3,9.3,8.3v15.8l0.4,0.8c0.9,0,0.2,0,1.1,0 c14.2,0,28.7-2.1,36.9-4.6c0-3.9-0.1-6.7-0.1-10.5C85.7,42.7,69.3,26.3,49,26.3z"
          />
          <path
            display="inline"
            fill="#BDEDFB"
            d="M47.5,78.3V61.5c0,0-1.7-8.3-9.3-8.3c-3.8,0-12.8,4.4-12.8,4.4s-4.3,2.9-4.3,7.9 c0,4.8,0,13.2,0,13.2l5,1.1V66.4c0,0,0.3-4.2,5.3-4.2s5,5.7,5,5.7l0.2,13.3l4.7,0.8L47.5,78.3z"
          />
          <path
            display="inline"
            d="M36.5,67.9c0,0,0-5.7-5-5.7s-5.3,4.2-5.3,4.2v13.2l0.8-0.3l9.5-4.5L36.5,67.9z"
          />
          <polygon
            display="inline"
            fill="#0BBBEF"
            points="47.5,61.5 41.7,64.5 41.7,81 47.9,78.1  "
          />
          <path
            display="inline"
            fill="#0BBBEF"
            d="M85.7,63c0-19.9-15.8-36.1-35.6-36.7c15.1,6.4,17,23,17,37c0,5.2,0.2,9.2,0.2,14.1 c9-0.8,13.1-2.3,18.5-3.9C85.9,69.6,85.7,66.8,85.7,63z"
          />
          <g display="inline">
            <line
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              x1={67.3}
              y1={61.2}
              x2={67.3}
              y2={76.7}
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M57,47.1c0,0,1.3,6.1,1.3,14.8"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M48,33.1c0,0-0.2,5.1-0.2,13.8"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeLinecap="round"
              strokeMiterlimit={10}
              d="M59,33.1c0,0,4.9,5,6.3,13.3"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeLinecap="round"
              strokeMiterlimit={10}
              d="M36.7,33.1c0,0-4.9,4.5-6.3,12.8"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M39.3,47.1c0,0-0.5,2.3-0.8,6.1"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M72.4,45.6c0,0,5,6.9,4.8,14.3"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M24.3,45.1c0,0-5,6.9-4.8,14.3"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeLinecap="round"
              strokeMiterlimit={10}
              d="M29.2,32c6,0.7,12.7,1.1,19.8,1.1 c7.2,0,13.5-0.4,19.6-1.1"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M17,43.9c8.1,2,19.5,3.2,32,3.2c12.4,0,23.6-1.2,31.7-3.2"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M12.5,57.6c2.7,0.9,6,1.7,9.7,2.4"
            />
            <path
              fill="none"
              stroke="#006894"
              strokeMiterlimit={10}
              d="M49,62.1c15,0,28.4-1.8,36.5-4.5"
            />
          </g>
          <g id="Main_Outlines" display="inline">
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeLinejoin="round"
              strokeMiterlimit={10}
              d="M47.5,77.3l0.4,0.8 c0.9,0,0.2,0,1.1,0c14.2,0,28.3-2.1,36.6-4.6c0.2-3.4,0.2-6.7,0.2-10.5c0-20.3-16.5-36.7-36.7-36.7S12.3,42.7,12.3,63 c0,3.8,0.3,6.8,0.2,10.5c2.1,0.6,6.1,1.7,8.8,2.2"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              x1={36.6}
              y1={76.1}
              x2={26.9}
              y2={79.6}
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              d=" M48,78.3V61.5c0,0-2.2-8.3-9.8-8.3c-6.8,0-12.8,3.9-12.8,3.9s-4.8,3.4-4.8,8.4c0,4.8,0,13.2,0,13.2l6,1.1V66.4 c0,0-0.3-4.2,4.8-4.2s5,5.7,5,5.7l0.2,13.3l4.7,0.8L48,78.3z"
            />
          </g>
        </g> ) : null }
      { letter === 'J' ? (
        <g id="Icon-J">
          <path
            display="inline"
            fill="#964A93"
            d="M80.2,23.5c-4.4-7.5-16.8-4.8-26.3-4.8s-20.2-2.8-24-3.1S20,17.4,20,17.4 c2.9,6.1,13.3,11.8,10.8,18.4s-11,15.8-12.6,24.2c-1.5,7.9,1.5,18,11.2,22.7c9.7,4.7,24.8,2.4,29.8-0.5S67,75.7,68,69.3 c0.9-6.4,5.7-9,11.6-19.5C85.6,39.3,84.6,31.1,80.2,23.5z M71.8,49.9c-2.5,3.7-3.6,4.6-5.7,0.9c-1-1.8-6.4-13-6.4-16.3 c0-3.3,1.9-8.7,6.8-9.4c4.4-0.6,9,2.6,9.9,7.3C77.5,37.2,74.1,46.5,71.8,49.9z"
          />
          <path
            display="inline"
            fill="#521D4F"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M62.6,20.5c0.3-0.6-0.3-1.3-1.3-2c-2.5,0.1-5,0.3-7.4,0.3c-9.4,0-20.2-2.8-24-3.1c-3.3-0.3-8.3,1.2-9.6,1.6 C27.5,20,36.4,26,45.8,26C54.4,26,61.7,22.6,62.6,20.5z"
          />
          <path
            display="inline"
            fill="#70296D"
            d="M66.8,51.8c-0.2-0.3-0.4-0.6-0.6-1c-1-1.8-6.4-13-6.4-16.3c0-2.5,1.1-6.3,3.9-8.2 c-2.8-0.9-7.5,1-10.2,5.2c-3.8,5.9-3.1,11.8,1.2,18.3c4.1,6.2,7.4,19.4-2.8,34.7c3.2-0.6,5.8-1.4,7.4-2.4c5-2.8,7.9-6.4,8.8-12.8 c0.2-1.6,0.7-3,1.4-4.4C66.5,60.7,62.9,57.9,66.8,51.8z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M80.2,23.5c-4.4-7.5-16.8-4.8-26.3-4.8s-20.2-2.8-24-3.1S20,17.4,20,17.4c2.9,6.1,13.3,11.8,10.8,18.4s-11,15.8-12.6,24.2 c-1.5,7.9,1.5,18,11.2,22.7c9.7,4.7,24.8,2.4,29.8-0.5S67,75.7,68,69.3c0.9-6.4,5.7-9,11.6-19.5C85.6,39.3,84.6,31.1,80.2,23.5z  M71.8,49.9c-2.5,3.7-3.6,4.6-5.7,0.9c-1-1.8-6.4-13-6.4-16.3c0-3.3,1.9-8.7,6.8-9.4c4.4-0.6,9,2.6,9.9,7.3 C77.5,37.2,74.1,46.5,71.8,49.9z"
          />
          <path
            display="inline"
            d="M33.6,24.1c3.9,1.1,8,1.9,12.2,1.9c4,0,7.8-0.5,10.7-1.2c-1.9-1-6.7-1.7-12.4-1.7 C39.8,23.1,36,23.5,33.6,24.1z"
          />
        </g> ) : null }
      { letter === 'K' ? (
        <g id="Icon-K">
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={1.7494}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M67.9,65.4C80.7,76,85.4,86.8,74.4,89.3c-8.9,2.1-16.1-2.2-23.3-8.7c-4.2-3.8-7.6-8.2-16.7-10.1c-8.1-1.7-15.4,0.5-9.2,7.9 C32,86.5,12.8,88.3,11,88"
          />
          <g display="inline">
            <polygon
              fill="#22B4E6"
              points="58.5,13.3 36.7,36.8 73.8,71.2 74.4,71.3  "
            />
            <polygon
              fill="#FA3C80"
              points="58.5,13.3 13.7,15 13.7,15.4 36.7,36.8  "
            />
            <path
              fill="#1B94BD"
              d="M58.5,13.3L54.1,18c0,0-0.3,10.3,2.7,20.3c3,10,17.1,32.8,17.1,32.8l0.5,0.1L58.5,13.3z"
            />
            <path
              fill="#D10360"
              d="M58.5,13.3L13.7,15l0,0.4c0,0,14.1,5.7,24.7,5.9c7.7,0.2,14.8-2.3,14.8-2.3L58.5,13.3z"
            />
            <polygon fill="#66CC66" points="15.4,59.7 73.8,71.2 36.7,36.8  " />
            <polygon fill="#FACE3C" points="36.7,36.8 13.7,15.4 15.4,59.7  " />
            <polygon
              fill="none"
              stroke="#000000"
              strokeWidth={2.2197}
              strokeLinejoin="round"
              strokeMiterlimit={10}
              points="74.4,71.3  15.4,59.7 13.7,15 58.5,13.3  "
            />
            <line
              fill="none"
              stroke="#000000"
              strokeWidth={1.1098}
              strokeMiterlimit={10}
              x1={15.4}
              y1={59.7}
              x2={58.5}
              y2={13.3}
            />
            <line
              fill="none"
              stroke="#000000"
              strokeWidth={1.1098}
              strokeMiterlimit={10}
              x1={13.7}
              y1={15}
              x2={74.4}
              y2={71.3}
            />
          </g>
        </g> ) : null }
      { letter === 'L' ? (
        <g id="Icon-L">
          <path
            display="inline"
            fill="#913506"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M48.7,31.3l-14.2-2.4c-0.6-0.7-4.2-5.7-2.5-4.7c0.3-1.9-1.1-4.8-5-7.2c4.8-1.9,12-0.6,12-0.6s-1-2.2-4.1-5.4 c2.8-0.1,8.9,1.3,10.2,2.6c0.1-1.6,3.5-4.3,3.5-4.3h0c0,0,3.4,2.7,3.5,4.3c1.3-1.3,7.4-2.7,10.2-2.6c-3,3.2-4.1,5.4-4.1,5.4 s7.3-1.3,12,0.6c-3.9,2.4-5.3,5.3-5,7.2c1.6-0.9-2,4.1-2.5,4.7L48.7,31.3z"
          />
          <path
            display="inline"
            fill="#B17F49"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M55.2,82.3c0,0,2-1.3,10.5-7.9 c15.1-11.7,9.7-39.8,9.7-39.8s5.1-3.2,6.2-6.7c0.8-2.5,0.7-7.9-3.5-10.4c-5-3-11.1-2.8-17.7,8c-0.8,1.7-2.2,2.7-2.2,2.7H39.2 c0,0-1.4-1-2.2-2.7c-6.7-10.9-12.7-11-17.7-8C15,20,15,25.4,15.7,27.9c1.1,3.5,6.2,6.7,6.2,6.7s-5.3,28.2,9.7,39.8 c8.5,6.6,10.5,7.9,10.5,7.9H55.2z"
          />
          <path
            display="inline"
            fill="#913506"
            d="M48.7,32.1c0,0-9.1-2.9-10.8-4.9c-1.8-2-5.3,4.7-5.3,4.7S19.6,30.3,13.9,39 c0,0.1,3.9,1.2,3.9,1.2s-6.4,1.3-6.4,12.1c2.1-2.9,5.4-2.8,5.4-2.8s-4.4,2.8-1.1,15.1c2.3-3.7,4.6-3.9,4.6-3.9S20.8,65,26,71.8 c7.1,9.3,20,9.3,22.7,18.8c2.6-9.6,15.5-9.5,22.7-18.8C76.6,65,77,60.7,77,60.7s2.3,0.2,4.6,3.9c3.3-12.3-1.1-15.1-1.1-15.1 s3.3-0.2,5.4,2.8c0-10.8-6.4-12.1-6.4-12.1s3.9-1.1,3.9-1.2c-5.7-8.8-18.6-7.1-18.6-7.1s-3.5-6.7-5.3-4.7S48.7,32.1,48.7,32.1z"
          />
          <path
            display="inline"
            fill="#6B2604"
            d="M85.9,52.3c0-10.8-6.4-12.1-6.4-12.1s3.9-1.1,3.9-1.2c-1.9-2.9-4.6-4.7-7.4-5.7 c0.3,0.3,0.6,0.7,0.8,1.1c0,0.1-4.2,1.7-4.2,1.7s3.7,2.2,6.7,9c-1.7-2.4-5.9-1.3-5.9-1.3s4.7,3.5,3.4,11.8c-1.9-3-4.8-1.9-4.8-1.9 s-2,8.8-6.2,14.4c-5.9,7.7-19.7,6.7-17.4,21.9c0,0,0,0,0,0c0.1,0.3,0.2,0.5,0.3,0.8c2.6-9.6,15.5-9.5,22.7-18.8 C76.6,65,77,60.7,77,60.7s2.3,0.2,4.6,3.9c3.3-12.3-1.1-15.1-1.1-15.1S83.8,49.3,85.9,52.3z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#1D1D1B"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M48.7,32.1c0,0-9.1-2.9-10.8-4.9c-1.8-2-5.3,4.7-5.3,4.7S19.6,30.3,13.9,39c0,0.1,3.9,1.2,3.9,1.2s-6.4,1.3-6.4,12.1 c2.1-2.9,5.4-2.8,5.4-2.8s-4.4,2.8-1.1,15.1c2.3-3.7,4.6-3.9,4.6-3.9S20.8,65,26,71.8c7.1,9.3,20,9.3,22.7,18.8 c2.6-9.6,15.5-9.5,22.7-18.8C76.6,65,77,60.7,77,60.7s2.3,0.2,4.6,3.9c3.3-12.3-1.1-15.1-1.1-15.1s3.3-0.2,5.4,2.8 c0-10.8-6.4-12.1-6.4-12.1s3.9-1.1,3.9-1.2c-5.7-8.8-18.6-7.1-18.6-7.1s-3.5-6.7-5.3-4.7S48.7,32.1,48.7,32.1z"
          />
          <path
            display="inline"
            stroke="#1D1D1B"
            strokeWidth={0.1349}
            strokeMiterlimit={10}
            d="M65,27.5c0,0,5.6-8.5,9.8-6.7 c4.2,1.8,3.6,6.3,2.7,7c-2.6,2.3-9.3-2.3-9.8,1.6c-0.4,3.8-0.8,0.7-0.8,0.7L65,27.5z"
          />
          <path
            display="inline"
            stroke="#1D1D1B"
            strokeWidth={0.1349}
            strokeMiterlimit={10}
            d="M32.3,27.5c0,0-5.6-8.5-9.8-6.7 c-4.2,1.8-3.6,6.3-2.7,7c2.6,2.3,9.3-2.3,9.8,1.6c0.4,3.8,0.8,0.7,0.8,0.7L32.3,27.5z"
          />
          <path
            display="inline"
            fill="#FFDA94"
            d="M48.7,69.5c0,0-4.4,0.4-6.6-0.9c-1.2-0.7-3.2-6.1-5.5-9.9c-0.3-0.4-0.5-0.8-0.8-1.2 c0.1-0.8,0.1-1.5,0.2-2.1c0.3-3.9,5.1-1.9,5-2c0,0-0.1-0.1-0.2-0.3c3.2,1.2,6.3,1.5,7.9,1.5h0c1.6-0.1,4.7-0.3,7.9-1.5 c-0.1,0.2-0.2,0.3-0.2,0.3c-0.2,0.1,4.7-1.9,5,2c0,0.6,0.1,1.3,0.2,2.1c-0.3,0.4-0.5,0.8-0.8,1.2c-2.3,3.8-4.3,9.2-5.5,9.9 C53.1,69.9,48.7,69.5,48.7,69.5L48.7,69.5z"
          />
          <path
            display="inline"
            fill="#B17F49"
            d="M48.7,28.4c0,0-10-8-17.5-0.3c-4.3,4.5-5.3,12.5,0.4,23.4c0.4,0.8,2.4,3.3,4.3,6.1 c0.1-0.8,0.1-1.5,0.2-2.1c0.3-3.9,5.1-1.9,5-2c-0.2-0.1,4.8,1.7,7.7,1.7h0c2.9,0,7.8-1.7,7.7-1.7c-0.2,0.1,4.7-1.9,5,2 c0,0.6,0.1,1.3,0.2,2.1c1.8-2.8,3.9-5.3,4.3-6.1c5.6-10.9,4.7-18.9,0.4-23.4C58.7,20.4,48.7,28.4,48.7,28.4"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            d="M48.7,69.5c0,0-4.4,0.4-6.6-0.9 c-1.2-0.7-3.2-6.1-5.5-9.9c-0.3-0.4-0.5-0.8-0.8-1.2c-1.8-2.8-3.9-5.3-4.3-6.1c-5.6-10.9-4.7-18.9-0.4-23.4 c7.5-7.7,17.5,0.3,17.5,0.3s10-8,17.5-0.3c4.3,4.5,5.3,12.5-0.4,23.4c-0.4,0.8-2.4,3.3-4.3,6.1c-0.3,0.4-0.5,0.8-0.8,1.2 c-2.3,3.8-4.3,9.2-5.5,9.9C53.1,69.9,48.7,69.5,48.7,69.5z"
          />
          <path
            display="inline"
            stroke="#1D1D1B"
            strokeWidth={0.139}
            strokeMiterlimit={10}
            d="M48.7,53.8c-2.6,0-4.2-0.3-6.3-1.1 c-2.2-0.8-2.3-1.4-2.3-1.4c-0.1,3.5,4.6,2.6,5.1,3.5c0.4,0.9,2.9,2.6,3.5,4.6c0,0-2-0.1-4.2,1.7c-2.6,2.2-5,0.6-6-0.8l2.2,4.5 c0,0,4-2.9,8-2.9h0c4,0,8,2.9,8,2.9l2.2-4.5c-1,1.4-3.4,3-6,0.8c-2.1-1.8-4.2-1.7-4.2-1.7c0.6-1.9,3.1-3.7,3.5-4.6s5.2,0,5.1-3.5 c0,0-0.1,0.6-2.3,1.4C52.8,53.5,51.2,53.8,48.7,53.8L48.7,53.8z"
          />
          <circle display="inline" cx={59.3} cy={36.8} r={3.7} />
          <circle display="inline" cx={38} cy={36.8} r={3.7} />
        </g> ) : null }
      { letter === 'M' ? (
        <g id="Icon-M">
          <g display="inline">
            <path
              fill="#DAEBF0"
              d="M58.5,75.7c-14.6,0-26.4-11.8-26.4-26.4s11.8-26.4,26.4-26.4c1.6,0,3.1,0.1,4.7,0.4 c-5.3-3.8-11.8-6.1-18.8-6.1c-17.7,0-32.1,14.4-32.1,32.1s14.4,32.1,32.1,32.1c7,0,13.5-2.3,18.8-6.1 C61.7,75.5,60.1,75.7,58.5,75.7z"
            />
            <path
              fill="#98C2D0"
              d="M58.5,22.8c1.6,0,3.1,0.1,4.7,0.4c-2.3-1.7-4.8-2-7.5-3c-7.2-1.1-30.4,6-30.4,29s23.7,31.3,32.8,28.9 c2.7-1,2.7-1.3,5-2.9c-1.5,0.3-3.1,0.4-4.7,0.4c-14.6,0-26.4-11.8-26.4-26.4S43.9,22.8,58.5,22.8z"
            />
            <path
              opacity={0.8}
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeLinejoin="round"
              strokeMiterlimit={10}
              d="M58.5,75.7 c-14.6,0-26.4-11.8-26.4-26.4s11.8-26.4,26.4-26.4c1.6,0,3.1,0.1,4.7,0.4c-5.3-3.8-11.8-6.1-18.8-6.1c-17.7,0-32.1,14.4-32.1,32.1 s14.4,32.1,32.1,32.1c7,0,13.5-2.3,18.8-6.1C61.7,75.5,60.1,75.7,58.5,75.7z"
            />
          </g>
          <polygon
            display="inline"
            fill="#F5DC3D"
            stroke="#F9A900"
            strokeWidth={2}
            strokeMiterlimit={10}
            points="84.3,73.6 80,78  81.7,83.9 77.3,79.6 71.3,81.3 75.6,76.8 74,70.9 78.4,75.2  "
          />
          <polygon
            display="inline"
            fill="#F5DC3D"
            stroke="#F9A900"
            strokeWidth={2}
            strokeMiterlimit={10}
            points="68.1,44.1 74.1,50.6  82.8,48.7 76.2,54.7 78.1,63.4 72.1,56.9 63.4,58.8 70,52.8  "
          />
          <polygon
            display="inline"
            fill="#F5DC3D"
            stroke="#F9A900"
            strokeWidth={2}
            strokeMiterlimit={10}
            points="82.1,10.4 81,17.6  86.6,22.2 79.5,21 74.9,26.7 76,19.5 70.4,14.9 77.5,16  "
          />
        </g>  ) : null }
      { letter === 'N' ? (
        <g id="Icon-N">
          <g id="Hammer_3_" display="inline">
            <path
              fill="#EA4260"
              d="M77.8,86.2c-0.7,0-1.4-0.3-2-0.9L56.3,64.4c-1-1-1.2-2-1.2-2.8L32,36.9l-0.6,0.6c-0.2,0.2-0.4,0.3-0.7,0.3 c-0.2,0-0.4-0.1-0.6-0.2l-3.6-2.4L26,35c0,0-0.7-0.1-0.7-0.1c-0.8,0-2.1,0.8-3.3,1.9c-0.4,0.4-0.2,1.3-0.1,2.1 c0.1,0.3,0.1,0.7,0.1,1c0.2,1.3-0.7,3.6-1.6,4.5L20,44.9c-0.8,0.8-1.8,1.2-2.8,1.2c-1.1,0-2-0.4-2.8-1.1l-5.4-4.4 c0,0-0.1,0-0.1-0.1c-1.6-1.6-1.6-4.1,0-5.7l0.5-0.5c1.7-1.7,3-2.6,3.9-2.6c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0l0.6,0.1 c0.4,0.1,0.7,0.1,0.9,0.1c0.2,0,0.2,0,1.1-0.9c0.2-0.2,0.4-0.4,0.7-0.7c0.9-0.9,1.2-2.3,0.8-3.5c-0.1-0.4,0-0.8,0.2-1.1l7.5-7.5 c2.3-2.3,5.2-3.5,9.2-3.7c0.2,0,0.4,0,0.6,0c5.8,0,12.5,4.9,12.8,5.1c0.4,0.3,0.5,0.8,0.3,1.2c-0.2,0.4-0.5,0.6-0.9,0.6 c-0.1,0-0.2,0-0.2,0c-0.1,0-5.6-1.3-9.1-1.3c-0.7,0-1.3,0.1-1.7,0.2c-2.4,0.6-3.7,1.8-4.8,2.9l5.3,8c0.3,0.4,0.2,0.9-0.1,1.3 l-0.6,0.6l24.7,23.1c0.1,0,0.2,0,0.3,0c0.9,0,1.7,0.4,2.5,1.2l20.9,19.4c1.1,1.1,1,2.1,0.8,2.8c-0.2,0.7-0.7,1.4-1.5,2.3l-2.9,2.9 C80.2,85.3,79.1,86.2,77.8,86.2L77.8,86.2z"
            />
            <path
              fill="#BD1622"
              d="M35.3,15.3c5.6,0,12.2,4.9,12.2,4.9s-5.6-1.3-9.3-1.3c-0.8,0-1.4,0.1-2,0.2c-3.1,0.8-4.6,2.4-5.9,3.8 l0.2,0.2l5.5,8.4l-1.3,1.3L60.5,57c0.2-0.1,0.4-0.1,0.6-0.1c0.6,0,1.2,0.3,1.8,0.9l20.9,19.5c1.2,1.2,0.5,2.5-0.7,3.7l-2.9,2.9 c-0.8,0.8-1.6,1.3-2.3,1.3c-0.4,0-0.9-0.2-1.3-0.6L57,63.7c-0.8-0.8-1.1-1.6-0.8-2.4L32,35.5l-1.3,1.3l-3.7-2.4L26.3,34l-0.8-0.1 c-0.1,0-0.1,0-0.2,0c-1.3,0-3,1.3-3.9,2.2C20.4,37,21,38.8,21.1,40c0.2,1-0.6,3-1.3,3.7l-0.5,0.5c-0.6,0.6-1.4,0.9-2.1,0.9 c-0.8,0-1.5-0.3-2.1-0.9l-5.5-4.5c-1.2-1.2-1.2-3.1,0-4.2L10,35c0.6-0.6,2.5-2.4,3.4-2.4c0,0,0,0,0.1,0l0,0 c0.8,0.1,1.2,0.2,1.6,0.2c0.8,0,1-0.4,2.5-1.9c1.2-1.2,1.6-3,1-4.6l7.5-7.5c2.2-2.2,5-3.2,8.6-3.4C34.9,15.4,35.1,15.3,35.3,15.3  M35.3,13.3L35.3,13.3c-0.2,0-0.5,0-0.7,0c-4.3,0.3-7.4,1.5-9.9,4l-7.5,7.5c-0.6,0.6-0.7,1.4-0.5,2.1c0.3,0.9,0.1,1.8-0.5,2.5 c-0.3,0.3-0.5,0.5-0.7,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0-0.4,0-0.6-0.1l-0.6-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4,0 c-0.7,0.1-2,0.4-4.5,2.9L8.2,34c-1.9,1.9-1.9,5.1,0,7.1c0,0,0.1,0.1,0.1,0.1l5.4,4.4c0.9,0.9,2.2,1.4,3.5,1.4 c1.3,0,2.6-0.5,3.5-1.5l0.5-0.5c1.1-1.1,2.1-3.7,1.9-5.4c0-0.3-0.1-0.7-0.1-1c-0.1-0.4-0.2-1-0.1-1.3c1-0.9,2-1.5,2.4-1.6l0.5,0.1 l0.4,0.2l3.5,2.3c0.3,0.2,0.7,0.3,1.1,0.3c0.4,0,0.9-0.1,1.3-0.4l22.1,23.6c0,0.9,0.3,2,1.5,3.1L75,85.9c0,0,0,0,0,0 c1,1,2,1.2,2.7,1.2c1.6,0,2.9-1.1,3.7-1.9l2.9-2.9c1-1,1.5-1.8,1.8-2.7c0.4-1.4,0-2.7-1.1-3.8c0,0,0,0,0,0L64.3,56.4 c-1.2-1.2-2.3-1.4-3.1-1.5L37.5,32.8c0.6-0.7,0.6-1.6,0.1-2.4l-4.8-7.3c0.9-0.9,2-1.6,3.8-2.1c0.2-0.1,0.7-0.1,1.5-0.1 c3.4,0,8.8,1.3,8.9,1.3c0.2,0,0.3,0.1,0.5,0.1c0.8,0,1.5-0.4,1.8-1.2c0.4-0.9,0.1-1.9-0.6-2.5C48.3,18.4,41.4,13.3,35.3,13.3 L35.3,13.3z"
            />
          </g>
          <g id="Nail_2_" display="inline">
            <path
              fill="#D9D9D9"
              d="M72.2,25.8c1.3,1.3,2.4,4,1.4,5L24.5,79.9l-8.9,2.5l0,0l2.5-8.9l49.1-49.1C68.2,23.4,70.9,24.5,72.2,25.8 L72.2,25.8z"
            />
            <polygon
              fill="#6F6F6E"
              points="73.8,27.7 24.8,76.8 18.4,79.6 72,26  "
            />
            <g>
              <path
                fill="#6F6F6E"
                d="M78.6,19.4c-3.9-3.9-9.1-4.9-11.6-2.4c-2.5,2.5-3.3,12.5-1.8,16.4c0.7,1.7,0.8,3.7-1,6.7 c2.8-2.6,8.9-7.3,8.9-7.3s6.7-0.7,7.8-1.8C83.5,28.5,82.5,23.3,78.6,19.4z"
              />
            </g>
            <g>
              <path d="M68.3,24c1.2,0,3,0.9,3.9,1.8c1.3,1.3,2.4,4,1.4,5L24.5,79.9l-8.9,2.5l2.5-8.9l49.1-49.1C67.5,24.1,67.9,24,68.3,24  M68.3,22L68.3,22c-1.3,0-2.1,0.5-2.5,1L16.7,72.1l-0.4,0.4L16.2,73l-2.5,8.9l-0.9,3.4l3.4-0.9l8.9-2.5l0.5-0.1l0.4-0.4L75,32.2 c0.6-0.6,1.4-1.9,0.6-4.4c-0.4-1.3-1.2-2.5-2-3.4C72.4,23.2,70.2,22,68.3,22L68.3,22z" />
            </g>
            <g display="none">
              <path
                display="inline"
                d="M78.6,19.4c-3.9-3.9-9.1-4.9-11.6-2.4c-2.5,2.5-3.8,11.8-0.8,14.8c3,3,12.2,1.8,14.8-0.8 C83.5,28.5,82.5,23.3,78.6,19.4z"
              />
            </g>
            <g>
              <path
                fill="#D9D9D9"
                d="M77.4,33.4c-2.9,0-6.1-1.5-8.6-4.1c-2-2-3.4-4.4-3.9-6.8c-0.5-2.5,0-4.7,1.5-6.2c1.1-1.1,2.6-1.7,4.4-1.7 c2.9,0,6.1,1.5,8.6,4.1c2,2,3.4,4.4,3.9,6.8c0.5,2.5,0,4.7-1.5,6.2C80.6,32.8,79.1,33.4,77.4,33.4C77.4,33.4,77.4,33.4,77.4,33.4 z"
              />
              <path d="M70.7,15.6c2.5,0,5.5,1.3,7.9,3.8c3.9,3.9,4.9,9.1,2.4,11.6c-0.9,0.9-2.2,1.4-3.7,1.4c-2.5,0-5.5-1.3-7.9-3.8 c-3.9-3.9-4.9-9.1-2.4-11.6C67.9,16.1,69.2,15.6,70.7,15.6 M70.7,13.6c-2,0-3.8,0.7-5.1,2c-1.7,1.7-2.3,4.3-1.7,7.1 c0.5,2.6,2,5.2,4.2,7.3c2.7,2.7,6.2,4.4,9.3,4.4c2,0,3.8-0.7,5.1-2c1.7-1.7,2.3-4.3,1.7-7.1c-0.5-2.6-2-5.2-4.2-7.3 C77.3,15.3,73.8,13.6,70.7,13.6L70.7,13.6z" />
            </g>
          </g>
        </g> ) : null }
      { letter === 'O' ? (
        <g id="Icon-O">
          <g display="inline">
            <ellipse fill="#FF7B00" cx={42.4} cy={53.3} rx={31.6} ry={30.6} />
            <ellipse fill="#FF9900" cx={42.4} cy={53.3} rx={31.6} ry={30.6} />
            <path
              fill="#F26900"
              d="M63.9,31.9c0.7,2.8,1.1,5.7,1.1,8.7c0,20.5-18.2,36.7-39.3,36.7c-1,0-2,0-3-0.1c4.6,2.6,14,6.6,19.8,6.6 c17.4,0,31.6-13.7,31.6-30.6C73.9,44.1,68.3,37.2,63.9,31.9z"
            />
            <circle fill="#F26900" cx={23.3} cy={59.5} r={1.5} />
            <circle fill="#F26900" cx={23.3} cy={67.5} r={1.5} />
            <circle fill="#F26900" cx={30.4} cy={64.2} r={1.5} />
            <circle fill="#F26900" cx={38} cy={68} r={1.5} />
            <circle fill="#F26900" cx={29.9} cy={72.7} r={1.5} />
            <ellipse
              fill="none"
              stroke="#000000"
              strokeWidth={1.8947}
              strokeMiterlimit={10}
              cx={42.4}
              cy={53.3}
              rx={31.6}
              ry={30.6}
            />
            <g>
              <path
                fill="#36C236"
                d="M53,11.3c7.8-2.4,13.8-0.7,15.4,0.4c0,0-2.7,5.4-9.3,10.1c-6.5,4.7-20.1,7.2-20.1,7.2 C40.3,24.6,45.2,13.7,53,11.3z"
              />
              <path
                fill="#22941F"
                d="M53,11.3c7.8-2.4,13.8-0.7,15.4,0.4c0,0-8.6,2.3-15.2,7c-6.5,4.7-14.2,10.2-14.2,10.2 C40.3,24.6,45.2,13.7,53,11.3z"
              />
              <path
                fill="none"
                stroke="#000000"
                strokeWidth={1.5834}
                strokeMiterlimit={10}
                d="M53,11.3c7.8-2.4,13.8-0.7,15.4,0.4 c0,0-2.7,5.4-9.3,10.1c-6.5,4.7-20.1,7.2-20.1,7.2C40.3,24.6,45.2,13.7,53,11.3z"
              />
            </g>
            <path
              fill="#36C236"
              d="M40.2,36.3c-0.1,0-0.2,0-0.3,0c-1-0.1-1.8-1.1-1.6-2.1c0-0.1,0.5-4.6-1.8-9.5c-1.8-3.8-3.4-5.2-3.4-5.2 c-0.8-0.7-0.9-1.9-0.3-2.7c0.7-0.8,1.8-0.9,2.6-0.3c0.2,0.2,2.3,1.9,4.5,6.5c2.8,6,2.2,11.4,2.1,11.6C42,35.6,41.2,36.3,40.2,36.3 z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={1.8947}
              strokeMiterlimit={10}
              d="M38.4,34.1c0-0.1,0.5-4.6-1.8-9.5 c-1.8-3.8-3.4-5.2-3.4-5.2c-0.8-0.7-0.9-1.9-0.3-2.7c0.7-0.8,1.8-0.9,2.6-0.3c0.2,0.2,2.3,1.9,4.5,6.5c2.8,6,2.2,11.4,2.1,11.6"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={1.8947}
              strokeLinecap="round"
              strokeMiterlimit={10}
              d="M37.1,33.5 c0,0,1.3,2.5,4.1,1.9c1.4-0.3,2.1-1.2,2.3-2"
            />
          </g>
          <g id="Slice_1_" display="inline">
            <path
              fill="#FA6100"
              d="M33.6,75.1C42.9,88.3,61,92,74.9,83.3c13.9-8.7,18.4-26.6,10.7-40.8L33.6,75.1z"
            />
            <path
              fill="#FDEBAA"
              d="M36.4,73.4c8.4,11.6,24.4,14.8,36.7,7.1c12.3-7.7,16.4-23.5,9.6-36.2L36.4,73.4z"
            />
            <use
              xlinkHref="#Orange_Slice"
              width={24.7}
              height={16.9}
              id="XMLID_28_"
              x={-14.9}
              y={-7.1}
              transform="matrix(-0.4123 -0.6591 0.6591 -0.4123 57.2354 72.7432)"
              overflow="visible"
            />
            <use
              xlinkHref="#Orange_Slice"
              width={24.7}
              height={16.9}
              id="XMLID_27_"
              x={-14.9}
              y={-7.1}
              transform="matrix(0.6591 -0.4123 -0.4123 -0.6591 53.0049 71.7695)"
              overflow="visible"
            />
            <use
              xlinkHref="#Orange_Slice"
              width={24.7}
              height={16.9}
              id="XMLID_26_"
              x={-14.9}
              y={-7.1}
              transform="matrix(-0.6591 0.4123 -0.4123 -0.6591 74.0781 58.585)"
              overflow="visible"
            />
            <use
              xlinkHref="#Orange_Slice"
              width={24.7}
              height={16.9}
              id="XMLID_25_"
              x={-14.9}
              y={-7.1}
              transform="matrix(-0.4123 -0.6591 -0.6591 0.4123 73.1045 62.8145)"
              overflow="visible"
            />
            <g display="none">
              <path
                display="inline"
                d="M85.6,42.6c7.7,14.2,3.1,32.1-10.7,40.8C69.8,86.5,64.2,88,58.6,88c-9.7,0-19.1-4.5-25.1-12.9 L85.6,42.6 M86.4,39.7l-1.8,1.1L32.5,73.4l-1.8,1.1l1.2,1.7C38.1,84.9,48,90,58.6,90c6.1,0,12.1-1.7,17.3-5 c14.7-9.2,19.7-28.2,11.4-43.4L86.4,39.7L86.4,39.7z"
              />
            </g>
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit={10}
              d="M33.6,75.1C42.9,88.3,61,92,74.9,83.3c13.9-8.7,18.4-26.6,10.7-40.8 "
            />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit={10}
              d="M85.6,42.6L33.6,75.1"
            />
          </g>
        </g> ) : null }
      { letter === 'P' ? (
        <g id="Icon-P">
          <g display="inline">
            <circle
              stroke="#000000"
              strokeWidth={2.1381}
              strokeMiterlimit={10}
              cx={73.7}
              cy={24.3}
              r={11.2}
            />
            <circle
              stroke="#000000"
              strokeWidth={2.1381}
              strokeMiterlimit={10}
              cx={23.2}
              cy={24.3}
              r={11.2}
            />
          </g>
          <circle display="inline" fill="#FAF4E6" cx={48.5} cy={52.3} r={33.7} />
          <path
            display="inline"
            fill="#D9D0C1"
            d="M74.6,31.7c0.8,3,1.2,6.2,1.2,9.5c0,22.2-18.6,40.2-41.5,40.2c-1.1,0-2.2-0.1-3.2-0.1 c5,2.8,10.9,4.4,17.1,4.4c18.9,0,34.1-14.8,34.1-33.1C82.3,44.7,79.4,37.4,74.6,31.7z"
          />
          <circle
            display="inline"
            fill="none"
            stroke="#1D1D1B"
            strokeWidth={2.0902}
            strokeMiterlimit={10}
            cx={48.5}
            cy={52.3}
            r={33.7}
          />
          <path
            id="Panda-Mouth_1_"
            display="inline"
            stroke="#1D1D1B"
            strokeWidth={0.139}
            strokeMiterlimit={10}
            d="M48.6,78.1 c-8.4,0-10.3-2.5-10.3-2.5s-0.6-0.4-0.6-0.9s0.8-0.8,0.8-0.8c1,1.4,6.6,3,9.2,0.8l0,0.3c-0.6-1.9-1.1-5.7-1.5-6.6s-6.2,0-6.1-3.5 c0,0-0.2-1.4,3.3-2.1c2.2-0.4,2.7-0.4,5.3-0.4s3.1,0,5.3,0.4c3.5,0.7,3.3,2.1,3.3,2.1c0.1,3.5-5.6,2.6-6.1,3.5S50.2,73,49.6,75 l0-0.3c2.6,2.2,8.2,0.6,9.2-0.8c0,0,0.9,0.2,0.8,0.8s-0.6,0.9-0.6,0.9S57,78.1,48.6,78.1z"
          />
          <use
            xlinkHref="#Panda_Eye"
            width={21.7}
            height={21.2}
            id="XMLID_2_"
            x={-11.3}
            y={-10.6}
            transform="matrix(1 0 0 -1 63.25 50.7344)"
            display="inline"
            overflow="visible"
          />
          <use
            xlinkHref="#Panda_Eye"
            width={21.7}
            height={21.2}
            x={-11.3}
            y={-10.6}
            transform="matrix(-1 0 0 -1 34.25 50.7344)"
            display="inline"
            overflow="visible"
          />
        </g> ) : null }
      { letter === 'R' ? (
        <g id="Icon-R">
          <g display="inline">
            <path
              fill="#FA3C80"
              d="M87.5,11.4c0-0.1,0-0.2,0-0.3c-0.3,0-0.6,0-1,0c-41.8,0-75.8,33.9-75.8,75.8c0,0.5,0,1,0,1.5h8.6 c0-0.5,0-1,0-1.5c0-37.1,30.1-67.1,67.1-67.1c0.3,0,0.7,0,1,0V11.4z"
            />
            <path
              fill="#FA793C"
              d="M87.5,19.8c-0.3,0-0.7,0-1,0c-37.1,0-67.1,30.1-67.1,67.1c0,0.5,0,1,0,1.5h8.3c0-0.5,0-1,0-1.5 c0-32.5,26.3-58.8,58.8-58.8c0.3,0,0.7,0,1,0V19.8z"
            />
            <path
              fill="#FACE3C"
              d="M87.5,28.1c-0.3,0-0.7,0-1,0c-32.5,0-58.8,26.3-58.8,58.8c0,0.5,0,1,0,1.5h8c0-0.5,0-1,0-1.5 c0-28.1,22.7-50.8,50.8-50.8c0.3,0,0.7,0,1,0V28.1z"
            />
            <path
              fill="#66CC66"
              d="M87.5,36.1c-0.3,0-0.7,0-1,0c-28.1,0-50.8,22.7-50.8,50.8c0,0.5,0,1,0,1.5h8.8c0-0.5,0-1,0-1.5 c0-23.2,18.8-42,42-42c0.3,0,0.7,0,1,0V36.1z"
            />
            <path
              fill="#22B4E6"
              d="M87.5,45c-0.3,0-0.7,0-1,0c-23.2,0-42,18.8-42,42c0,0.5,0,1,0,1.5h8.2c0-0.5,0-1,0-1.5 c0-18.6,15.1-33.8,33.8-33.8c0.3,0,0.7,0,1,0V45z"
            />
            <path
              fill="#453BFF"
              d="M87.5,53.2c-0.3,0-0.7,0-1,0c-18.6,0-33.8,15.1-33.8,33.8c0,0.5,0,1,0,1.5h8.5c0-0.5-0.1-1-0.1-1.5 c0-14,11.3-25.3,25.3-25.3c0.3,0,0.5,0,0.8,0c0.1-1.3,0.2-2.6,0.2-3.9V53.2z"
            />
            <path
              fill="#8B1EC7"
              d="M61.2,86.9c0,0.5,0,1,0.1,1.5h7.8c0-0.5-0.1-1-0.1-1.5c0-10.2,7.3-17.5,17.5-17.5c0.1,0,0.2,0,0.4,0 c0.1-2.3,0.3-5.5,0.4-7.8c-0.3,0-0.5,0-0.8,0C72.5,61.6,61.2,72.9,61.2,86.9z"
            />
          </g>
          <path
            id="Highlight"
            display="inline"
            opacity={0.4}
            fill="#FFFFFF"
            d="M86.5,11.1c-25.5,0-48,12.6-61.8,31.9l48.7,31.8 c3.1-3.4,7.7-5.5,13.1-5.5c0.1,0,0.2,0,0.4,0c0.1-2.3,0.3-5.5,0.4-7.8c0.1-1.3,0.2-2.6,0.2-3.9v-4.5V45v-8.8v-8v-8.3v-8.4 c0-0.1,0-0.2,0-0.3C87.1,11.1,86.8,11.1,86.5,11.1z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M87.5,11.1c-0.3,0-0.6,0-1,0 c-41.8,0-75.8,33.9-75.8,75.8c0,0.8,0,1.5,0.1,2.3"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M88.2,19.8c-0.6,0-1.1,0-1.7,0 c-37.1,0-67.1,30.1-67.1,67.1c0,0.8,0,1.6,0.1,2.4"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M88.5,28.1c-0.7,0-1.3-0.1-2-0.1 c-32.5,0-58.8,26.3-58.8,58.8c0,0.8,0,1.6,0.1,2.4"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M88.5,36.1c-0.7,0-1.3,0-2,0 c-28.1,0-50.8,22.7-50.8,50.8c0,0.8,0,1.5,0.1,2.3"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M88.2,45c-0.6,0-1.1,0-1.7,0c-23.2,0-42,18.8-42,42 c0,0.7,0,1.4,0.1,2.1"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeMiterlimit={10}
            d="M87.8,53.2c-0.4,0-0.8,0-1.3,0 c-18.6,0-33.8,15.1-33.8,33.8c0,0.7,0,1.3,0.1,2"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M87.3,61.6 c-0.3,0-0.5,0-0.8,0c-14,0-25.3,11.3-25.3,25.3c0,0.6,0,1.3,0.1,1.9"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M10.7,86.9c0,0.5,0,1,0,1.5h57.3H69c0-10.2,8.3-19,18.5-19v-0.9V11.1c-0.3,0-0.7,0-1,0C44.7,11.1,10.7,45,10.7,86.9z"
          />
          <path
            display="inline"
            fill="#ECECEC"
            d="M16.7,41.4h42.6c0,0,9.8,0.8,9.8-9.3c0-10.1-11.5-10.7-11.5-10.7s-1.2-11-13.7-11 c-11.5,0-14.8,7.9-14.8,7.9s-6.6-1.8-9.8,1.5S16.7,28,16.7,28s-9.3-0.3-9.3,7.1S16.7,41.4,16.7,41.4z"
          />
          <path
            display="inline"
            fill="#B1B1B1"
            d="M66,24.7c0.1,0.6,0.1,1.3,0.1,1.9c0,10.1-9.8,9.3-9.8,9.3H13.7c0,0-3.6,0.4-6.3-1 c0,0.1,0,0.1,0,0.2c0,7.4,9.3,6.3,9.3,6.3h42.6c0,0,9.8,0.8,9.8-9.3C69.1,28.7,67.8,26.3,66,24.7z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M16.7,41.4h42.6c0,0,9.8,0.8,9.8-9.3c0-10.1-11.5-10.7-11.5-10.7s-1.2-11-13.7-11c-11.5,0-14.8,7.9-14.8,7.9s-6.6-1.8-9.8,1.5 S16.7,28,16.7,28s-9.3-0.3-9.3,7.1S16.7,41.4,16.7,41.4z"
          />
        </g> ) : null }
      { letter === 'S' ? (
        <g id="Icon-S">
          <g display="inline">
            <polygon
              fill="#F9A900"
              points="48.5,11.4 60.3,35.3 86.8,39.2 67.6,57.8 72.2,84.2 48.5,71.8 24.8,84.2 29.4,57.8 10.2,39.2  36.7,35.3  "
            />
          </g>
          <g display="inline">
            <g>
              <polygon fill="#F5DC3D" points="67.8,57.9 86.9,39.3 48.6,51.7  " />
            </g>
            <g>
              <polygon fill="#F5DC3D" points="48.6,72 72.2,84.4 48.5,51.8  " />
            </g>
            <g>
              <polygon fill="#F5DC3D" points="29.3,58.1 24.8,84.4 48.5,51.8  " />
            </g>
            <g>
              <polygon fill="#F5DC3D" points="36.5,35.4 10.1,39.3 48.4,51.7  " />
            </g>
            <g>
              <polygon fill="#F5DC3D" points="60.3,35.3 48.5,11.4 48.5,51.7  " />
            </g>
          </g>
          <g display="inline">
            <path d="M48.5,11.4l11.8,24l26.5,3.8L67.6,57.8l4.5,26.3L48.5,71.8L24.8,84.2l4.5-26.3L10.2,39.2l26.5-3.8L48.5,11.4 M48.5,9.4 c-0.8,0-1.5,0.4-1.8,1.1l-11.4,23L9.9,37.2c-0.8,0.1-1.4,0.6-1.6,1.4c-0.2,0.7,0,1.5,0.5,2.1l18.4,17.9l-4.3,25.3 c-0.1,0.8,0.2,1.5,0.8,2c0.3,0.3,0.8,0.4,1.2,0.4c0.3,0,0.6-0.1,0.9-0.2l22.7-12l22.7,12c0.3,0.2,0.6,0.2,0.9,0.2 c0.4,0,0.8-0.1,1.2-0.4c0.6-0.4,0.9-1.2,0.8-2l-4.3-25.3l18.4-17.9c0.5-0.5,0.7-1.3,0.5-2.1c-0.2-0.7-0.9-1.3-1.6-1.4l-25.4-3.7 l-11.4-23C50,9.8,49.3,9.4,48.5,9.4L48.5,9.4z" />
          </g>
        </g> ) : null }
      { letter === 'T' ? (
        <g id="Icon-T">
          <path
            display="inline"
            fill="#A4D534"
            d="M48.9,66l2.9,0.5c0,0,8.8,5.3,17.5,0.5c5.4-3,6-11.3,6-11.3s9-2.3,9-13 s-8.7-12.2-8.7-12.2s3.2-6.2-2.1-11.1S61,18.3,61,18.3s-2-7.9-12.2-7.9s-12.2,7.9-12.2,7.9s-7-3.8-12.4,1.1s-2.1,11.1-2.1,11.1 s-8.7,1.5-8.7,12.2s9,13,9,13s0.6,8.3,6,11.3c8.7,4.8,17.5-0.5,17.5-0.5L48.9,66z"
          />
          <path
            display="inline"
            fill="#72B634"
            d="M79.2,32.1c0,1.4,0,2.9,0,4.6c0,10.7-9,13-9,13s-0.6,8.3-6,11.3 c-8.7,4.8-17.5-0.5-17.5-0.5L43.9,60L41,60.5c0,0-8.6,5.1-17.2,0.6c0.9,2.2,2.3,4.5,4.7,5.8c8.7,4.8,17.5-0.5,17.5-0.5l2.9-0.5 l2.9,0.5c0,0,8.8,5.3,17.5,0.5c5.4-3,6-11.3,6-11.3s9-2.3,9-13C84.2,36.8,81.6,33.7,79.2,32.1z"
          />
          <path
            display="inline"
            fill="#9C6B33"
            d="M63.5,55.3l1.7-1c-0.5-0.4-1.1-0.8-1.7-1.5c-0.6-0.7-1.1-1.6-1.4-2.4 c-3,1.8-6.2,3.9-6.6,4.6c-0.4,0.9,1-3.8,2.1-7.4c-1.4,0.3-3,0.3-4.7-0.5l-4.4,12.2l-1.8-9c-1.8,0.6-3.5,0.6-4.9,0.3 c1.7,4.1,0.6,7.8,0.2,6.9c-0.3-0.7-4.7-3.4-7.9-5.3C34,53,33.6,53.8,33,54.5c-0.6,0.7-1.2,1.3-1.8,1.7l1.8,1.1c0,0,8.2,4.2,9.5,8.5 s1,8.4-1,12.9s-4.7,9.2-4.7,9.2h11.7h11.7c0,0-2.7-4.7-4.7-9.2s-2.3-8.7-1-12.9S63.5,55.3,63.5,55.3z"
          />
          <path
            display="inline"
            fill="#734B1C"
            d="M63.5,55.3l1.7-1c-0.5-0.4-2.7-2-2.7-2c-0.7,0.4-5,3-5,3s-6,5.9-7.3,10.1s-1.1,8.9,0,13.7 c0.9,4.1,2.2,7.3,2.8,8.8h7.2c0,0-2.7-4.7-4.7-9.2s-2.3-8.7-1-12.9S63.5,55.3,63.5,55.3z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2.0687}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M76.4,29.9c0,0,3.3-6.4-2.2-11.5s-12.8-1.2-12.8-1.2s-2.1-8.1-12.6-8.1s-12.6,8.1-12.6,8.1s-7.3-3.9-12.8,1.2s-2.2,11.5-2.2,11.5 s-9,1.6-9,12.6s9.3,13.4,9.3,13.4s0.7,8.6,6.2,11.7c5.7,3.2,11.6,2.1,15,0.9c0.7,3.7,0.2,7.3-1.6,11.2c-2.1,4.7-4.8,8.5-4.8,8.5 h12.1h12.1c0,0-2.8-3.9-4.8-8.5c-1.8-4-2.3-7.7-1.5-11.5c3.3,1.3,9.5,2.8,15.7-0.6c5.5-3.1,6.2-11.7,6.2-11.7s9.3-2.4,9.3-13.4 S76.4,29.9,76.4,29.9z"
          />
        </g> ) : null }
      { letter === 'U' ? (
        <g id="Icon-U">
          <path
            id="Rod"
            display="inline"
            fill="#D1D1D1"
            d="M56.8,89.3c-7.5,0-10.5-5.2-11-8c0-0.2,0-0.4,0-0.5V12.3c0-1.7,1.3-3,3-3 s3,1.3,3,3v68.1c0.2,0.7,1.2,2.9,5,2.9c3.8,0,4.4-2.1,4.5-2.7v-5.8c0-1.7,1.3-3,3-3s3,1.3,3,3v6c0,0.1,0,0.2,0,0.3 C67,83.9,64.5,89.3,56.8,89.3z"
          />
          <path
            id="Rod_1_"
            display="inline"
            fill="#868686"
            d="M56.8,89.3c-7.5,0-10.5-5.2-11-8c0-0.2,3.5-0.4,3.5-0.5V12.3 c0-1.7-2.2-3-0.5-3s3,1.3,3,3v68.1c0.2,0.7,1.2,2.9,5,2.9c3.8,0,4.4-2.1,4.5-2.7v-5.8c0-1.7,1.3-3,3-3s3,1.3,3,3v6 c0,0.1,0,0.2,0,0.3C67,83.9,64.5,89.3,56.8,89.3z"
          />
          <path
            id="Handle"
            display="inline"
            fill="#D10360"
            d="M56.8,89.3c-7.5,0-10.5-5.2-11-8c0-0.2,0-0.4,0-0.5v-13h6v12.6 c0.2,0.7,1.2,2.9,5,2.9c3.8,0,4.4-2.1,4.5-2.7v-5.8c0-1.7,1.3-3,3-3s3,1.3,3,3v6c0,0.1,0,0.2,0,0.3C67,83.9,64.5,89.3,56.8,89.3z"
          />
          <path
            display="inline"
            fill="#85074D"
            d="M49.3,67.8v13.1c0.2,0.7,1.2,3.9,5,3.9c2.1,0,3.2-0.8,3.8-1.6c-0.4,0.1-0.8,0.1-1.3,0.1 c-3.8,0-4.8-2.1-5-2.9V67.8H49.3z"
          />
          <path
            display="inline"
            fill="#85074D"
            d="M64.3,71.8c-0.1,0-0.2,0-0.3,0c0.5,0.5,0.8,1.2,0.8,2v6c0,0.1,0,0.2,0,0.3 c-0.3,2.8-2.8,7.2-10.5,7.2c-2.6,0-4.6-0.6-6.2-1.5c1.7,1.9,4.5,3.5,8.7,3.5c7.7,0,10.2-5.4,10.5-8.2c0-0.1,0-0.2,0-0.3v-6 C67.3,73.1,65.9,71.8,64.3,71.8z"
          />
          <path
            id="Outline"
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M56.8,89.3c-7.5,0-10.5-5.2-11-8c0-0.2,0-0.4,0-0.5V12.3c0-1.7,1.3-3,3-3s3,1.3,3,3v68.1c0.2,0.7,1.2,2.9,5,2.9 c3.8,0,4.4-2.1,4.5-2.7v-5.8c0-1.7,1.3-3,3-3s3,1.3,3,3v6c0,0.1,0,0.2,0,0.3C67,83.9,64.5,89.3,56.8,89.3z"
          />
          <path
            display="inline"
            fill="#FCC7DB"
            d="M48.8,48.3c-10.5,0-12.8,6.5-12.8,6.5s-5.5-7-13.3-7s-13,7-13,7c0-36.5,39-38.5,39-38.5 s39,2,39,38.5c0,0-5.3-7-13-7s-13.3,7-13.3,7S59.3,48.3,48.8,48.3z"
          />
          <path
            display="inline"
            fill="#FAA6C6"
            d="M48.8,16.3c0,0,3.1,6.6,4.1,14.7s0.3,17.2,0.3,17.2c10.5,0,8.3,6.6,8.3,6.6s5.5-7,13.3-7 s13,7,13,7C87.8,18.3,48.8,16.3,48.8,16.3z"
          />
          <path
            display="inline"
            fill="#FA3C80"
            d="M48.8,16.3c0,0,7.8,1.3,11.1,15.3c2.5,10.2,1.6,23.2,1.6,23.2s5.5-7,13.3-7s13,7,13,7 C87.8,18.3,48.8,16.3,48.8,16.3z"
          />
          <path
            display="inline"
            fill="#C70360"
            d="M48.8,16.3c0,0,11,1.6,19.1,13.6c3.4,5.1,5.5,10.2,6.9,17.9c7.8,0,13,7,13,7 C87.8,18.3,48.8,16.3,48.8,16.3z"
          />
          <path
            display="inline"
            fill="#FA3C80"
            d="M48.8,16.3c0,0-7.8,1.3-11.1,15.3C35.1,41.7,36,54.8,36,54.8s-5.5-7-13.3-7s-13,7-13,7 C9.8,18.3,48.8,16.3,48.8,16.3z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M48.8,48.3c-10.5,0-12.8,6.5-12.8,6.5s-5.5-7-13.3-7s-13,7-13,7c0-36.5,39-38.5,39-38.5s39,2,39,38.5c0,0-5.3-7-13-7 s-13.3,7-13.3,7S59.3,48.3,48.8,48.3z"
          />
        </g> ) : null }
      { letter === 'V' ? (
        <g id="Icon-V">
          <path
            display="inline"
            fill="#D9D9D9"
            d="M40.4,35.7c0,0,1.8-10.9,9.3-10.6c-0.5-7.2,6.2-10.9,11.2-7c1.5-5.8,9.9-8.2,14.6-3.9 c0.1-2.9,3.2-5.5,5.9-5.5H89V36c0,0-8.2,0.9-9-6.5c-3.1,7.4-9.9,4-12.2,2c-0.6,3.6-7.1,4.7-9.1,3.3c-0.1,2.9-1.4,5-1.4,5l-14.8,1.3 L40.4,35.7z"
          />
          <path
            display="inline"
            fill="#9C9B9B"
            d="M81.4,23.6c-3.8,9.2-12.2,4.9-15.2,2.5c-0.7,4.5-8.7,5.8-11.2,4c-0.1,3.6-1.8,6.2-1.8,6.2 L41,37.4l1.4,3.7l14.8-1.3c0,0,1.4-2.1,1.4-5c2,1.4,8.5,0.4,9.1-3.3c2.4,1.9,9.2,5.4,12.2-2c0.8,7.4,9,6.5,9,6.5v-4.5 C86,30.9,82.1,29.1,81.4,23.6z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={1.9297}
            strokeMiterlimit={10}
            d="M40.4,35.7 c0,0,1.8-10.9,9.3-10.6c-0.5-7.2,6.2-10.9,11.2-7c1.5-5.8,9.9-8.2,14.6-3.9c0.1-2.9,3.2-5.5,5.9-5.5H89V36c0,0-8.2,0.9-9-6.5 c-3.1,7.4-9.9,4-12.2,2c-0.6,3.6-7.1,4.7-9.1,3.3c-0.1,2.9-1.4,5-1.4,5l-14.8,1.3L40.4,35.7z"
          />
          <path
            display="inline"
            fill="#506578"
            d="M88.9,88.6V74.5c0,0-29.1-14.5-29.1-36c0,0-0.1-1.5-1.5-1.5s-17.2,0-18.5,0 s-1.2,1.5-1.2,1.5c0,21.5-29.1,36-29.1,36v14.1H88.9z"
          />
          <g display="inline">
            <polygon fill="#374757" points="18.9,88.6 35.2,88.6 35,76.3  " />
            <polygon
              fill="#374757"
              points="9.5,81.5 9.5,88.6 15.7,88.6 27.5,70.3  "
            />
            <polygon fill="#374757" points="50.7,75.8 41.7,88.6 58.9,88.6  " />
            <polygon fill="#374757" points="63.2,72.3 63.9,88.6 81.5,88.6  " />
            <path
              fill="#374757"
              d="M88.9,74.5c0,0-18.5-9.2-26-23.9c3.3,7.8,7.6,15.8,10.9,19.4l11.6,18.5h3.5V74.5z"
            />
          </g>
          <path
            display="inline"
            fill="#F9A900"
            d="M40.2,54c1,0.5,2.2,2.5,2,4.8v9.9c0,0,0,2.7,2.7,2.7s2.9-2.7,2.9-2.7V52 c0,0-0.4-3.3,3.1-3.3c3,0,2.8,3.3,2.8,3.3v12.2c0,0-0.3,3.3,2.6,3.3s2.6-3.3,2.6-3.3V53.6c0,0-0.1-2.1,4-2.9c-2.4-4.2-4-8.8-4-13.8 H39.6c0,5.7-2.1,11-5.3,15.8C36.8,53.1,39.2,53.5,40.2,54z"
          />
          <g display="inline">
            <path
              fill="#E6333E"
              d="M48.7,46.2c-3.8,0-2.9,3.1-2.9,3.1v17.7c0,0-0.2,2.9-3.1,2.9c-0.1,0-0.2,0-0.3,0c0.3,0.7,1,1.5,2.4,1.5 c2.7,0,2.9-2.7,2.9-2.7V52c0,0-0.4-3.3,3.1-3.3c0.3,0,0.6,0,0.9,0.1C51.6,47.9,51.1,46.2,48.7,46.2z"
            />
            <path
              fill="#E6333E"
              d="M62.8,50.8c-0.5-0.9-1-1.8-1.4-2.7c-4.2,0.8-4.1,3-4.1,3v11c0,0,0.3,3.6-2.8,3.6c-0.3,0-0.5,0-0.8-0.1 c0.2,0.9,0.9,1.9,2.4,1.9c2.9,0,2.6-3.3,2.6-3.3V53.6C58.8,53.6,58.7,51.5,62.8,50.8z"
            />
          </g>
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M88.9,88.6V74.5c0,0-29.1-14.5-29.1-36c0,0-0.1-1.5-1.5-1.5s-17.2,0-18.5,0s-1.2,1.5-1.2,1.5c0,21.5-29.1,36-29.1,36v14.1H88.9z"
          />
        </g> ) : null }
      { letter === 'W' ? (
        <g id="Icon-W">
          <path
            display="inline"
            fill="#695CC7"
            d="M37.1,25.7c12.8,0,23.5,9.1,25.9,21.2c1.2,3.1,4.3,5.4,7.8,5.4c4.6,0,8.4-3.8,8.4-8.4 c0-2.3-1.8-5.3-4.7-7.7c-3.2-2-3.8,0-8.3-3.5c-2.1-1.6-5.8-5.5-5.8-5.5s5.1,3.4,8.6,1c1.5-1,2.3-3.2,5.5,0c0.4-4.8,4.6-6.2,8-7.3 c3.4-1,4.9-6.6,4.9-6.6s2.5,4.6,1.1,8.9c-1.5,4.6-4.3,7.8-7.5,10c-1,0.7,1.1,2.8,1.7,4c2.9,4.3,4.6,9.4,4.6,14.9 c0,14.6-11.8,26.4-26.4,26.4H37.1c-14.6,0-26.4-11.8-26.4-26.4C10.6,37.5,22.5,25.7,37.1,25.7z"
          />
          <path
            display="inline"
            fill="#D9D9D9"
            d="M10.6,52.1c0-1.1,0.1-2.2,0.2-3.3h20.4c15.8,0,28.5,12.8,28.5,28.5c0,0.4,0,0.8,0,1.2 H37.1C22.5,78.5,10.6,66.7,10.6,52.1z"
          />
          <g display="inline">
            <path
              fill="#4537AB"
              d="M10.6,52.1c0-8.3,3.8-15.7,9.7-20.5c-2.9,4.2-4.6,9.3-4.6,14.8c0,0.8,0,1.6,0.1,2.4h-5.1 C10.7,49.9,10.6,51,10.6,52.1z"
            />
            <path
              fill="#4537AB"
              d="M59.4,72.9h6.5c6.3,0,12.1-2.2,16.7-5.9c-4.8,7-12.8,11.6-21.9,11.6h-0.9c0-0.4,0-0.8,0-1.2 C59.8,75.8,59.7,74.3,59.4,72.9z"
            />
            <path
              fill="#B1B1B1"
              d="M10.9,48.8H16c1.2,13.5,12.5,24.1,26.3,24.1h17.1c0.2,1.5,0.4,2.9,0.4,4.5c0,0.4,0,0.8,0,1.2H37.1 c-14.6,0-26.4-11.8-26.4-26.4C10.6,51,10.7,49.9,10.9,48.8z"
            />
          </g>
          <path
            display="inline"
            fill="#695CC7"
            d="M28.7,48.1c0,0,0.5,6.8,5.9,11.9c4.6,4.4,14.9,3.7,14.9,3.7s-4.9-3.2-5.9-8.1 c-1.1-5.1-10.7-8-10.7-8"
          />
          <circle display="inline" fill="#1D1D1B" cx={25.1} cy={41.5} r={2.8} />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M59.8,78.5c0-0.4,0-0.8,0-1.2c0-13.6-9.6-25-22.4-27.8c2.7,1.4,5.6,3.4,6.1,6.1c1,4.9,5.9,8.1,5.9,8.1S39.2,64.4,34.6,60 c-4.4-4.1-5.5-9.3-5.8-11.2H10.9"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M37.1,25.7c12.8,0,23.5,9.1,25.9,21.2c1.2,3.1,4.3,5.4,7.8,5.4c4.6,0,8.4-3.8,8.4-8.4c0-2.3-1.8-5.3-4.7-7.7c-3.2-2-3.8,0-8.3-3.5 c-2.1-1.6-5.8-5.5-5.8-5.5s5.1,3.4,8.6,1c1.5-1,2.3-3.2,5.5,0c0.4-4.8,4.6-6.2,8-7.3c3.4-1,4.9-6.6,4.9-6.6s2.5,4.6,1.1,8.9 c-1.5,4.6-4.3,7.8-7.5,10c-1,0.7,1.1,2.8,1.7,4c2.9,4.3,4.6,9.4,4.6,14.9c0,14.6-11.8,26.4-26.4,26.4H37.1 c-14.6,0-26.4-11.8-26.4-26.4C10.6,37.5,22.5,25.7,37.1,25.7z"
          />
        </g> ) : null }
      { letter === 'X' ? (
        <g id="Icon-X">
          <g display="inline">
            <rect x={12.5} y={20} fill="#F7C372" width={73.3} height={10.5} />
            <rect x={46.2} y={20} fill="#B17F49" width={1.9} height={10.5} />
            <rect x={24.7} y={20} fill="#B17F49" width={1.9} height={10.5} />
            <rect x={67.4} y={20} fill="#B17F49" width={1.9} height={10.5} />
            <rect
              x={12.5}
              y={20}
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              width={73.3}
              height={10.5}
            />
          </g>
          <g display="inline">
            <rect
              x={12.5}
              y={46}
              transform="matrix(0.9659 -0.2588 0.2588 0.9659 -11.5909 14.4613)"
              fill="#F7C372"
              width={73.2}
              height={10.5}
            />
            <rect x={46.2} y={46.5} fill="#B17F49" width={1.9} height={10.5} />
            <rect x={24.7} y={52.1} fill="#B17F49" width={1.9} height={10.5} />
            <rect x={67.4} y={41} fill="#B17F49" width={1.9} height={10.5} />
            <rect
              x={12.5}
              y={46}
              transform="matrix(0.9659 -0.2588 0.2588 0.9659 -11.5909 14.4613)"
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              width={73.2}
              height={10.5}
            />
          </g>
          <path
            display="inline"
            fill="#FA3C80"
            d="M24.3,68.5c0,1.1-0.9,2-2,2H11.6c-1.1,0-2-0.9-2-2v-51c0-1.1,0.9-2,2-2h10.7 c1.1,0,2,0.9,2,2V68.5z"
          />
          <path
            display="inline"
            fill="#D10360"
            d="M22.3,15.5h-0.5V67c0,0.6-0.4,1-1,1H9.6v0.5c0,1.1,0.9,2,2,2h10.7c1.1,0,2-0.9,2-2v-51 C24.3,16.4,23.4,15.5,22.3,15.5z"
          />
          <path
            display="inline"
            fill="#FA793C"
            d="M45.6,61.8c0,1.1-0.9,2-2,2H32.9c-1.1,0-2-0.9-2-2V17.5c0-1.1,0.9-2,2-2h10.7 c1.1,0,2,0.9,2,2V61.8z"
          />
          <path
            display="inline"
            fill="#E0550A"
            d="M43.6,15.5h-0.5v44.8c0,0.6-0.4,1-1,1H30.9v0.5c0,1.1,0.9,2,2,2h10.7c1.1,0,2-0.9,2-2 V17.5C45.6,16.4,44.7,15.5,43.6,15.5z"
          />
          <path
            display="inline"
            fill="#A4D534"
            d="M66.9,55.2c0,1.1-0.9,2-2,2H54.3c-1.1,0-2-0.9-2-2V17.5c0-1.1,0.9-2,2-2h10.7 c1.1,0,2,0.9,2,2V55.2z"
          />
          <path
            display="inline"
            fill="#72B634"
            d="M64.9,15.5h-0.5v38.2c0,0.6-0.4,1-1,1H52.3v0.5c0,1.1,0.9,2,2,2h10.7c1.1,0,2-0.9,2-2 V17.5C66.9,16.4,66,15.5,64.9,15.5z"
          />
          <path
            display="inline"
            fill="#60D3F5"
            d="M88.3,48.5c0,1.1-0.9,2-2,2H75.6c-1.1,0-2-0.9-2-2v-31c0-1.1,0.9-2,2-2h10.7 c1.1,0,2,0.9,2,2V48.5z"
          />
          <path
            display="inline"
            fill="#009FE3"
            d="M86.3,15.5h-0.5V47c0,0.6-0.4,1-1,1H73.6v0.5c0,1.1,0.9,2,2,2h10.7c1.1,0,2-0.9,2-2v-31 C88.3,16.4,87.4,15.5,86.3,15.5z"
          />
          <g id="Outlines" display="inline">
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M24.3,68.5c0,1.1-0.9,2-2,2H11.6c-1.1,0-2-0.9-2-2 v-51c0-1.1,0.9-2,2-2h10.7c1.1,0,2,0.9,2,2V68.5z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M45.6,61.8c0,1.1-0.9,2-2,2H32.9c-1.1,0-2-0.9-2-2 V17.5c0-1.1,0.9-2,2-2h10.7c1.1,0,2,0.9,2,2V61.8z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M66.9,55.2c0,1.1-0.9,2-2,2H54.3c-1.1,0-2-0.9-2-2 V17.5c0-1.1,0.9-2,2-2h10.7c1.1,0,2,0.9,2,2V55.2z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M88.3,48.5c0,1.1-0.9,2-2,2H75.6c-1.1,0-2-0.9-2-2 v-31c0-1.1,0.9-2,2-2h10.7c1.1,0,2,0.9,2,2V48.5z"
            />
          </g>
          <g display="inline">
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={81}
              cy={25.3}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={59.7}
              cy={25.3}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={37.8}
              cy={25.3}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={16.5}
              cy={25.3}
              r={3}
            />
          </g>
          <g display="inline">
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={81}
              cy={42.8}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={59.7}
              cy={48.8}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={37.8}
              cy={54.3}
              r={3}
            />
            <circle
              fill="#F7C372"
              stroke="#000000"
              strokeWidth={1.6}
              strokeMiterlimit={10}
              cx={17}
              cy={59.8}
              r={3}
            />
          </g>
          <g id="Bonger_1_" display="inline">
            <path
              fill="#F7C372"
              d="M79.6,72.4c0.3,1.4-0.6,2.7-2,2.9l-54,10.3c-1.4,0.3-2.7-0.6-2.9-2l0,0c-0.3-1.4,0.6-2.7,2-2.9l54-10.3 C78,70.2,79.4,71.1,79.6,72.4L79.6,72.4z"
            />
            <rect
              x={21.3}
              y={78}
              transform="matrix(0.9823 -0.1873 0.1873 0.9823 -13.9424 10.9045)"
              fill="#B17F49"
              width={58.9}
              height={2.5}
            />
            <path
              fill="none"
              stroke="#000000"
              strokeWidth={2}
              strokeMiterlimit={10}
              d="M79.6,72.4c0.3,1.4-0.6,2.7-2,2.9l-54,10.3 c-1.4,0.3-2.7-0.6-2.9-2l0,0c-0.3-1.4,0.6-2.7,2-2.9l54-10.3C78,70.2,79.4,71.1,79.6,72.4L79.6,72.4z"
            />
            <circle fill="#DAEBF0" cx={74.3} cy={73.5} r={7.9} />
            <path
              fill="#98C2D0"
              d="M78.2,66.6c1,1.2,1.5,2.8,1.5,4.5c0,4-3.3,7.3-7.3,7.3c-2.3,0-4.3-1.1-5.7-2.7c0.9,3.3,4,5.7,7.6,5.7 c4.4,0,7.9-3.5,7.9-7.9C82.2,70.5,80.6,68,78.2,66.6z"
            />
            <circle
              fill="none"
              stroke="#1D1D1B"
              strokeWidth={2}
              strokeMiterlimit={10}
              cx={74.3}
              cy={73.5}
              r={7.9}
            />
          </g>
        </g> ) : null }
      { letter === 'Y' ? (
        <g id="Icon-Y">
          <polygon
            display="inline"
            fill="#FDDB60"
            points="83.7,58.3 42.1,58.3 42.1,10.5  "
          />
          <polygon
            display="inline"
            fill="#FDDB60"
            points="11.9,58.3 38.6,58.3 38.6,15.5  "
          />
          <path
            display="inline"
            fill="#EBBE2D"
            d="M63.7,58.3H42.1V10.5c0,0,4,29.6,7.8,35.8C55.5,55.7,63.7,58.3,63.7,58.3z"
          />
          <polygon
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            points="83.7,58.3 42.1,58.3  42.1,10.5  "
          />
          <polygon
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeMiterlimit={10}
            points="11.9,58.3 38.6,58.3  38.6,15.5  "
          />
          <path
            id="Rod_3_"
            display="inline"
            fill="#D1D1D1"
            d="M38.5,72.4c0-0.1,0-0.3,0-0.4V9.3c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3 v62.4"
          />
          <path
            id="Rod_2_"
            display="inline"
            fill="#868686"
            d="M38.5,72.4c0-0.1,2.7-0.3,2.7-0.4V9.3C41.1,8,39.5,7,40.8,7 c1.3,0,2.3,1,2.3,2.3v62.4"
          />
          <path
            id="Outline_2_"
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={1.5369}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M38.5,72.4c0-0.1,0-0.3,0-0.4V9.3c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3v62.4"
          />
          <path
            display="inline"
            fill="#E0550A"
            d="M10.9,66.5h36.5h38.8v7.2c0,0-1,5.9-5.9,5.9s-20.1,0-20.1,0v7.9h-9c0,0-11.4,0.8-11.4-7.9 c-8.6-0.6-18.4-1.1-22.6-4.1s-6.3-10-6.3-10"
          />
          <path
            display="inline"
            fill="#AB4108"
            d="M82.7,66.5v3.2c0,0-1,5.9-5.9,5.9s-20.1,0-20.1,0l-20.4,0C27.8,75,18,74.5,13.8,71.5 c0,0-0.1-0.1-0.1-0.1c1,1.5,2.2,3.1,3.6,4.1c4.3,3,14,3.5,22.6,4.1l20.4,0c0,0,15.3,0,20.1,0s5.9-5.9,5.9-5.9v-7.2H82.7z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#000000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d=" M10.9,66.5h36.5h38.8v7.2c0,0-1,5.9-5.9,5.9s-20.1,0-20.1,0v7.9h-9c0,0-11.4,0.8-11.4-7.9c-8.6-0.6-18.4-1.1-22.6-4.1 S10.9,66.5,10.9,66.5z"
          />
        </g> ) : null }
      { letter === 'Z' ? (
        <g id="Icon-Z">
          <path
            id="Mane_2_"
            fill="#2E241F"
            d="M55.5,16.6c-6.2-3.1-10.1-0.7-13.7-0.1c-3.6,0.6-7.6,4.5-10,8c-2.4,3.5-3,7.2-3,7.2 s4.5-1.5,5.4-1.8c0.5-0.1,1.3,0.4,1.9,1c6.5-5.5,13.7-7.1,17-7.3c4.4-0.2,34.8,16.1,34.8,16.1v-9.1C87.9,30.5,61.7,19.7,55.5,16.6z "
          />
          <path
            id="Silhouette_1_"
            fill="#ECE2D0"
            d="M87.9,39.6c0,0-30.5-16.3-34.8-16.1c-4.4,0.2-15.6,3-22.8,13.8 c-3.8,5.7-4.4,12.1-6.1,16c-1.7,3.8-7.7,12.6-9.2,17c-1.4,4.4-2,6.3,0.1,10.4c2.1,4,4.2,7.1,6.6,4.7s4.6-4.7,4.6-4.7l-1.1,5.9 c0,0,5,0.8,5.9-2.5c1-3.3,0.7-4.3,1.8-5.3c1.1-1,12.2-9,14.5-9.6c2.3-0.6,8.8-3.2,9.5-4.9c0.7-1.7,1.3-0.2,1.3-0.2l29.7,23.2V39.6z "
          />
          <path
            id="Shadow"
            fill="#B9A482"
            d="M57.5,54.8c0,0,0.3,2.4-0.4,4.3c-0.8,1.8-7.9,4.7-10.4,5.3C44.2,65,32,73.8,30.8,74.9 c-1.2,1-0.9,2.1-2,5.8c-0.5,1.6-1.7,2.3-3.1,2.6l-0.6,3.3c0,0,5,0.8,5.9-2.5c1-3.3,0.7-4.3,1.8-5.3c1.1-1,12.2-9,14.5-9.6 c2.3-0.6,8.8-3.2,9.5-4.9c0.7-1.7,1.3-0.2,1.3-0.2l29.7,23.2V77.3L57.5,54.8z"
          />
          <g id="Black_Stripes">
            <path
              fill="#2E241F"
              d="M87.9,39.6c0,0-28.7-16.8-34.5-16.1c0.1,0,0.2,0,0.3,0c3.5,0.3-2,6.3-2.3,11.9c-0.2,2.9-1.1,15.9,1,20.9 c1.5,3.7,1.9,8.1,1.7,10.1c1.4-0.8,2.6-1.5,2.8-2.2c-0.1,0.2-0.3,0.4-0.5,0.6c1.5-2.7,3.5-4.4,2-7.2c-1.5-2.9-2.5-12.1-2.5-14.7 c0-2.6,0.9-8,1.4-8.9c0.5-1,2.5-7.4-1.1-9.8c1,0.4,2.1,0.9,3.4,1.5l1.9,1c0,0,3.2,2.9,2,6.6c-1.1,3.5-1.3,8.7-0.5,12.7 c0.8,4,0.3,11.7-1.5,15.4c-0.4,0.7-1.7,2.5-2.7,3l4.5,3.5c2.7-1.7,3.8-3.8,4.5-7.1c1.4-6.2,1.3-9.6,1.3-14.8 c0-4.7-0.5-8.3,0.3-11.6c0.4-1.6-1.8-3.9-2.2-4.5c1.8,1,7.1,4.1,8.2,4.8c0,0,1.9,2.2,0.2,6.8C74.8,44,74,61.2,74.2,62.6 c0.1,1.4,0.2,7.4-5.2,10l6.3,5c1.5-0.8,2.6-1.1,3.5-3.9c1.4-4.6,0.6-8,0.8-11.4s0.2-12.9,1.1-14.9c0.8-2,0.8-6.8-1.1-9.8 C81.6,38.8,87.9,39.6,87.9,39.6z"
            />
            <path
              fill="#2E241F"
              d="M45.8,54.8c-0.5-1.4-0.4-6.9,0.6-11.6c0.6-2.9,1.1-5.5-1.1-7.7s-3.5-7.9-3.5-7.9C42,26,45,27,45,27 l-0.6-2.3c-2.6,1.1-6.7,3.1-8.3,6c0.9-0.6,1.8-1.2,2.6-1.7c-0.5,1.2-0.5,3-1,3.7c-0.7,1.2,3.3,4,4.1,5.2 c0.7,1.2,0.8,13.9-1.5,15.3s-7.6,0.4-0.7,5.1c4.2,2.8,5.7,7.1,6.3,11.5c0.6-0.3,1.1-0.6,1.4-0.6c0.7-0.2,1.8-0.5,3-1 C49.5,63.7,46.5,56.4,45.8,54.8z"
            />
            <path
              fill="#2E241F"
              d="M34.5,60.5c-6-3.6-6.8-2.2-8-0.3c-0.8,1.3-3.7,6-4.4,7.8c-0.2-1.4-0.5-2.7-1-3.4c0,0,0,0,0,0L20.4,64 c-0.7-0.2-1.8,0.2-2.9,0.8c1.6-2.8,3.5-5.9,4.9-8.4C20,60.8,16.1,66.9,15,70.3c-1.4,4.4-2,6.3,0.1,10.4c2.1,4,4.2,7.1,6.6,4.7 s4.6-4.7,4.6-4.7l-1.1,5.9c0,0,5,0.8,5.9-2.5c0.8-2.7,0.8-3.8,1.3-4.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6-1.8-1.7-3.8-3.5-4.4 c-3-1.1-6.1,2.9-6.1,0.5c0-1.3-0.1-4.4-0.4-7.1c0.7-0.7,1.8-1.7,3.6-2.9c3-1.9,2.4-0.8,4.4,0c2,0.8,3.8,0.4,5,1.3 c1.2,1,2.6,3.2,2.8,5.2c0.1,0.7,0.2,1.7,0.3,2.7c1-0.7,2-1.4,3-2.1C41.3,68.3,38.1,62.6,34.5,60.5z"
            />
          </g>
          <g id="Black_Stripes_Shadow">
            <path d="M48,69.1c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0,0,0,0c0.5-0.2,1.1-0.4,1.6-0.6c-0.2-1.4-0.7-3-1.2-4.7c-1,0.4-1.8,0.7-2.4,0.8 c-0.4,0.1-1,0.4-1.8,0.8c0.5,1.5,0.9,3.1,1.1,4.7c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1 c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4-0.1,0.6-0.2C48,69.1,48,69.1,48,69.1z" />
            <path d="M40.3,68.1c-1,0.7-2,1.4-3,2c0.3,0.7,0.5,1.4,0.6,2.1c0.1,0.7,0.2,1.7,0.3,2.7c0,0,0,0,0,0c1-0.7,2-1.4,3-2.1 C41.3,71.3,40.9,69.7,40.3,68.1z" />
            <path d="M31.4,82.9c0.4-1.8,0.5-2.7,1-3.4c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.2C31.7,78.4,31,77,30,76.1 c-0.3,0.9-0.5,2.2-1.2,4.6c-0.5,1.6-1.7,2.3-3.1,2.6l-0.6,3.3c0,0,5,0.8,5.9-2.5c0.1-0.4,0.2-0.8,0.3-1.1 C31.4,83,31.4,82.9,31.4,82.9z" />
            <path d="M62.1,67.2l1.2,1c2.3-1.5,3.5-3.3,4.2-5.9l-5-3.7c-0.3,1.2-0.6,2.2-1,3.1c-0.4,0.7-1.7,2.5-2.7,3L62.1,67.2z" />
            <path d="M73.7,66.8c-0.6,2.1-1.9,4.4-4.7,5.8l6.3,5c1.5-0.8,2.6-1.1,3.5-3.9c0.3-0.9,0.5-1.8,0.6-2.6L73.7,66.8z" />
            <path d="M58.4,57.8c-0.3-0.5-0.5-1.3-0.8-2.1c0,0.9,0,2.3-0.5,3.4c-0.3,0.7-1.7,1.6-3.4,2.5c0.4,2,0.4,3.9,0.3,5c0,0,0,0,0,0 c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1 c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1C57.9,62.3,59.9,60.6,58.4,57.8z" />
          </g>
          <g id="Ear_1_">
            <path
              fill="#ECE2D0"
              d="M41.9,27.8c0,0-0.8-8.2-1.1-13.3c-0.3-4.8,5.1-6.9,8-5.9c2.9,1,6.5,6,6.8,10.3c0.5,6.2-3.1,12.4-4.7,14.4 c-1.6,2-4.3,2.2-4.3,2.2L41.9,27.8z"
            />
            <path
              fill="#2E241F"
              d="M44.8,25.7c0,0-0.4-3.2-0.6-6.3c-0.2-2.8,0.2-6.6,1.6-6c1.3,0.6,3.4,5.8,3,8.4c-0.4,2.6-0.6,4.5-1.3,5.7 c-0.7,1.2-1.3,3-1.3,3L44.8,25.7z"
            />
          </g>
          <circle cx={33.9} cy={47.3} r={4.1} />
          <g id="Outline_3_">
            <path d="M45.2,9.5c2.1,0,5,1.1,8.8,5.7c0.7,1,1.7,2.6,2,3.9c6.2,3.1,31.9,11.4,31.9,11.4v9.1v4.2v43.6L59.2,64.1 c0,0-0.9-1.3-1.6-1.3c-0.3,0-0.5,0.2-0.7,0.6c-0.7,1.7-7.3,5.3-9.5,5.9c-2.3,0.6-13.4,8.6-14.5,9.6c-1.1,1-0.8,0.9-1.8,4.3 c-1,3.3-3.9,3.5-3.9,3.5l1.1-7.9c0,0-4.2,3.3-6.6,5.7c-0.7,0.7-1.4,1-2,1c-1.6,0-3.1-1.9-4.6-4.7c-2.1-4-1.5-6-0.1-10.4 c1.4-4.4,7.5-13.2,9.2-17c1.7-3.8,2.3-10.3,6.1-16c1.8-2.6,3.7-4.7,5.8-6.5c-0.5-0.6-1.2-1-1.7-1c-0.1,0-0.1,0-0.2,0 c-0.8,0.2-5.4,1.8-5.4,1.8s0.7-3.7,3-7.2c2.3-3.4,5.7-6.3,9.2-7.1c-0.4-3.6,0.3-7.3,3.1-7.8C44.4,9.6,44.8,9.5,45.2,9.5 M45.2,7.5 c-0.5,0-0.9,0-1.4,0.1c-3.2,0.6-5,3.7-4.9,8.3c-3.1,1.2-6.2,3.8-8.7,7.4c-2.6,3.8-3.3,7.8-3.4,8c-0.1,0.7,0.1,1.4,0.7,1.9 c0.4,0.3,0.8,0.5,1.3,0.5c0.2,0,0.4,0,0.6-0.1c0.5-0.2,1-0.4,1.6-0.5c-0.9,1-1.7,2.1-2.5,3.3c-2.7,4-3.9,8.3-4.8,11.8 c-0.5,1.8-0.9,3.3-1.4,4.5c-0.6,1.5-2.1,3.9-3.6,6.4c-2.3,3.9-4.7,8-5.7,10.8c-1.4,4.4-2.3,7.1,0.3,11.9c1.4,2.7,3.4,5.8,6.4,5.8 c1.2,0,2.4-0.5,3.4-1.6c0.7-0.7,1.6-1.5,2.5-2.3l-0.4,2.8c-0.1,0.6,0.1,1.2,0.5,1.6c0.4,0.4,0.9,0.6,1.5,0.6c0,0,0.1,0,0.1,0 c1.6-0.1,4.7-1.2,5.7-4.9c0.2-0.7,0.4-1.3,0.5-1.8c0.1-0.5,0.3-1.1,0.4-1.2c0,0,0.1-0.1,0.4-0.3c1.3-1.2,11.9-8.6,13.7-9.2 c1.7-0.5,7.4-3.2,9.9-5.7c0.1,0.1,0.1,0.1,0.2,0.2l28.7,23.2c0.4,0.3,0.8,0.4,1.3,0.4c0.3,0,0.6-0.1,0.9-0.2 c0.7-0.3,1.1-1,1.1-1.8V43.8v-4.2v-9.1c0-0.9-0.6-1.6-1.4-1.9c-0.2-0.1-23.7-7.7-30.8-11c-0.5-1.3-1.3-2.6-1.9-3.5l0,0 c0-0.1-0.1-0.1-0.1-0.2C52,9.7,48.5,7.5,45.2,7.5L45.2,7.5z" />
          </g>
        </g> ) : null }
    </svg>
  );
};

const StudentPin = ({ pin, show = true }: { pin: string, show?: boolean }) => {
  return (
    <div className={'flex items-center gap-2'}>
      {pin.split('').map((letter, index) => (
        <div className='justify-self-center self-center overflow-hidden w-14 h-14 md:w-16 md:h-16 flex grow-0 justify-center items-center bg-white rounded-md border-2 border-gray-300'>
          { show ? <PinIcon key={index} letter={letter} /> : <span className={'text-4xl leading-none'}>*</span> }
        </div>
      ))}
    </div>
  );
};

export { StudentPin, PinIcon, pinIcons };
